<template>
  <div :class="{ 'has-mouse': hasMouse }" @touchstart="hasMouse = false">
    <Flipbook
      class="flipbook"
      :pages="pages"
      :startPage="pageNum"
      v-slot="flipbook"
      ref="flipbook"
      @flip-left-start="onFlipLeftStart"
      @flip-left-end="onFlipLeftEnd"
      @flip-right-start="onFlipRightStart"
      @flip-right-end="onFlipRightEnd"
      @zoom-start="onZoomStart"
      @zoom-end="onZoomEnd"
    >
      <div class="action-bar">
        <left-icon
          class="btn left"
          :class="{ disabled: !flipbook.canFlipLeft }"
          @click="flipbook.flipLeft"
        />
        <plus-icon
          class="btn plus"
          :class="{ disabled: !flipbook.canZoomIn }"
          @click="flipbook.zoomIn"
        />
        <span class="page-num">
          Page {{ flipbook.page }} of {{ flipbook.numPages }}
        </span>
        <minus-icon
          class="btn minus"
          :class="{ disabled: !flipbook.canZoomOut }"
          @click="flipbook.zoomOut"
        />
        <right-icon
          class="btn right"
          :class="{ disabled: !flipbook.canFlipRight }"
          @click="flipbook.flipRight"
        />
      </div>
    </Flipbook>
  </div>
</template>

<script>
import "vue-material-design-icons/styles.css";
import Flipbook from "flipbook-vue";
import LeftIcon from "vue-material-design-icons/ChevronLeftCircle";
import RightIcon from "vue-material-design-icons/ChevronRightCircle";
import PlusIcon from "vue-material-design-icons/PlusCircle";
import MinusIcon from "vue-material-design-icons/MinusCircle";
// import email from "../EmailMe/Email";

export default {
  components: { Flipbook, LeftIcon, RightIcon, PlusIcon, MinusIcon },
  data() {
    return {
      pages: [],
      pagesHiRes: [],
      hasMouse: true,
      pageNum: null,
    };
  },
  created() {
    for (let i = 1; i <= 521; i++) {
      this.pages.push("./books/2022_NP/zxc" + i + ".jpeg");
    }
  },
  methods: {
    onFlipLeftStart(page) {
      return page;
    },
    onFlipLeftEnd(page) {
      return page;
      //   window.location.hash = "#" + page;
    },
    onFlipRightStart(page) {
      return page;
    },
    onFlipRightEnd(page) {
      return page;
      //   window.location.hash = "#" + page;
    },
    onZoomStart(zoom) {
      return zoom;
    },
    onZoomEnd(zoom) {
      return zoom;
    },
  },
};

//     setPageFromHash: ->
//       n = parseInt window.location.hash.slice(1), 10
//       @pageNum = n if isFinite n
//   mounted: ->
//     window.addEventListener 'keydown', (ev) =>
//       flipbook = @$refs.flipbook
//       return unless flipbook
//       flipbook.flipLeft() if ev.keyCode == 37 and flipbook.canFlipLeft
//       flipbook.flipRight() if ev.keyCode == 39 and flipbook.canFlipRight
//     # Simulate asynchronous pages initialization
//     setTimeout (=>
//       @pages = [
//         null
//         'images/1.jpg'
//         'images/2.jpg'
//         'images/3.jpg'
//         'images/4.jpg'
//         'images/5.jpg'
//         'images/6.jpg'
//       ]
//     ), 1
//     window.addEventListener 'hashchange', @setPageFromHash
//     @setPageFromHash()
</script>

<style>
.action-bar {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.action-bar .btn {
  font-size: 30px;
  color: #999;
}
.action-bar .btn svg {
  bottom: 0;
}
.action-bar .btn:not(:first-child) {
  margin-left: 10px;
}
.has-mouse .action-bar .btn:hover {
  color: #ccc;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.3));
  cursor: pointer;
}
.action-bar .btn:active {
  filter: none !important;
}
.action-bar .btn.disabled {
  color: #666;
  pointer-events: none;
}
.action-bar .page-num {
  font-size: 12px;
  margin-left: 10px;
}
.flipbook {
  width: 100%;
  height: 100vh;
}
.flipbook .bounding-box {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}
.ant-row {
  margin-bottom: 10px;
}
</style>