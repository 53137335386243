<template>
  <div>
    <div style="margin-top: 40px">
      <div class="all-card-wrapper">
        <div class="event-title">
          <h2 style="color: #00599a; font-weight: 500; text-align: center">
            Explore by Event Status
          </h2>
        </div>
        <a-row>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <a-card class="module-wrapper">
                <img
                  src="../../../public/icons/Dashboard/Icon_Closed_case.svg"
                  alt="Hotel"
                  width="50"
                  height="50"
                  @click="changebtnEventData('Closed Case', 'Closed Case')"
                />
              </a-card>
              <p>Closed Case</p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Dashboard/Icon_Compromise.svg"
                alt="Hotel"
                width="50"
                height="50"
                @click="changebtnEventData('Compromise', 'Compromise')"
              />
              <p>Compromise</p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Dashboard/Icon_Incomplete.svg"
                alt="Hotel"
                width="50"
                height="50"
                @click="changebtnEventData('Incomplete', 'Incomplete')"
              />
              <p>Incomplete</p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Dashboard/Icon_Investigation.svg"
                alt="Hotel"
                width="50"
                height="50"
                @click="changebtnEventData('Investigation', 'Investigation')"
              />
              <p>Investigation</p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Dashboard/Icon_Legal_Proceeds.svg"
                alt="Discrimination"
                width="50"
                height="50"
                @click="
                  changebtnEventData('Legal Proceeding', 'Legal Proceeds')
                "
              />
              <p>Legal Proceeds</p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Dashboard/Icon_No_legal_proceedsv.svg"
                alt="Women Rights"
                width="50"
                height="50"
                @click="
                  changebtnEventData('No Legal Proceeding', 'No Legal Proceeds')
                "
              />
              <p>No Legal Proceeds</p>
            </div>
          </a-col>
        </a-row>
      </div>
      <a-row style="padding: 0 40px;">
        <div style="text-align: center; margin-top: 25px;">
          <h2 style="color: #00599a; font-weight: 500">
            {{ report_head }} Datasheet
          </h2>
          <p>This data sheet contains the {{ report_head }} Data Report</p>
          <!-- <div class="button-wrapper">
           <a-row>
            <a-col class="btn-child" :xl="3" :lg="{span:4,offset:2}" :md="24" :sm="24" :xs="24">
                  <a-select
                @change="changeeventapidata"
                v-model="group_by"
              >
                <a-select-option value="vdc"> Local Level </a-select-option>
                <a-select-option value="district"> District </a-select-option>
                <a-select-option value="province_id">
                  Province
                </a-select-option>
              </a-select>
            </a-col>
             <a-col class="btn-child"  :xl="3" :lg="{span:4,offset:1}" :md="24" :sm="24" :xs="24">
                     <a-select
                v-model="countOf"
                @change="changecountofData"
              >
                <a-select-option value="victim"> Victim </a-select-option>
                <a-select-option value="perpetrator">
                  Perpetrator
                </a-select-option>
              </a-select>
            </a-col>
             <a-col class="btn-child"  :xl="4" :lg="{span:4,offset:1}" :md="24" :sm="24" :xs="24">
                      <a-input-search
                placeholder="input search text"
                class="search-btn"
                enter-button
                @search="onSearch"
              />
            </a-col>
             <a-col class="btn-child" :xl="4" :lg="{span:4,offset:1}" :md="24" :sm="24" :xs="24">
              
              <a-select @change="changeGraphData(graph_change)" v-model="graph_change">
                  <a-select-option value="male_count">
                      No of Male
                  </a-select-option>
                  <a-select-option value="female_count">
                      No of Female
                  </a-select-option>
                  <a-select-option value="others_count">
                      Others
                  </a-select-option>
                  <a-select-option value="total_count">
                      Total Count
                  </a-select-option>
              </a-select>
         
            </a-col>
             <a-col class="btn-child" :xl="3" :lg="{span:4,offset:1}" :md="24" :sm="24" :xs="24">
                     <download-excel
                class="ant-btn"
                :data="data"
                :fields="json_fields"
                worksheet="My Worksheet"
                name="filename.xls"
              >
                <a-icon type="download"></a-icon>
              </download-excel>
            </a-col>
          </a-row> -->
              <!-- <a-button
                class="btn"
                style="background-color: #f9a825"
                @click="changeoption('table')"
                >Table</a-button
              > -->
              <!--  <a-button style="background-color: #00599A" class="btn" @click="changeoption('map')"> Map</a-button>-->
              <!-- <a-button
                style="background-color: #00599a"
                class="btn"
                @click="changeoption('graph')"
                >Graph</a-button
              > -->
          <!-- </div> -->
        </div>
        <!-- quick stat -->
        <a-row>
          <a-col  :xl="{span:4,offset:5}" :lg="{span:4,offset:5}" :md="24" :xs="24" :sm="24">
            <div class="total-victim stat">
                Total Event Status : 6
            </div>
          </a-col>
           <a-col  :xl="{span:4,offset:1}" :lg="{span:4,offset:1}" :md="24" :xs="24" :sm="24">
            <div class="total-victim stat">
                Total Victims : {{es_victim_event_dynamic_data}}
            </div>
          </a-col>
           <a-col  :xl="{span:4,offset:1}" :lg="{span:4,offset:1}" :md="24" :xs="24" :sm="24">
            <div class="total-victim stat">
                Total Perpetrator : {{es_perpetrator_dynamic_data}}
            </div>
          </a-col>
        </a-row>
        <!-- tab -->
        <a-row>
        <a-col  :xl="19" :lg="19" :md="24" :sm="24" :xs="24">
           <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane key="1" tab="Table">
            <a-col :span="24">
          <div class="d-table">
            <a-table :columns="columns" :data-source="data">
              <span slot="sn" slot-scope="text, record">
                {{ record.key }}
              </span>
            </a-table>
          </div>
        </a-col>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Chart" force-render>
           <a-col :span="24">
            <apexchart
      
        type="line"
        height="500"
        :options="chartOptions"
        :series="series"
      ></apexchart>
        </a-col>
      </a-tab-pane>
        </a-tabs>
        </a-col>
        <a-col  :xl="{span:4,offset:1}" :lg="{span:4,offset:1}" :md="24" :sm="24" :xs="24" style="margin-top:4px;">
          <h3>Filters</h3>
               <div class="button-wrapper">
           <a-row>
               <a-col class="btn-child"  v-if="visible"  :xl="24" :lg="24" :md="24" :sm="24" :xs="24" style="margin-bottom:20px;">
               <label for="Search">Search</label>
               <a-input-search
                       placeholder="input search text"
                       class="search-btn"
                       enter-button
                       @search="onSearch"
                       v-if="visible"
               />
           </a-col>
               <a-col class="btn-child" v-if="visible"  :xl="24" :lg="24" :md="24" :sm="24" :xs="24" style="margin-bottom:15px;">
                   <label>Pick Date Range</label>
                   <a-date-picker
                           v-model="start_date"
                           format="YYYY-MM-DD"
                           placeholder="Start"
                   />
               </a-col>
               <a-col class="btn-child" v-if="visible"  :xl="24" :lg="24" :md="24" :sm="24" :xs="24" style="margin-bottom:20px;">
                   <a-date-picker :format="dateFormat" v-model="end_date"></a-date-picker>
               </a-col>
            <a-col class="btn-child" :xl="24" :lg="24" :md="24" :sm="24" :xs="24" style="margin-bottom:20px;">
                 <label for="District">Select</label>
                  <a-select
                @change="changeeventapidata"
                v-model="group_by"
              >
                <a-select-option value="vdc"> Local Level </a-select-option>
                <a-select-option value="district"> District </a-select-option>
                <a-select-option value="province_id">
                  Province
                </a-select-option>
              </a-select>
            </a-col>
             <a-col class="btn-child"  :xl="24" :lg="24" :md="24" :sm="24" :xs="24" style="margin-bottom:20px;">
                     <label for="Victims">Count Of</label>
                     <a-select
                v-model="countOf"
                @change="changecountofData"
              >
                <a-select-option value="victim"> Victim </a-select-option>
                <a-select-option value="perpetrator">
                  Perpetrator
                </a-select-option>
              </a-select>
            </a-col>
             <a-col class="btn-child" v-if="selectGender" :xl="24" :lg="24" :md="24" :sm="24" :xs="24" style="margin-bottom:20px;">
                 <label for="Gender">Select Gender</label>
              <a-select @change="changeGraphData(graph_change)" v-model="graph_change">
                  <a-select-option value="male_count">
                      Male
                  </a-select-option>
                  <a-select-option value="female_count">
                      Female
                  </a-select-option>
                  <a-select-option value="others_count">
                      Others
                  </a-select-option>
                  <a-select-option value="total_count">
                      Total Count
                  </a-select-option>
              </a-select>
         
            </a-col>
             <a-col class="btn-child" v-if="visible"  :xl="24" :lg="24" :md="24" :sm="24" :xs="24" style="margin-bottom:20px;"  >
              <label for="Download">Download Excel Report</label>
              <a-button style="width: 100% !important" @click="showModal"
              ><a-icon type="download"></a-icon>
            </a-button>
              <download-excel
                class="ant-btn"
                style="visibility: hidden"
                id="test"
                :data="data"
                :fields="json_fields"
                worksheet="My Worksheet"
                name="filename.xls"
              >
                <a-icon type="download"></a-icon>
              </download-excel>
            </a-col>

          </a-row>
              <!-- <a-button
                class="btn"
                style="background-color: #f9a825"
                @click="changeoption('table')"
                >Table</a-button
              > -->
              <!--  <a-button style="background-color: #00599A" class="btn" @click="changeoption('map')"> Map</a-button>-->
              <!-- <a-button
                style="background-color: #00599a"
                class="btn"
                @click="changeoption('graph')"
                >Graph</a-button
              > -->
           
          

       
        
          </div>
        </a-col>
        </a-row>
      </a-row>
    </div>
    <div class="overlay" v-if="overlay_visible">
        <!-- <a-spin size="large" /> -->
        <div class="loaderOnClick"></div>
    </div>
     <!-- download modal -->
    <a-modal
      :visible="downloadVisible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form
        :form="form"
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        @submit="handleSubmit"
      >
          <a-form-item label="Name">
      <a-input
        v-decorator="['Name', { rules: [{ required: true, message: ' name !' }] }]"
         />
       </a-form-item>
       <a-form-item label="Organization Name">
      <a-input
        v-decorator="['Organization_Name', { rules: [{ required: true, message: 'Please input your organization name !' }] }]"
         />
       </a-form-item>

       <a-form-item  label="E-mail">
      <a-input
        v-decorator="[
          'Email',
          {
            rules: [
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ],
          },
        ]"
        />
    </a-form-item>
      <a-form-item  :xl="{span:4, offset:19}" :lg="{span: 4, offset:19}" :md="24" :sm="24" :xs="24">
      <a-button type="primary" html-type="submit">
        Submit
      </a-button>
    </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import ARow from "ant-design-vue/es/grid/Row";
import event from "/src/services/event";
import ACol from "ant-design-vue/es/grid/Col";
import VueApexCharts from "vue-apexcharts";
import JsonExcel from "vue-json-excel";
import downloadPost from "../../services/downloadForm";
const columns = [
  {
    title: "S.No.",
    dataIndex: "sn",
    key: "sn",
    scopedSlots: { customRender: "sn" },
  },
  {
    title: "District",
    dataIndex: "district",
    key: "district",
  },
  {
    title: "Report",
    dataIndex: "report_count",
    key: "report_count",
  },
  {
    title: "No.of Male",
    dataIndex: "male_count",
    key: "male_count",
  },
  {
    title: "No.of Female",
    dataIndex: "female_count",
    key: "female_count",
  },
  {
    title: "Others",
    dataIndex: "others_count",
    key: "others_count",
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
  },
];
const data = [];
export default {
  created() {
    this.fetcheventapidata();
    this.fetchVictimAndPerpetratorData();
    // console.log(this.series);
  },
  components: {
    ACol,
    ARow,
    downloadExcel: JsonExcel,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      downloadVisible:false,
      overlay_visible:false,
      visible:true,
      data,
      columns,
      report_head: "Closed Case",
      event_data: "",
      event_type: "Closed Case",
      group_by: "district",
      countOf: "victim",
      filter: "",
      check: 1,
      key_columns: "",
      json_fields: "",
      table_data: 1,
      map_data: 0,
      graph_data: 0,
      graph_change: "Please Select",
      start_date:"2020-01-01",
      end_date:"2020-03-03",
      dateFormat: 'YYYY-MM-DD',
      es_victim_event_dynamic_data:'',
      es_perpetrator_dynamic_data:'',
      json_fields_district: {
        "S.No": "key",
        District: "district",
        Report_count: "report_count",
        "No.of Male": "male_count",
        "No.of Female": "male_count",
        Others: "others_count",
        Total: "total",
      },
      json_fields_vdc: {
        "S.No": "key",
        VDC: "vdc",
        Report_count: "report_count",
        "No.of Male": "male_count",
        "No.of Female": "male_count",
        Others: "others_count",
        Total: "total",
      },
      json_fields_province: {
        "S.No": "key",
        Province: "province_id",
        Report_count: "report_count",
        "No.of Male": "male_count",
        "No.of Female": "male_count",
        Others: "others_count",
        Total: "total",
      },
      json_data: [
        {
          name: "Tony Peña",
          city: "New York",
          country: "United States",
          birthdate: "1978-03-15",
          phone: {
            mobile: "1-541-754-3010",
            landline: "(541) 754-3010",
          },
        },
        {
          name: "Thessaloniki",
          city: "Athens",
          country: "Greece",
          birthdate: "1987-11-23",
          phone: {
            mobile: "+1 855 275 5071",
            landline: "(2741) 2621-244",
          },
        },
      ],

      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      series: [
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
          xaxis: {
              type: "category",
          },
      },
    };
  },
    watch:{
        start_date(val){
            this.start_date =val.format('YYYY-MM-DD');
            // console.log(this.start_date);
            this.eventStatusDatepickerData();
        },
        end_date(val){
            this.end_date = val.format('YYYY-MM-DD');
            // console.log(this.end_date);
            this.eventStatusDatepickerData();
        }
    },
  methods: {
    // download modal button
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(values);
          this.email = values.Email;
          this.orgName = values.Organization_Name;
          this.name = values.Name;
          let formData = new FormData();
          formData.append("name",this.name);
          formData.append("email",this.email);
          formData.append("org_name",this.orgName);
          downloadPost.postData(formData).then(res=>{
              if(res.data.success === true){
                  this.$cookies.set("downloadExcel","fjdklsajfkdls","7d");
                  document.getElementById("test").click();
                  this.downloadVisible = false;
                  this.clearData();
              }

          })
        }
      });
    },
      clearData(){
          this.name = "";
          this.email = "";
          this.orgName = "";
      },
      showModal() {
          if(this.$cookies.get("downloadExcel") == null){
              this.downloadVisible = true;
              /*
               console.log("there is no cookie");*/
              //this.$cookies.set("downloadExcel","fjdklsajfkdls","7d");
          }
          else{
              this.downloadVisible = false;
              document.getElementById("test").click();
          }
      },
    // excelDownload(table,name){
    //    var uri = 'data:application/vnd.ms-excel;base64,'
    //       , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
    //       , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))); }
    //       , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }); };
    //     if (!table.nodeType) table = document.getElementById(table);
    //     var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML };
    //     window.location.href = uri + base64(format(template, ctx));
    // },
    handleOk() {
      this.ModalText = "The modal will be closed after two seconds";
      setTimeout(() => {
        this.downloadVisible = false;
      }, 2000);
    },
    handleCancel() {
      // console.log("Clicked cancel button");
      this.downloadVisible = false;
    },
    callback(key) {
      if(key == 2){
        this.visible= false;
        this.selectGender= true;
      }
      else{
        this.visible= true;
        this.selectGender= false
      }
    },
    async fetcheventapidata() {
      this.json_fields = this.json_fields_district;
      await event.fetcheventcomplete(this.event_type, this.group_by,this.countOf, this.start_date, this.end_date).then((res) => {
        this.event_data = res.data.data;
        this.data = res.data.data;
        this.keydata();
        // console.log("-");
      });
      // console.log('--check-data');

      this.changeGraphData();
      this.addEventStatusRowData();
    },
   async changeeventapidata() {
      switch (this.group_by) {
        case "vdc":
          this.columns[1].dataIndex = "vdc";
          this.columns[1].key = "vdc";
          this.columns[1].title = "Local Level";
          this.json_fields = this.json_fields_vdc;
          break;
        case "district":
          this.columns[1].dataIndex = "district";
          this.columns[1].key = "district";
          this.columns[1].title = "District";
          this.json_fields = this.json_fields_district;
          break;
        case "province_id":
          this.columns[1].dataIndex = "province_id";
          this.columns[1].key = "province_id";
          this.columns[1].title = "Province";
          this.json_fields = this.json_fields_province;
          break;
      }
      await event.fetcheventcomplete(this.event_type, this.group_by,this.countOf, this.start_date, this.end_date).then((res) => {
        this.data = "";
        // console.log(this.group_by);
        // console.log(res);
        this.event_data = res.data.data;
        this.data = res.data.data;
        // console.log("------");
        // console.log(this.data);
        this.keydata();
        this.key_columns = Object.keys(this.data[0]);
      });
      this.addEventStatusRowData();
      this.changeGraphData();

    },
     async changecountofData() {
       await event.fetcheventcomplete(this.event_type, this.group_by,this.countOf, this.start_date, this.end_date).then((res) => {
        this.data = res.data.data;
        this.keydata();
      });
       this.fetchVictimAndPerpetratorData();
         this.addEventStatusRowData();
         this.changeGraphData(this.countOf);
    },
    changesearchdata() {},
    onSearch(value) {
      let test = this.data.filter((data) => {
        const male_count = data.male_count.toString();
        const female_count = data.female_count.toString();
        const report = data.report_count.toString();
        const other = data.others_count.toString();
        //const total = data.total.toString();

        let vdc = "";
        if (data.vdc !== undefined) {
          vdc = data.vdc.toString().toLowerCase();
        }
        let district = "";
        if (data.district !== undefined) {
          district = data.district.toLowerCase();
        }
        let province = "";
        if (data.province_id !== undefined) {
          province = data.province_id.toString();
        }
        const filter = value.toString().toLowerCase();

        return male_count.includes(filter) ||
          female_count.includes(filter) ||
          report.includes(filter) ||
          other.includes(filter) ||
          district != ""
          ? district.includes(filter)
          : "" || vdc != ""
          ? vdc.includes(filter)
          : "" || province != ""
          ? province.includes(filter)
          : "";
      });
    //   console.log(value.toString().toLowerCase());
      this.data = test;
      this.keydata();
    //   console.log(test);
    },
    keydata() {
      let i = 0;
      this.data.forEach(function (dat) {
        dat["key"] = i + 1;
        i++;
      });
    //   console.log(this.data);
    },
    /*  rename(value) {
                  let objectOrder = '';
                  if(value != ''){
                      switch (value){
                          case  'vdc':
                              if("District" in this.json_fields){
                                   objectOrder = {
                                      'VDC': null,
                                  }
                                  this.json_fields.VDC = this.json_fields.District;
                                  this.json_fields.VDC = 'vdc';
                                  delete this.json_fields.District;
                                  Object.assign(objectOrder, this.json_fields)
                              }
                              else if("Province" in this.json_fields){
                                  this.json_fields.VDC = this.json_fields.Province;
                                  delete this.json_fields.Province;
                                  Object.assign(objectOrder, this.json_fields)
                              }
                              break;
                          case  'district':
                              if("VDC" in this.json_fields){
                                  this.json_fields.District = this.json_fields.VDC;
                                  delete this.json_fields.VDC;
                                  Object.assign(objectOrder, this.json_fields)
                              }
                              else if("Province" in this.json_fields){
                                  this.json_fields.District = this.json_fields.Province;
                                  delete this.json_fields.Province;
                                  Object.assign(objectOrder, this.json_fields)
                              }
                              break;
                          case  'province_id':
                              if("VDC" in this.json_fields){
                                  this.json_fields.Province = this.json_fields.VDC;
                                  delete this.json_fields.VDC;
                                  Object.assign(objectOrder, this.json_fields)
                              }
                              else if("District" in this.json_fields){
                                  this.json_fields.Province = this.json_fields.District;
                                  delete this.json_fields.District;
                                  Object.assign(objectOrder, this.json_fields)

                              }
                              break;
                      }
                  }
                     /!* if(value == 'vdc'){
                          this.json_fields.vdc = this.json_fields.District;
                          delete this.json_fields.District;
                      }
                      else if(value == 'district'){
                          this.json_fields.vdc = this.json_fields.District;
                          delete this.json_fields.District;
                      }
                      else if(value == 'province_id'){

                      } this.json_fields.vdc = this.json_fields.District;
                      delete this.json_fields.District;
                  }*!/

              }*/
    changeoption(value) {
      switch (value) {
        case "table":
          this.table_data = 1;
          this.map_data = 0;
          this.graph_data = 0;
          break;
        case "map":
          this.map_data = 1;
          this.table_data = 0;
          this.graph_data = 0;
          break;
        case "graph":
          this.graph_data = 1;
          this.table_data = 0;
          this.map_data = 0;
          break;
      }
    },
    async changebtnEventData(value, title) {
       this.overlay_visible=true;
     await event.fetcheventcomplete(value,this.group_by,this.countOf, this.start_date, this.end_date).then((res) => {
         this.event_type = value;
        // console.log(res);
        this.report_head = title;
        // console.log(this.report_head);
        // console.log(res);
        this.data = res.data.data;
        this.keydata();
        this.overlay_visible=false;
      });
     this.fetchVictimAndPerpetratorData();
     this.addEventStatusRowData();
     this.changeGraphData();

    },
      changeGraphData(value = ''){
          let title = this.report_head + ' ' + 'Graph';
          this.chartOptions = {
              title: {
                  text: title
              },
          }
          let series_data  = [];
          this.data.forEach(function (dat) {
              series_data.push({
                  x:dat.label,
                  y:dat.report_count
              })
          });
          series_data.pop();
         this.series = [
             {
                 name:"Reports",
                 data: series_data
             }
         ]
          if(value == 'male_count'){
              this.updateSeries();
          }
          else if(value == 'female_count'){
              this.updateFemaleSeries();
          }
          else if(value == 'others_count'){
              this.updateOthersSeries();
          }
          else  if(value == 'total_count'){
              this.updateTotalSeries();
          }
          else if(value == 'perpetrator'){
              this.updatePerperatorSeries();
          }
      },
      updateSeries() {
          let series_data = [];
          this.data.forEach(function (dat) {
              series_data.push({
                  x: dat.label,
                  y: dat.male_count,
              });
          });
          series_data.pop();
          this.series.push({
              name: "Male Count",
              data: series_data,
          });
      },
      updateFemaleSeries(){
          let female_data = [];
          this.data.forEach(function (dat) {
              female_data.push({
                      x: dat.label,
                      y: dat.female_count
                  }
              );
          });
          female_data.pop();
          this.series.push({
              name:'Female_count',
              data:female_data
          });
      },
      updateOthersSeries(){
          let others_data  = [];
          this.data.forEach(function (dat) {
              others_data.push({
                  x:dat.label,
                  y:dat.others_count
              });
          });
          others_data.pop();

          this.series.push({
              name:'Others Count',
              data:others_data
          })
      },
      updateTotalSeries(){
          let total_series = [];
          this.data.forEach(function (dat) {
              total_series.push({
                  x:dat.label,
                  y:dat.total
              });
          })
          total_series.pop();
          this.series.push({
              name:'Total Count',
              data:total_series
          })
      },
      updatePerperatorSeries(){
          let perperator_data = [];
          this.data.forEach(function (dat) {
              perperator_data.push({
                  x: dat.label,
                  y: dat.total
              });
          })
          perperator_data.pop();
          this.series.push({
              name: 'Perperator Count',
              data:perperator_data
          })
      },
      addEventStatusRowData(){
          let report_count_data = 0;
          let male_count_data = 0;
          let female_count_data = 0;
          let others_count_data  = 0;
          let total_count_data = 0;
          this.data.forEach(function (dat) {
              report_count_data += dat.report_count;
              male_count_data += dat.male_count;
              female_count_data += dat.female_count;
              others_count_data += dat.others_count;
              total_count_data += dat.total;
          });
          this.data.push({
              report_count : report_count_data,
              male_count: male_count_data,
              female_count : female_count_data,
              others_count: others_count_data,
              total : total_count_data
          });
      },
      async eventStatusDatepickerData(){
          let start_date = this.start_date;
          let end_date = this.end_date;
          await event.changeeventstatusdate(this.event_type, this.group_by,this.countOf,
              start_date,end_date).then((res)=>{
              this.data = res.data.data;
              this.keydata();
          });
          this.fetchVictimAndPerpetratorData();
          this.addEventStatusRowData();
      },
      async fetchVictimAndPerpetratorData(){
          let countOfVictim='victim';
          let countOfPerpetrator = 'perpetrator';
          let total_v_data = 0;
          let total_p_data = 0;
          await  event.fetchEsvictimcountdata(this.event_type,  this.group_by, countOfVictim, this.start_date,this.end_date).then((res)=>{
              let victim_data = res.data.data;
              // console.log('test');
              // console.log(victim_data);
              victim_data.forEach(function (v_d) {
                  total_v_data += v_d.total;
              });
          });
          await event.fetchEsperpetratorcountdata(this.event_type,  this.group_by, countOfPerpetrator, this.start_date,this.end_date).then((res)=>{
              let perpet_data = res.data.data;
              perpet_data.forEach(function (per_d) {
                  total_p_data += per_d.total;
              })
          });
          this.es_victim_event_dynamic_data = total_v_data;
          this.es_perpetrator_dynamic_data = total_p_data;
      }
  },
};
</script>

<style>
/*----Report CSS*/
/*.title{*/
/*margin-top: 50px;*/
/*}*/
.button-wrapper .btn {
  margin-right: 15px;
  width: 90px;
  height: 37px;
  color: white;
}
/* .button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
} */
.search-btn {
  margin-left: 20px;
  width: 200px;
}
.d-table {
  margin-top:0px !important;
  overflow:auto;
}
.module {
  text-align: center;
  cursor: pointer;
}
.module-wrapper {
  border:none !important;
}
/* flex-flow: column;*/
/*.module-wrapper .ant-card-body {*/
/*padding: 9px !important;*/
/*}*/
/*.module p {*/
/*margin-top: 4px;*/
/*}*/

.test {
  height:100px;
}
.test p {
  width: 150px;
    word-wrap: break-word;
    margin: 5px auto;
    text-align: center;
}
.all-card-wrapper {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  width: 80%;
  margin: auto;
}
.event-title {
  margin-left: auto;
  margin-right: auto;
  margin-top: -26px;
  background: #fff;
  width: 52%;
  text-align: center;
}
.test:hover img {
  transform: scale(0.95);
  transition: all 0.3s ease;
}
@media(max-width:1125px){
  .btn-child{
    margin-bottom:5px;
  }
}
@media(max-width:995px){
  .stat{
    margin-bottom:5px;
  }
}
.stat{
  width:100%;
  background:#00599a;
  padding:5px 10px;
  border-radius:5px 10px;
  text-align:center;
  color:#fff;
}
.overlay{
  height:100%;
  width:100%;
  background:rgba(0,0,0,0.6);
  position:fixed;
  top:0px;
  left:0px;
  z-index: 1000;
  display:flex;
  align-items:center;
  justify-content: center;
}
.overlay > .ant-spin-spinning{
  position:fixed !important;
  top:50% !important; 
}

.loaderOnClick{
	position: relative;
	width: 12px;
	height: 12px;
	border-radius: 12px;
	background-color: #feab56;
	-webkit-transform-origin:  50% 50%;
			transform-origin:  50% 50% ;
	-webkit-animation: loaderOnClick 1s ease-in-out infinite;
			animation: loaderOnClick 1s ease-in-out infinite;
}

.loaderOnClick:before{
	content: "";
	position: absolute;
	background-color:#c3535f;
	top: 0px;
	left: -25px;
	height: 12px;
	width: 12px;
	border-radius: 12px;
}

.loaderOnClick:after{
	content: "";
	position: absolute;
	background-color:#78c972;
	top: 0px;
	left: 25px;
	height: 12px;
	width: 12px;
	border-radius: 12px;
}


@-webkit-keyframes loaderOnClick{
    0%{-webkit-transform:rotate(0deg);}
    50%{-webkit-transform:rotate(180deg);}
    100%{-webkit-transform:rotate(180deg);}
}

@keyframes loaderOnClick{
    0%{transform:rotate(0deg);}
    50%{transform:rotate(180deg);}
    100%{transform:rotate(180deg);}
}
.btn-child .ant-calendar-picker{
    width:100%;
}
</style>