<template>
  <div class="map" ref="map">
     <div class="province"  >
     <div class="spinBefore" v-if="loading"></div>
    </div>
    <div id="slider"></div>
    <div id="legend">
      <div class="title">0 . . . . . . . . . . . . . 200+</div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import d3 from "d3";
import * as topojson from "topojson-client";
import {EventBus} from "@/main";

export default {
  props: ['date_data'],
  name: "province",
  data() {
    return {
      provinceNum: null,
      districtData: null,
      updatedDistrictData: 2,
      clickCount: 0,
      date_p: '',
       loading:true,
    };
  },
  
  created() {
    this.provinceNum = parseInt(this.$route.params.id);
    //this.date_p = parseInt(this.date_data);
  },
  methods: {
    async createSvg() {
      //slider
      let margin = {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10,
      };
      let active = d3.select(null);

      let tooltip = d3.select('body').append('div')
          .attr('class', 'hidden tooltip');

      let reportById = d3.map();
      let victimsById = d3.map();
      let perpsById = d3.map();
      let valueById = {};

      let width = this.$refs.map.clientWidth - 50;
      let height = 400;

      d3.selectAll('svg').remove();

      let svg = d3
          .select(".map")
          .append("svg")
          .attr("class", "center-container")
          .attr("height", height)
          .attr("width", width);

      svg
          .append("rect")
          .attr("class", "background center-container")
          .attr("height", height + margin.top + margin.bottom)
          .attr("width", width + margin.left + margin.right)
          .on("click", clicked);

      let province = this.provinceNum;
      let province_year = parseInt(this.date_data);
        //this.$emit('update_list', province_year);
      let provinceCenter = {
        1: [87.2, 27.25],
        2: [85.75, 27],
        3: [85.25, 27.65],
        4: [84.05, 28.35],
        5: [82.5, 28.1],
        6: [82.325, 29.3],
        7: [80.95, 29.3],
      };

      let color = d3.scale
          .threshold()
          .domain([25, 50, 75, 100, 150, 200])
          .range([
            "#c9edef",
            "#a0d9ef",
            "#1fbaea",
            "#19a2d8",
            "#1187c1",
            "#0a6aa5",
          ]);

      let localLevelColor = d3.scale
          .threshold()
          .domain([5, 10, 15, 20, 25, 30])
          .range([
            "#f6bdc0",
            "#f1959b",
            "#f1959b",
            "#f07470",
            "#ea4c46",
            "#dc1c13",
          ]);

      let projection = d3.geo
          .mercator()
          .scale(9000)
          .translate([width / 2, height / 2])
          .center(provinceCenter[province]);

      let path = d3.geo.path().projection(projection);

      let g = svg
          .append("g")
          .attr("class", "center-container center-items us-state")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom);

      let localgeoData, geoData, tipData, localtipData, allData;
      // Promise.resolve(d3.json("/public/nepal_local_level.json"))
      //  .then(localLevel);
      const reqOne = axios.get(
          `https://hrv.insec.org.np/api/v1/province-data?province=${province}&type=data&groupBy=district&year=${province_year}`
      );
      const reqTwo = axios.get(
          `https://hrv.insec.org.np/api/v1/province-data?province=${province}&type=data&groupBy=vdc&year=${province_year}`
      );
      await axios
          .all([reqOne, reqTwo])
          .then(
              axios.spread((...responses) => {
                tooltipData(responses[0].data, responses[1].data);
                allData = responses[0].data;
                EventBus.$emit('districtData', responses[0].data);
                EventBus.$emit('year', province_year);
              })
          )
          .catch((errors) => {
            // react on errors.
            console.log(errors);
          });

      const reqThree = axios.get("/nepal-districts.json");
      const reqFour = axios.get("/nepal_local_level.json");
      await axios
          .all([reqThree, reqFour])
          .then(
              axios.spread((...responses) => {
                ready(responses[0].data, responses[1].data);
              })
          )
          .catch((errors) => {
            // react on errors.
            console.log(errors);
          });

      function tooltipData(district, local_level) {
        tipData = district.data;

        localtipData = local_level.data;
      }


      function ready(us, local_level) {
        localgeoData = topojson
            .feature(local_level, local_level.objects.data)
            .features.filter(function (d) {
              return d.properties.PROVINCE === province;
            });
        geoData = topojson
            .feature(us, us.objects.data)
            .features.filter(function (d) {
              return d.properties.PROVINCE === province;
            });
        tipData.forEach(function (d) {
          valueById[d.district] = d.district;
          reportById.set(d.district, d.report);
          victimsById.set(d.district, d.victims);
          perpsById.set(d.district, d.perpetrator);
          geoData.forEach(function (v) {
            if (d.district === v.properties.DISTRICT) {
              v.properties.value = d.report;
            }
          });
        });
        localtipData.forEach(function (d) {
          valueById[d.vdc + d.district] = d.district;
          reportById.set(d.vdc + d.district, d.report);
          victimsById.set(d.vdc + d.district, d.victims);
          perpsById.set(d.vdc + d.district, d.perpetrator);

          localgeoData.forEach(function (v) {
            if (
                d.vdc === v.properties.LOCAL &&
                d.district === v.properties.DISTRICT
            ) {
              v.properties.value = d.report;
            }
          });
        });
        g.append("g")
            .attr("id", "counties")
            .selectAll("path")
            .data(localgeoData)
            .enter()
            .append("path")
            .attr("d", path)
            // .on("mouseover", tip.show)
            // .on("mouseout", tip.hide)
            .attr("class", "county-boundary")
            .on('mouseover', function (d) {
              // console.log(d)
              var mouse = d3.mouse(svg.node()).map(function (d) {
                return parseInt(d);
              });
              tooltip.classed('hidden', false)
                  .attr('style', 'left:' + (mouse[0]) +
                      'px; top:' + (mouse[1] + 100) + 'px')
                  .html(d.properties.LOCAL);
            })
            .on('mouseout', function () {
              tooltip.classed('hidden', true);
            })
            .on("click", localClicked)
            .on("dblclick", reset)
            .style("fill", function (d) {
              return localLevelColor(d.properties.value);
            });

        g.append("g")
            .attr("id", "states")
            .selectAll("path")
            .data(geoData)
            .enter()
            .append("path")
            .attr("d", path)
            .attr("class", "state")
            .on("click", clicked)
            .on('mouseover', function (d) {
              // console.log(d)
              var mouse = d3.mouse(svg.node()).map(function (d) {
                return parseInt(d);
              });
              tooltip.classed('hidden', false)
                  .attr('style', 'left:' + (mouse[0]) +
                      'px; top:' + (mouse[1] + 100) + 'px')
                  .html(d.properties.DISTRICT);
            })
            .on('mouseout', function () {
              tooltip.classed('hidden', true);
            })
            .style("fill", function (d) {
              return color(d.properties.value);
            });

        g.append("path")
            .datum(
                topojson
                    .feature(us, us.objects.data, function (a, b) {
                      return a !== b || a === b;
                    })
                    .features.filter(function (d) {
                  return d.properties.PROVINCE === province;
                })
            )
            .attr("id", "state-borders")
            .attr("d", path);
      }

      let tip = d3
          .tip()
          .attr("class", "d3-tip")
          .offset([0, 0])
          .direction("n")
          .html(function (d) {
            let place_name = d.properties.LOCAL ?? d.properties.DISTRICT;
            let report =
                reportById.get(d.properties.LOCAL + d.properties.DISTRICT) ??
                reportById.get(d.properties.DISTRICT);
            let victims =
                victimsById.get(d.properties.LOCAL + d.properties.DISTRICT) ??
                victimsById.get(d.properties.DISTRICT);
            let perps =
                perpsById.get(d.properties.LOCAL + d.properties.DISTRICT) ??
                perpsById.get(d.properties.DISTRICT);
            return (
                place_name +
                "<br/>Total Reports: " +
                report +
                "<br/>Total Victims: " +
                victims +
                " <br/>Total Perpetrator: " +
                perps
            );
          });

      svg.call(tip);

      // console.log(this);
      function clicked(d) {
        if (d3.select(".background").node() === this) return reset();

        if (active.node() === this) return reset();

        active.classed("active", false);

        active = d3.select(this).classed("active", true);

        var bounds = path.bounds(d),
            dx = bounds[1][0] - bounds[0][0],
            dy = bounds[1][1] - bounds[0][1],
            x = (bounds[0][0] + bounds[1][0]) / 2,
            y = (bounds[0][1] + bounds[1][1]) / 2,
            scale = 0.9 / Math.max(dx / width, dy / height),
            translate = [width / 2 - scale * x, height / 2 - scale * y];

        g.transition()
            .duration(750)
            .style("stroke-width", 1.5 / scale + "px")
            .attr(
                "transform",
                "translate(" + translate + ")scale(" + scale + ")"
            );
        let districtData = [];
        tipData.forEach(function (data) {
          if (data.district === d.properties.DISTRICT) {
            districtData.push(data)
            let response = {
              success: true,
              data: districtData,
              message: data.district
            }
            EventBus.$emit('districtData', response);
          }
        });
      }

      function localClicked(d) {

        let districtData = [];
        localtipData.forEach(function (data) {
          if (data.vdc === d.properties.LOCAL) {
            districtData.push(data)
            let response = {
              success: true,
              data: districtData,
              message: data.vdc
            }
            EventBus.$emit('districtData', response);
          }
        });
      }

      function reset() {
        active.classed("active", false);
        active = d3.select(null);

        g.transition()
            .delay(100)
            .duration(750)
            .style("stroke-width", "1.5px")
            .attr(
                "transform",
                "translate(" + margin.left + "," + margin.top + ")"
            );

        EventBus.$emit('districtData', allData);
      }

      var legend = d3
          .select("#legend")
          .append("svg:svg")
          .attr("width", 160)
          .attr("height", 10);
      for (var i = 0; i <= 5; i++) {
        legend
            .append("svg:rect")
            .attr("x", i * 20)
            .attr("height", 10)
            .attr("width", 20)
            .attr("class", "q" + i + "-9 "); //color
      }
    },
  },
  watch: {
    districtData() {
      // Emit this information to the parents component
      this.$emit("districtData", this.districtData);
      // console.log(this.districtData);
    },
  },
  mounted() {
    this.createSvg();
     setTimeout(()=>{
      this.loading = false;
      // this.loadingTitle = true;
    },5000);
  },
};
</script>

<style>
.d3-tip {
  line-height: 1.5;
  padding: 8px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 0px;
  text-align: center;
}

.map {
  /* background: ; */
  border-radius: 10px;
  padding: 20px;
  height: 75vh;
  overflow:auto;
}

.background {
  fill: none;
  pointer-events: all;
}

#states {
  fill: #aaa;
}

#states .active {
  display: none;
}

#state-borders {
  fill: none;
  stroke: #fff;
  stroke-width: 3px !important;
  stroke-linejoin: round;
  stroke-linecap: round;
  pointer-events: none;
}

.state {
  fill: #aaa;
  stroke: #fff;
  stroke-width: 1px;
}

.county-boundary {
  fill: #aaa;
  stroke: #fff;
  stroke-width: 0.5px;
}

.county-boundary:hover,
.state:hover {
  fill: orange !important;
}

.q0-9 {
  fill: #c9edef;
}

.q1-9 {
  fill: #a0d9ef;
}

.q2-9 {
  fill: #1fbaea;
}

.q3-9 {
  fill: #19a2d8;
}

.q4-9 {
  fill: #1187c1;
}

.q5-9 {
  fill: #0a6aa5;
}

.hidden {
  display: none;
}

div.tooltip {
  color: #ffffff;
  background-color: #030303;
  padding: .5em;
  text-shadow: #f5f5f5 0 1px 0;
  border-radius: 2px;
  opacity: 0.9;
  position: absolute;
}
/* .province >.ant-spin-spinning{
  position:absolute !important;
  left:15rem !important;
  top:21rem !important;
} */
.province{
      position: absolute;
    top: 50%;
    left: 50%;
}
.title{
  margin-top:0px !important;
}


.spinBefore{
	position: relative;
	width: 12px;
	height: 12px;
	border-radius: 12px;
	background-color: #feab56;
	-webkit-transform-origin:  50% 50%;
			transform-origin:  50% 50% ;
	-webkit-animation: spinBefore 1s ease-in-out infinite;
			animation: spinBefore 1s ease-in-out infinite;
}

.spinBefore:before{
	content: "";
	position: absolute;
	background-color:#c3535f;
	top: 0px;
	left: -25px;
	height: 12px;
	width: 12px;
	border-radius: 12px;
}

.spinBefore:after{
	content: "";
	position: absolute;
	background-color:#78c972;
	top: 0px;
	left: 25px;
	height: 12px;
	width: 12px;
	border-radius: 12px;
}


@-webkit-keyframes spinBefore{
    0%{-webkit-transform:rotate(0deg);}
    50%{-webkit-transform:rotate(180deg);}
    100%{-webkit-transform:rotate(180deg);}
}

@keyframes spinBefore{
    0%{transform:rotate(0deg);}
    50%{transform:rotate(180deg);}
    100%{transform:rotate(180deg);}
}
</style>
