<template>
  <a-layout>
    <a-layout-header class="header">
      <a-row class="navigation-wrapper">
        <a-col :xs="22" :sm="22" :md="10" :lg="9" :xl="9">
          <div class="logo" @click="logoClick">
            <a href="/"> <img
                src="../../../public/icons/Hr_logo_250px-64.png"
                class="img_logo"
              /></a>
            <!-- <router-link to="/" exact>
              <img
                src="../../../public/icons/Hr_logo_250px-64.png"
                class="img_logo"
              />
            </router-link> -->
          </div>
        </a-col>

        <a-col class="header-left" :xs="2" :sm="2" :md="12" :lg="12" :xl="12">
          <a-menu
            class="menu-wrapper"
            mode="horizontal"
            :default-selected-keys="[menuKey]"
            :style="{ lineHeight: '64px' }"
          >
            <a-menu-item key="/"
              ><router-link to="/" exact>Home</router-link></a-menu-item
            >
            <a-menu-item key="/event_type/Abduction"
              ><router-link to="/event_type/Abduction"
                >Event Types</router-link
              ></a-menu-item
            >
            <a-menu-item key="/geography"
              ><router-link to="/geography"> Geography</router-link>
            </a-menu-item>
            <a-menu-item key="/event_status">
              <router-link to="/event_status">Event Status</router-link>
            </a-menu-item>
            <a-menu-item key="/datasets"
              ><router-link to="/datasets"> Datasets </router-link></a-menu-item
            >
            <a-menu-item key="/contactus"
              ><router-link to="/contactus"> Contact </router-link></a-menu-item
            >
          </a-menu>
          <!-- language -->
        </a-col>
        <a-col :xs="0" :sm="0" :md="0" :lg="2" :xl="2">
          <a-menu style="background: transparent; border-right: none">
            <a-menu-item>
              <div class="language-flag" :xs="0" :sm="0" :md="0" :lg="0">
                <!-- <img src="../../../public/icons/united-states.png" alt="" height="30" width="30"> -->
                <a-dropdown class="dropdown">
                  <a-menu slot="overlay">
                    <a-menu-item key="1">ENG</a-menu-item>
                  </a-menu>
                  <a-button>Lang<a-icon type="down" /> </a-button>
                </a-dropdown>
              </div>
            </a-menu-item>
          </a-menu>
        </a-col>
      </a-row>
    </a-layout-header>
  </a-layout>
</template>

<script>
import ARow from "ant-design-vue/es/grid/Row";
import ACol from "ant-design-vue/es/grid/Col";
export default {
  name: "Index.vue",
  components: { ACol, ARow },
  data() {
    return {
      menuKey: this.$route.path,
    };
  },
  methods: {
    logoClick() {
      this.menuKey = this.$route.path;
    },
  },
};
</script>

<style scoped>
.navigation-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-layout-header {
  padding: 0;
}
.ant-menu-horizontal > .ant-menu-item-selected > a,
.ant-menu-horizontal > .ant-menu-item > a:hover {
  color: #00599a;
}
.header {
  height: 90px;
  background: #008ddf;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-right: 40px;
}
.ant-menu-horizontal {
  background: #008ddf;
}
.ant-menu-item {
  font-size: 1rem;
}
/* .ant-menu-item:active{
} */

.search {
  padding-top: 35px;
}
.main-body {
  margin-top: 20px;
}
.main-body .title {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 29px;
}
.ant-menu-horizontal > .ant-menu-item > a {
  color: #fff;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #00599a;
  border-bottom: 2px solid #78c972;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  color: #fff !important;
}

@media screen and (max-width: 500px) {
  .img_logo {
    width: 80%;
    margin-left: 5px !important;
  }
}
/* language */
.language-flag {
  position: absolute;
  right: 0px;
  top: 0px;
}
.dropdown {
  padding: 5px !important;
  background: #008ddf;
  color: #fff !important;
}
.dropdown:hover,
.dropdown:active {
  background: #008ddf !important;
}
.dropdown:focus {
  background: none;
}
.menu-wrapper {
  color: #fff;
  border-bottom: none;
}
.img_logo {
  margin-top: 5px;
  /* margin-left: -12px; */
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
</style>
