<template>
  <div class="landingPage">
    <Popup />
    <!-- logo section -->
    <a-row>
      <a-col :span="20" :xs="23" :sm="23" :md="22" :lg="20" :xl="20">
        <div class="container1">
          <a-row :gutter="[48, 24]">
            <a-col :xs="24" :sm="24" :md="24" :lg="9" :xl="12">
              <!-- text section -->
              <div class="text-wrapper">
                <h2>
                  Welcome to Human Rights Violation Open Data
                </h2>
                <p style="text-align: justify">
                  Human Rights Violation Open Data Portal aims to disseminate
                  the data related to human rights violations documented by
                  INSEC in all the districts of Nepal. It was established in
                  2020. The portal is updated every three months and the user is
                  able to acquire specific data of human rights on the basis of
                  the provided categories. Once a year, the data and its detail
                  is published in Nepal Human Rights Yearbook. We request the
                  users to derive maximum information in a convenient and
                  ethical manner.
                  <router-link
                    to="/how-is-data-gathered"
                    style="color: #00599a; font-weight: bold"
                  >
                    More Details
                  </router-link>
                </p>
              </div>
              <!-- search ares -->
              <div class="search-wrapper">
                <p>
                  Search required data or visit this
                  <router-link to="/event_type/Abduction"
                    >Open Data Portal Link</router-link
                  >
                  <!-- <a href="">Open Data Portal Link</a>-->
                </p>
                <a-auto-complete
                  :data-source="dataSource"
                  style="width: 100%"
                  class="input-box"
                  :placeholder="
                    'Search Over ' + hrvStats.data_sets + ' Dataset'
                  "
                  @select="onSelect"
                  :filter-option="filterOption"
                >
                  <a-input>
                    <a-button
                      slot="suffix"
                      style="margin-right: -12px"
                      class="search-btn"
                      type="primary"
                    >
                      <a-icon type="search" />
                      <router-link
                        v-bind:to="'/event_type/' + search_data"
                      ></router-link>
                    </a-button>
                  </a-input>
                </a-auto-complete>
              </div>
              <mark style="position: relative; left: 10px"
                >Last Updated : 2023-02-22</mark
              >
              <!-- icons -->

              <div class="icons-wrapper">
                <a-row>
                  <a-col
                    :xl="{ span: 4, offset: 1 }"
                    :lg="{ span: 4, offset: 1 }"
                    :md="11"
                    :sm="11"
                    :xs="11"
                  >
                    <a-tooltip class="pop" placement="bottom" trigger="hover">
                      <template slot="title">
                        <p>A dataset is a structured collection of data.</p>
                      </template>
                      <div class="icon-wrapper">
                        <img
                          src="../../public/icons/Mock_insec_Datasets.svg"
                          alt=""
                          height="50"
                          width="50"
                        />
                      </div>
                      <div class="data-wrapper">
                        <h2>{{ hrvStats.data_sets }}</h2>
                        <p>Datasets</p>
                      </div>
                    </a-tooltip>
                  </a-col>
                  <a-col
                    :xl="{ span: 4, offset: 1 }"
                    :lg="{ span: 4, offset: 2 }"
                    :md="11"
                    :sm="11"
                    :xs="11"
                  >
                    <a-tooltip class="pop" placement="bottom" trigger="hover">
                      <template slot="title">
                        <p>
                          Total reports that were verified by moderators and
                          admin.
                        </p>
                      </template>
                      <div class="icon-wrapper">
                        <img
                          src="../../public/icons/Mock_insec_Sources.svg"
                          alt=""
                          height="50"
                          width="50"
                        />
                      </div>
                      <div class="data-wrapper">
                        <h2>{{ hrvStats.sources }}</h2>
                        <p>Verified Reports</p>
                      </div>
                    </a-tooltip>
                  </a-col>
                  <a-col
                    :xl="{ span: 4, offset: 2 }"
                    :lg="{ span: 4, offset: 2 }"
                    :md="11"
                    :sm="11"
                    :xs="11"
                  >
                    <a-tooltip class="pop" placement="bottom" trigger="hover">
                      <template slot="title">
                        <p>Total number of district represenatitve</p>
                      </template>
                      <div class="icon-wrapper">
                        <img
                          src="../../public/icons/Mock_insec_Contributors.svg"
                          alt=""
                          height="50"
                          width="50"
                        />
                      </div>
                      <div class="data-wrapper">
                        <!-- <h2>{{hrvStats.volunteers}}</h2> -->
                        <h2>77</h2>
                        <p>District Representatives</p>
                      </div>
                    </a-tooltip>
                  </a-col>
                  <a-col
                    :xl="{ span: 4, offset: 2 }"
                    :lg="{ span: 4, offset: 2 }"
                    :md="11"
                    :sm="11"
                    :xs="11"
                  >
                    <a-tooltip class="pop" placement="bottom" trigger="hover">
                      <template slot="title">
                        <p>Total number of documentation officers</p>
                      </template>
                      <div class="icon-wrapper">
                        <img
                          src="../../public/icons/Moderator_Up_arrow.svg"
                          alt=""
                          height="50"
                          width="50"
                        />
                      </div>
                      <div class="data-wrapper">
                        <!-- <h2>{{hrvStats.moderators}}</h2> -->
                        <h2>7</h2>
                        <p>Documentation Officers</p>
                      </div>
                    </a-tooltip>
                  </a-col>
                </a-row>
              </div>
            </a-col>
            <!-- map section -->
            <a-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="14"
              :xl="12"
              class="map-responsive"
            >
              <Map />
              <br />
              <EmailSub />
            </a-col>
          </a-row>
          <!-- footer image -->
          <a-row>
            <a-col :xs="24" :sm="24" :md="23" :lg="23" :xl="24">
              <footer class="footerImg-wrapper">
                <a-row>
                  <!-- <a-col :xs="24" :sm="24" :md="13" :lg="13" :xl="13"> -->
                  <h2>How is Human Rights Violation data generated?</h2>
                  <!-- </a-col>
                  <a-col :xs="24" :sm="24" :md="11" :lg="11" :xl="11">
                    
                  </a-col> -->
                </a-row>
                <router-link to="/how-is-data-gathered">
                 <img
                    src="../../public/icons/Footer_HQ.png"
                    alt=""
                    width="100%"
                  />
                </router-link> 
              </footer>
            </a-col>
          </a-row>
        </div>
      </a-col>
      <!-- slider component -->
      <a-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
        <div class="container2">
          <!-- <div class="slider-title">
            <h2>HRV Year Book</h2>
          </div> -->
          <Slider />
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Map from "./Landingpage/Map";
import Slider from "./Landingpage/Slider";
import HrvStatsService from "../services/hrvstats";
import EmailSub from "./EmailMe/Email";
import Popup from "./Modal/Modal";

export default {
  name: "landingPage",
  data() {
    return {
      search_data: "",
      dataSource: [
        "Abduction",
        "Arrest",
        "Beatings",
        "Child Rights",
        "Death in Detention",
        "Death in Jail",
        "Economic, Social and Cultural Rights",
        "Inhumane Behavior",
        "Injured",
        "Killing",
        "Racial Discrimination",
        "Right to Assembly",
        "Threats",
        "Torture",
        "Destruction",
        "Women Rights",
      ],
      hrvStats: {},
    };
  },
  components: {
    Map,
    Slider,
    EmailSub,
    Popup,
  },
  created() {
    this.fetchStats();
  },
  methods: {
    onSelect(value) {
      // console.log("onSelect", value);
      this.$router.push("/event_type" + "/" + value);
      //this.$router.push({ name: 'report', params: { data: 'abduction' }});
      this.search_data = value;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toUpperCase()
          .indexOf(input.toUpperCase()) >= 0
      );
    },
    fetchStats() {
      HrvStatsService.fetchStats().then((res) => {
        this.hrvStats = res.data;
      });
    },
  },
};
</script>

<style>
.container1 {
  padding-top: 25px;
  padding-left: 40px;
  padding-right: 0px;
  padding-bottom: 20px;
}
.container2 {
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 40px;
}
/* text */
.text-wrapper {
  margin-left: 9px;
}
.text-wrapper h1 {
  font-size: 1.5rem;
  text-align: justify;
  color: #444444;
}
.text-wrapper p {
  font-size: 1rem;
}
/* search area */
.search-wrapper {
  margin-left: 9px;
  margin-top: 10px;
}
.search-wrapper p {
  font-size: 1rem;
  text-align: justify;
}
.search-wrapper p a {
  color: #00599a;
  font-weight: bold;
}
/* input section */
.ant-btn-primary {
  background: #00599a !important;
  border: 2px solid #00599a !important;
  position: relative;
  right: 1px;
  width: 100px !important;
}
.ant-input {
  border: 1px solid #00599a !important;
}
.input-box {
  margin-top: 10px;
}

/* footer */
.footerImg-wrapper {
  margin-top: 20px;
}
/* icons wrapper */
.icons-wrapper a-row a-col {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.data-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  text-align: center;
}
.data-wrapper h2 {
  color: #00599a;
  margin: 0px;
  font-size: 1rem;
}
.data-wrapper p {
  margin-bottom: 0px;
}
.hooper:focus {
  outline: none !important;
}
.slider-title {
  font-size: 0.7rem;
  text-align: center;
}
.slider-title h2 {
  color: #008ddf;
}
.ant-menu-item,
.ant-menu-submenu-title {
  color: #fff !important;
}
/* popover */

.pop {
  cursor: help;
}
.ant-popover-inner {
  background-color: rgb(0 0 0 / 75%) !important;
}

@media only screen and (max-width: 575px) {
  /*Small smartphones [325px -> 425px]*/
  .map-wrapper {
    overflow: auto;
  }
  .language-flag {
    display: none;
  }
  .container1 {
    padding-left: 10px;
  }
  .container2 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .map-responsive {
    padding: 0px !important;
  }
  .footerImg-wrapper {
    text-align: center;
  }
  .text-wrapper p {
    text-align: center;
  }
  .text-wrapper h1 {
    text-align: center;
  }
  .search-wrapper p {
    text-align: center;
  }
}
@media only screen and (max-width: 760px) {
  .container1 {
    padding-left: 10px;
  }
  .container2 {
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media only screen and (max-width: 575px) {
  .map-responsive {
    padding: 0px 10px 0px 20px !important;
  }
}
/* slider-wrapper */
</style>
