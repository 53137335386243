<template>
  <div class="map-wrapper1" ref="map">
    <div class="geography">
       <!-- <a-spin v-if="loading" /> -->
          <div class="spinBefore" v-if="loading"></div>
    </div>
    <div id="map1"></div> 
    <div id="map-legend">
      <br/>
      <div class="title">
        HRV GIS Heatmap <br> Click to Expand
        <div class="title">0 . . . . . . . . . . . . 1000</div>
      </div>
    </div>
  </div>
</template>
<script>
//  import json from '../../nepaljson.json';
import d3 from "d3";
import * as topojson from "topojson-client";

// var width = 900, height = 500;
export default {    
  name: "main-map",
  data() {
    return {
      loading:true,
    };
  },
  methods: {
    createSvg() {
      var margin = {top: 20, right: 20, bottom: 20, left: 20};
      let width = this.$refs.map.clientWidth;
      let height = this.$refs.map.clientHeight;
      let reportById = d3.map();
      let victimsById = d3.map();
      let perpsById = d3.map();

      var color = d3.scale
          .threshold()
          .domain([100, 300, 500, 700, 800, 1000])
          .range([
            "#c9edef",
            "#a0d9ef",
            "#1fbaea",
            "#19a2d8",
            "#1187c1",
            "#0a6aa5",
            "#1fbaea",
          ]);
      d3.select("#map1").node();

      let tooltip = d3.select('body').append('div')
          .attr('class', 'hidden tooltip');

      var projection = d3.geo
          .mercator()
          .scale(4400)
          .translate([width / 2, height / 2])
          .center([83.985593872070313, 28.465876770019531]);

      var path = d3.geo.path().projection(projection);

      var svg = d3
          .select("#map1")
          .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      d3.json("./hermes_NPL_new_wgs_2.json", function (error, ok) {
        // console.log(ok.objects);
        var counties = topojson.feature(ok, ok.objects.hermes_NPL_new_wgs_1);

        d3.json("https://hrv.insec.org.np/api/v1/main-map", function (result) {
          var valueById = {};
          let data = result.data;
          data.forEach(function (d) {
            // console.log(d);
            valueById[d.province] = d.report;
            reportById.set(d.province, d.report);
            victimsById.set(d.province, d.victims);
            perpsById.set(d.province, d.perpetrator);
          });
          data.forEach(function (d) {
            counties.features.forEach(function (county) {
              if (d.province == county.properties.PROVINCE) {
                county.properties.value = d.report;
              }
            });
          });

          //counties
          svg
              .append("g")
              .attr("class", "county")
              .selectAll("path")
              .data(counties.features)
              .enter()
              .append("path")
              .attr("d", path)
              .style("fill", function (d) {
                // console.log(d.properties.value);
                return color(d.properties.value);
              })
              .on("mouseover", function (d) {
                var mouse = d3.mouse(svg.node()).map(function (d) {
                  return parseInt(d);
                });
                tooltip.classed('hidden', false)
                    .attr('style', 'left:' + (mouse[0]) +
                        'px; top:' + (mouse[1] + 100) + 'px')
                    .html(  d.properties.PR_NAME +
                        "<br/>Total Reports: " +
                        reportById.get(d.properties.PROVINCE) +
                        "<br/>Total Victims: " +
                        victimsById.get(d.properties.PROVINCE) +
                        " <br/>Total Perpetrator: " +
                        perpsById.get(d.properties.PROVINCE));
              })
              .on('mouseout', function () {
                tooltip.classed('hidden', true);
              })
              .on("click", redirectToProvince);

          d3.scale.sqrt().domain([0, 1e6]).range([0, 20]);

          function redirectToProvince(d) {
            // console.log(d.properties.PROVINCE);
            window.location.href = "/province/" + d.properties.PROVINCE;
          }

          //county borders
          svg
              .append("path")
              .datum(
                  topojson.mesh(
                      ok,
                      ok.objects.hermes_NPL_new_wgs_1,
                      function (a, b) {
                        return a !== b || a === b;
                      }
                  )
              )
              .attr("class", "county-border")
              .attr("d", path);
        });
      });


      var legend = d3
          .select("#map-legend")
          .append("svg:svg")
          .attr("width", 160)
          .attr("height", 10);
      for (var i = 0; i <= 5; i++) {
        legend
            .append("svg:rect")
            .attr("x", i * 20)
            .attr("height", 10)
            .attr("width", 20)
            .attr("class", "q" + i + "-9 "); //color
      }
    },
    redirectToProvince() {
      // console.log("Redirect me");
    },
  },
  mounted() {
    this.createSvg();
     setTimeout(()=>{
      this.loading = false;
      // this.loadingTitle = true;
    },5000);
  },
};
</script>

<style >
#map {
  margin-top: 50px;
  text-align: center;
  margin-left: 100px;
}

.county-border {
  fill: none;
  stroke: white;
}

.axis text {
  font: 10px sans-serif;
}

.axis line,
.axis path {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

#key {
  position: absolute;
  top: 90px;
  left: 50px;
}

#tooltip {
  position: absolute;
  padding: 10px;
  background-color: #ccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  pointer-events: none;
}

#tooltip.hidden {
  display: none;
}

/* map wrapper */
.map-wrapper1 {
  display: flex;
  align-items: Center;
  justify-content: center;
  /* background: #ebfbfe; */
  /* background:#e3ffe1; */
  border-radius: 10px;
  background-repeat: no-repeat;
  height: 78vh;
  width: 100%;
}

.hidden {
  display: none;
}

div.tooltip {
  color: #ffffff;
  background-color: #030303;
  padding: .5em;
  text-shadow: #f5f5f5 0 1px 0;
  border-radius: 2px;
  opacity: 0.9;
  position: absolute;
}

#map-legend {
  text-align: center;
  position: relative;
  right: 250px;
  top: -100px;
}

.q0-9 {
  fill: #c9edef;
}

.q1-9 {
  fill: #a0d9ef;
}

.q2-9 {
  fill: #1fbaea;
}

.q3-9 {
  fill: #19a2d8;
}

.q4-9 {
  fill: #1187c1;
}

.q5-9 {
  fill: #0a6aa5;
}
/* .geography >.ant-spin-spinning{
  position:absolute !important;
  right:45rem !important;
} */
.geography{
  position:absolute;
}
/* @media only screen and (max-width:590px){
.geography > .ant-spin-spinning{
   position:absolute !important;
   right:20rem !important;
}
} */

.spinBefore{
	position: relative;
	width: 12px;
	height: 12px;
	border-radius: 12px;
	background-color: #feab56;
	-webkit-transform-origin:  50% 50%;
			transform-origin:  50% 50% ;
	-webkit-animation: spinBefore 1s ease-in-out infinite;
			animation: spinBefore 1s ease-in-out infinite;
}

.spinBefore:before{
	content: "";
	position: absolute;
	background-color:#c3535f;
	top: 0px;
	left: -25px;
	height: 12px;
	width: 12px;
	border-radius: 12px;
}

.spinBefore:after{
	content: "";
	position: absolute;
	background-color:#78c972;
	top: 0px;
	left: 25px;
	height: 12px;
	width: 12px;
	border-radius: 12px;
}


@-webkit-keyframes spinBefore{
    0%{-webkit-transform:rotate(0deg);}
    50%{-webkit-transform:rotate(180deg);}
    100%{-webkit-transform:rotate(180deg);}
}

@keyframes spinBefore{
    0%{transform:rotate(0deg);}
    50%{transform:rotate(180deg);}
    100%{transform:rotate(180deg);}
}
</style>
 