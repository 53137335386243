<template>
  <div class="body">
    <!-- dashboard main content -->
    <a-row>
      <a-col :span="24" class="content-body">
  <div class="main-container">
          <div class="main-body fist-row">

      <div class="title">Explore by Event Type</div>
      <a-row class="modules-row">
        <a-col
          :xs="12"
          :sm="6"
          :md="{ span: 2, offset: 2 }"
          :lg="{ span: 2, offset: 5 }"
        >
          <div class="module">
            <a-card class="module-wrapper"  @click = "navrouteLink(value = 'abduction')">
              <img
                src="../../../public/icons/Dashboard/Icon__Abduction.svg"
                alt="Adbuction"
                width="50"
                height="50"
              />
            </a-card>
            <p>Abduction</p>
          </div>
        </a-col>
        <a-col :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper" @click = "navrouteLink(value = 'arrest')">
              <img
                src="../../../public/icons/Dashboard/Icon__Arrests.svg"
                alt="Arrests"
                width="50"
                height="50"
              />
            </a-card>
            <p>Arrests</p>
          </div>
        </a-col>
        <a-col :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper" @click = "navrouteLink(value = 'beatings')">
              <img
                src="../../../public/icons/Dashboard/Icon__Beating.svg"
                alt="beating"
                width="50"
                height="50"
              />
            </a-card>
            <p>Beating</p>
          </div>
        </a-col>
        <a-col :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper" @click = "navrouteLink(value = 'child rights')">
              <img
                src="../../../public/icons/Dashboard/Icon__Child_right.svg"
                alt="Child Rights"
                width="50"
                height="50"
              />
            </a-card>
            <p>Child Rights</p>
          </div>
        </a-col>
        <a-col :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper" @click = "navrouteLink(value = 'Racial Discrimination')">
              <img
                src="../../../public/icons/Dashboard/Icon__Discrimination.svg"
                alt="Discrimination"
                width="50"
                height="50"
              />
            </a-card>
            <p>Discrimination</p>
          </div>
        </a-col>
        <a-col :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper" @click = "navrouteLink(value = 'Women Rights')">
              <img
                src="../../../public/icons/Dashboard/Icon__women_right.svg"
                alt="Women Rights"
                width="50"
                height="50"
              />
            </a-card>
            <p>Women Rights</p>
          </div>
        </a-col>
        <a-col :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper" @click = "navrouteLink(value = 'torture')">
              <img
                src="../../../public/icons/Dashboard/Icon__Torture.svg"
                alt="Torture"
                width="50"
                height="50"
              />
            </a-card>
            <p>Torture</p>
          </div>
        </a-col>
      </a-row>
    </div>

    <div class="main-body second-row">
         <div class="title">Explore by Province</div>
      <a-row class="modules-row">
        <a-col
          :xs="12"
          :sm="6"
          :md="{ span: 2, offset: 2 }"
          :lg="{ span: 2, offset: 5 }"
          :xl="2"
        >
          <div class="module">
            <a-card class="module-wrapper">
              <img
                src="../../../public/icons/Dashboard/Icon__Province_1.svg"
                alt="Hotel"
                width="50"
                height="50"
              />
            </a-card>
            <p>Province no. 1</p>
          </div>
        </a-col>
        <a-col :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper">
              <img
                src="../../../public/icons/Dashboard/Icon__Province_2.svg"
                alt="Hotel"
                width="50"
                height="50"
              />
            </a-card>
            <p>Province no. 2</p>
          </div>
        </a-col>
        <a-col :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper">
              <img
                src="../../../public/icons/Dashboard/Icon__province_3.svg"
                alt="Hotel"
                width="50"
                height="50"
              />
            </a-card>
            <p>Province no. 3</p>
          </div>
        </a-col>
        <a-col :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper">
              <img
                src="../../../public/icons/Dashboard/Icon__province_4.svg"
                alt="Hotel"
                width="50"
                height="50"
              />
            </a-card>
            <p>Province no. 4</p>
          </div>
        </a-col>
        <a-col :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper">
              <img
                src="../../../public/icons/Dashboard/Icon__province_5.svg"
                alt="Hotel"
                width="50"
                height="50"
              />
            </a-card>
            <p>Province no. 5</p>
          </div>
        </a-col>
        <a-col :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper">
              <img
                src="../../../public/icons/Dashboard/Icon__province_6.svg"
                alt="Hotel"
                width="50"
                height="50"
              />
            </a-card>
            <p>Province no. 6</p>
          </div>
        </a-col>
        <a-col :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper">
              <img
                src="../../../public/icons/Dashboard/Icon__province_7.svg"
                alt="Hotel"
                width="50"
                height="50"
              />
            </a-card>
            <p>Province no. 7</p>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="main-body third-row">
      <div class="title">Event Complete Status</div>
      <a-row class="modules-row">
        <a-col
          :xs="12"
          :sm="6"
          :md="{ span: 2, offset: 2 }"
          :lg="{ span: 2, offset: 6 }"
          :xl="2"
        >
          <div class="module">
            <a-card class="module-wrapper">
              <img
                src="../../../public/icons/Dashboard/Icon_Closed_case.svg"
                alt="Hotel"
                width="50"
                height="50"
              />
            </a-card>
            <p>Closed Case</p>
          </div>
        </a-col>
        <a-col span="2" :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper">
              <img
                src="../../../public/icons/Dashboard/Icon_Compromise.svg"
                alt="Hotel"
                width="50"
                height="50"
              />
            </a-card>
            <p>Compromise</p>
          </div>
        </a-col>
        <a-col span="2" :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper">
              <img
                src="../../../public/icons/Dashboard/Icon_Incomplete.svg"
                alt="Hotel"
                width="50"
                height="50"
              />
            </a-card>
            <p>Incomplete</p>
          </div>
        </a-col>
        <a-col span="2" :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper">
              <img
                src="../../../public/icons/Dashboard/Icon_Investigation.svg"
                alt="Hotel"
                width="50"
                height="50"
              />
            </a-card>
            <p>Investigation</p>
          </div>
        </a-col>
        <a-col span="2" :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper">
              <img
                src="../../../public/icons/Dashboard/Icon_Legal_Proceeds.svg"
                alt="Hotel"
                width="50"
                height="50"
              />
            </a-card>
            <p>Legal Proceeds</p>
          </div>
        </a-col>
        <a-col span="2" :xs="12" :sm="6" :md="2" :lg="2" :xl="2">
          <div class="module">
            <a-card class="module-wrapper">
              <img
                src="../../../public/icons/Dashboard/Icon_No_legal_proceedsv.svg"
                alt="Hotel"
                width="50"
                height="50"
              />
            </a-card>
            <p>No Legal Proceeds</p>
          </div>
        </a-col>
      </a-row>
    </div>
    </div>
      </a-col>
    </a-row>
    <!-- footer -->
    <a-row>
      <a-col :span="24">
        <footer class="footer"></footer>
      </a-col>
    </a-row>
  </div>
</template>

<script>

import ARow from "ant-design-vue/es/grid/Row";
import ACol from "ant-design-vue/es/grid/Col";
export default {
  name: "Index.vue",
  components: { ACol, ARow, },
  methods:{
      navrouteLink(value){
          this.$router.push({ name: 'report', params: { event: value }})
      }
  }
};

</script>

<style scoped>
/* navigation wrapper */
/* dashboard icons */
.main-body{

    margin-top:10px;
}
.content-body{
  height:85vh;
  width:100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.second-row{
 margin-top:10px;
}
.third-row{
  margin-top:10px;

}
.main-body .title {
  text-align: center;
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 21px;
  color: #ef5350;
}
.modules-row {
  margin-bottom: 11px !important;
}
.module {
  text-align: center;
  cursor: pointer;
}
.module-wrapper {
  height: 50px;
  width: 50px;
  border-radius: 14px;
  border: 1px solid #00599a;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.module-wrapper .ant-card-body {
  padding: 9px !important;
}
.module p {
  margin-top: 4px;
}

.ant-menu-item {
  color: #00599a;
}
.footer {
  background-color: #00599a;

  height: 45px;
  width: 100%;


}
.search {
  margin-top: 10px;
}

.header {
  border-bottom: 1px solid #78c972;

}
/* menu wrapper */
.menu-wrapper {
  margin-top: 32px;
}
.module:hover img {
  transform: scale(0.95);
  transition: all 0.3s ease;
}
</style>
