var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',[_c('a-input-search',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              } ],
          } ]),expression:"[\n          'email',\n          {\n            rules: [\n              {\n                type: 'email',\n                message: 'The input is not valid E-mail!',\n              },\n              {\n                required: true,\n                message: 'Please input your E-mail!',\n              },\n            ],\n          },\n        ]"}],ref:"email",staticClass:"emailme",attrs:{"placeholder":"Drop Your Email to Receive HRV Year Book","enter-button":"Send Me Books"},on:{"search":_vm.handleSubmit}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }