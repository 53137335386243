import Vue from 'vue'
import App from './App.vue'
import Home from './components/landingPage'
import Dashboard from './components/Dashboard/Index'
import Report from './components/Report/Index'
import Prov from './components/Dashboard/Province'
import eventstatus from './components/Report/event_status'
import Geography from './components/Geography/Index'
import contactUs from './components/ContactUs.vue'
import email from './components/EmailMe/Email.vue'
import pivot from  './components/Test/pivot'
import article from './components/Datasets/Article'
import aboutus from './components/Aboutus/Aboutus.vue'
import VueRouter from 'vue-router'
import VueApexCharts from 'vue-apexcharts'
import JsonExcel from "vue-json-excel";
import VuePivottable from 'vue-pivottable'
import 'vue-pivottable/dist/vue-pivottable.css'
import axios from 'axios'
import 'ant-design-vue/dist/antd.css';
import Datasets from './components/Datasets/Dataset.vue'
import DatasetDetails from "./components/Datasets/Details"
import VueCookies from 'vue-cookies';
import VueSmoothScroll from 'vue2-smooth-scroll'
import Scrollspy from 'vue2-scrollspy';




import {
    Hooper,
    Slide
} from 'hooper';
import 'hooper/dist/hooper.css';

import {
    Button,
    Row,
    Input,
    Col,
    AutoComplete,
    Radio,
    Carousel,
    Layout,
    Menu,
    Card,
    Table,
    Icon,
    Divider,
    Select,
    Dropdown,
    Form,
    List,
    message,
    Spin,
    Modal,
    Popover,
    Tooltip,
    DatePicker,
    Tabs

} from 'ant-design-vue';

const routes = [
    {
        path: '/',
        component: Home
    },
    {
        path: '/dashboard',
        component: Dashboard
    },
   /* {
        path: '/report/:event',
        name: 'report',
        component: Report
    },*/
    {
        path: '/province/:id',
        component: Prov
    },
    {
        path: '/event_type/:id',
        name: 'event_type',
        component: Report
    },
    {
        path: '/event_status',
        component: eventstatus
    },
    {
        path: '/geography',
        component: Geography
    },
    {
        path:'/contactus',
        name:'contactUs',
        component:contactUs
    },
    {
        path:'/email',
        name:'email',
        component:email
    },
    {
        path: '/pivot',
        name: 'pivot',
        component: pivot
    },
    {
        path:'/datasets',
        name:'dataset',
        component:Datasets
    },
    {

        path:'/datasets/article/:id',
        name:'dataset_article',
        component:article
    },
    {

        path:'/dataset-details/:id',
        name:'details',
        component:DatasetDetails
    },
    {
        path:'/how-is-data-gathered',
        name: "how_is_data_gathered",
        component:aboutus
    }

]

const router = new VueRouter({
    routes,
    mode: 'history'
});

Vue.config.productionTip = false;

Vue.use(Dropdown);
Vue.use(Divider);
Vue.use(VueRouter);
Vue.use(Form);
Vue.use(Button);
Vue.use(AutoComplete);
Vue.use(Row);
Vue.use(Input);
Vue.use(Col);
Vue.use(Radio);
Vue.use(Carousel);
Vue.use(Layout);
Vue.use(Menu);
Vue.use(Card);
Vue.use(Table);
Vue.use(Carousel);
Vue.use(Icon);
Vue.use(Modal);
Vue.use(Hooper);
Vue.use(Slide);
Vue.use(axios);
Vue.use(Select);
Vue.use(JsonExcel);
Vue.use(VueApexCharts);
Vue.use(Card);
Vue.use(message);
Vue.use(VuePivottable);
Vue.use(List);
Vue.use(Spin);
Vue.use(VueCookies);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(DatePicker);
Vue.use(Tabs);
Vue.use(VueSmoothScroll);
Vue.use(Scrollspy);


Vue.prototype.$message = message;
export const EventBus = new Vue;

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');