import axios from 'axios';

export default {
    fetchDatasets() {
        return axios.get("https://hrv.insec.org.np/api/v1/dataset-main-chart");
    },
    fetchLatestData(search) {
        return (search) ? axios.get(`https://hrv.insec.org.np/api/v1/datasets-article?search=${search}`)
            : axios.get(`https://hrv.insec.org.np/api/v1/datasets-article`);
    },
    fetchArticleDatasets(id) {
        return axios.get(`https://hrv.insec.org.np/api/v1/datasets-article/${id}`)
    },
    fetchDatasetCount(data) {
        return axios.get(`https://hrv.insec.org.np/api/v1/datasets?count_value=${data}`)
    }

}