<template>
  <!-- main container -->
  <div class="contactUs">
    <a-row>
      <a-col :xl="12" :lg="12" md="12" :sm="24" :xs="24" class="google-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.7154119234265!2d85.27552481442433!3d27.695189432634407!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb187a2b524cdf%3A0x342c49c93c1772e5!2sINSEC%20Nepal!5e0!3m2!1sen!2snp!4v1613629509590!5m2!1sen!2snp"
          width="90%"
          height="400"
          frameborder="0"
          style="border: 0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </a-col>
      <!-- form -->
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">

        <h3 style="color: #00599a">Contact Us:</h3>
        <a-form :form="form" @submit="handleSubmit">
          <!-- name -->
          <a-col :xl="11" :lg="11" :md="24" :sm="24" :xs="24">
            <a-form-item label="Name">
              <a-input
                v-decorator="[
                  'Name',
                  {
                    rules: [
                      { required: true, message: 'Please input your name!' },
                    ],
                  },
                ]"
                v-model="formData.name"
              />

            </a-form-item>
          </a-col>
          <!-- email -->
          <a-col :xl="{span:11,offset:2}" :lg="{span:11,offset:2}" :md="24" :xs="24" :sm="24">
            <a-form-item label="E-mail">
              <a-input

                      v-decorator="[
          'email',
          {
            rules: [
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ],
          },
        ]"
              v-model="formData.email"

              />
            </a-form-item>
          </a-col>
          <!-- contact number -->
          <a-col :span="11" :xl="11" :lg="11" :md="24" :sm="24" :xs="24">
            <a-form-item label="Contact No:">
              <a-input

                v-decorator="[
                  'Contact No',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your contact no!',
                      },
                    ],
                  },
                ]"
                v-model="formData.contact"
              />

            </a-form-item>
          </a-col>
          <!-- Subject -->
          <a-col :xl="{span:11,offset:2}" :lg="{span:11,offset:2}" :md="24" :xs="24" :sm="24">
            <a-form-item label="Subject">
              <a-input

                v-decorator="[
                  'Subject',
                  {
                    rules: [
                      { required: true, message: 'Please input your subject!' },
                    ],
                  },
                ]"
                v-model="formData.subject"
              />

            </a-form-item>
          </a-col>
          <!-- text area -->
          <a-col  :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
            <a-form-item label="Message">
              <a-textarea
                placeholder="Your Message here. "
                style="height: 100px"
                v-model="formData.message"
              />
            </a-form-item>
          </a-col>
          <!-- submit button -->
          <a-col :xl="6" :lg="6" :md="24" :sm="24" :xs="24">
              <a-form-item>
                <a-button type="primary" html-type="submit" class="submit-btn" @click = storecontactform>
                  Submit
                </a-button>
              </a-form-item>
          </a-col>
        </a-form>
      </a-col>
    </a-row>
    <a-row>
      <hr />
      <a-col style="margin-top: 20px" :span="24">
        <a-col :xl="6" :lg="6" :md="12" :sm="12" :xs="24">
          <div style="padding: 10px">
            <a-card
              class="card-wrapper"
              title="Central Office"
              :bordered="true"
            >
              <h3>Informal Sector Service Center (INSEC)</h3>
              <p>
                Syuchatar, Kalanki, Kathmandu, Nepal<br />
                G.P.O. Box: 2726, Kathmandu, Nepal
                <br />Tel.: +977-1-5218770 <br />
                Fax: +977-1-5218251
              </p>
              <p>
                Email:
                <a href="mailto:insec@insec.org.np">insec@insec.org.np</a>
              </p>
              <p>
                Website:
                <a href="http://inseconline.org" target="_blank"
                  >www.inseconline.org</a
                >
              </p>
            </a-card>
          </div>
        </a-col>
        <a-col :xl="6" :lg="6" :md="12" :sm="12" :xs="24">
          <div style="padding: 10px">
            <a-card
              class="card-wrapper"
              title="Surkhet Office"
              :bordered="true"
            >
              <h3>Informal Sector Service Center (INSEC)</h3>
              <p>
                Birendranagar Municipality – 7, Itram Morang, Nepal
                <br />Tel.: +977-081-521489 <br />
                Fax:
              </p>
              <p>
                Email:
                <a href="mailto:urkhet@insec.org.np">urkhet@insec.org.np</a>
              </p>
              <p>
                Website:
                <a href="http://inseconline.org" target="_blank"
                  >www.inseconline.org</a
                >
              </p>
            </a-card>
          </div>
        </a-col>
        <a-col :xl="6" :lg="6" :md="12" :sm="12" :xs="24">
          <div style="padding: 10px">
            <a-card
              class="card-wrapper"
              title="Janakpur Office"
              :bordered="true"
            >
              <h3>Informal Sector Service Center (INSEC)</h3>
              <p>
                Janakupurdham Sub-Metropolitan City–8, <br />
                Pidarichowk G.P.O. Box:2726, Kathmandu, Nepal
                <br />Tel.: +977-9854027507 <br />
                Fax: +977-1-5218251
              </p>
              <p>
                Email:
                <a href="mailto:janakpur@insec.org.np">janakpur@insec.org.np</a>
              </p>
              <p>
                Website:
                <a href="http://inseconline.org" target="_blank"
                  >www.inseconline.org</a
                >
              </p>
            </a-card>
          </div>
        </a-col>
        <a-col :xl="6" :lg="6" :md="12" :sm="12" :xs="24">
          <div style="padding: 10px">
            <a-card
              class="card-wrapper"
              title="Dhangadi Office"
              :bordered="true"
            >
              <h3>Informal Sector Service Center (INSEC)</h3>
              <p>
                Uttarbehadi,Ratopul,<br>Dhangadi-4 Kailali, Nepal<br />
                G.P.O. Box: 2726, Kathmandu, Nepal
                <br />Tel.:+977-91-522576, 091-521446 <br />
                Fax:+977-91-523244
              </p>
              <p>
                Email:
                <a href="mailto:dhangadi@insec.org.np">dhangadi@insec.org.np</a>
              </p>
              <p>
                Website:
                <a href="http://inseconline.org" target="_blank"
                  >www.inseconline.org</a
                >
              </p>
            </a-card>
          </div>
        </a-col>
      </a-col>

      <a-col style="margin-top: 20px" :span="24">
        <a-col :xl="6" :lg="6" :md="12" :sm="12" :xs="24">
          <div style="padding: 10px">
            <a-card
              class="card-wrapper"
              title="Nepalgunj Office"
              :bordered="true"
            >
              <h3>Informal Sector Service Center (INSEC)</h3>
              <p>
                National Trading Road, Nepalgunj Banke, Nepal<br />
                G.P.O. Box: 2726, Kathmandu, Nepal
                <br />Tel.: +977-81-524504, +977-81-522068<br />
                Fax: +977-81-526949
              </p>
              <p>
                Email:
                <a href="mailto:nepalgunj@insec.org.np">
                  nepalgunj@insec.org.np</a
                >
              </p>
              <p>
                Website:
                <a href="http://inseconline.org" target="_blank"
                  >www.inseconline.org</a
                >
              </p>
            </a-card>
          </div>
        </a-col>
        <a-col :xl="6" :lg="6" :md="12" :sm="12" :xs="24">
          <div style="padding: 10px">
            <a-card
              class="card-wrapper"
              title="Pokhara Office"
              :bordered="true"
            >
              <h3>Informal Sector Service Center (INSEC)</h3>
              <p>
                AnnapurnaTole, Pokhara-7, House NO. 106-2 Kaski, Nepal
                <br />Tel.: +977-61-465512, 977-61-463492<br />
                Fax: +977-61-463492
              </p>
              <p>
                Email:
                <a href="mailto:pokhara@insec.org.np">pokhara@insec.org.np</a>
              </p>
              <p>
                Website:
                <a href="http://inseconline.org" target="_blank"
                  >www.inseconline.org</a
                >
              </p>
            </a-card>
          </div>
        </a-col>
        <a-col :xl="6" :lg="6" :md="12" :sm="12" :xs="24">
          <div style="padding: 10px">
            <a-card
              class="card-wrapper"
              title="Kathmandu Office"
              :bordered="true"
            >
              <h3>Informal Sector Service Center (INSEC)</h3>
              <p>
                Nagarjun Municipality-10, Syuchatar, Kalanki G.P.O. Box: 2726,
                Kathmandu, Nepal
                <br />Tel.:+977-1-5 218770 <br />
                Fax:+977-1-5 218251
              </p>
              <p>
                Email:
                <a href="mailto:kathmandu@insec.org.np"
                  >kathmandu@insec.org.np</a
                >
              </p>
              <p>
                Website:
                <a href="http://inseconline.org" target="_blank"
                  >www.inseconline.org</a
                >
              </p>
            </a-card>
          </div>
        </a-col>
        <a-col :xl="6" :lg="6" :md="12" :sm="12" :xs="24">
          <div style="padding: 10px">
            <a-card
              class="card-wrapper"
              title="Biratnagar Office"
              :bordered="true"
            >
              <h3>Informal Sector Service Center (INSEC)</h3>
              <p>
                Janpathtole, Biratnagar-15 Morang, Nepal
                <br />Tel.: +977-21-471025, 977-21-471127<br />
                Fax: +977-21-471521
              </p>
              <p>
                Email:
                <a href="mailto:biratnagar@insec.org.np"
                  >biratnagar@insec.org.np</a
                >
              </p>
              <p>
                Website:
                <a href="http://inseconline.org" target="_blank"
                  >www.inseconline.org</a
                >
              </p>
            </a-card>
          </div>
        </a-col>
      </a-col>
    </a-row>
  </div>
</template>

<script>

import ContactService from "../services/contactForm";


export default {
  data() {
    return {
      formLayout: "horizontal",

      form: this.$form.createForm(this, { name: "coordinated" }),
      formData: {
        name: "",
        email: "",
        contact: "",
        message: "",
        subject: "",
      },
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          ContactService.submitContactform(this.formData).then((res) => {
            if (res.status === 200) {
              this.$message.success("Thank you " + this.formData.name + "! Contact Form Successfully Submitted!");
              this.formData.name = "";
              this.formData.email = "";
              this.formData.contact = "";
              this.formData.message = "";
              this.formData.subject = "";
            } else {
              this.$message.error(
                "There was problem while submitting your data. Please try again"
              );
            }
          });
        }
      });
    },

  },
};
</script>

<style >
/* main-container */
.contactUs {
  padding: 30px;
}
.submit-btn {
  width: 100% !important;
}
.ant-form-item {
  margin-bottom: 10px !important;
}
.card-wrapper {
  height: 400px !important;
  overflow: auto;

}
@media only screen and (max-width:990px){
  .google-map iframe{
    width:100% !important;
  }
}
</style>
