<template>
  <div class="slider-wrapper">
    <div class="slider-title">
      <!-- dropdown -->
      <a-dropdown v-model="visible">
        <a class="ant-dropdown-link" @click="(e) => e.preventDefault()" style="color:#232626 !important;">
          {{ selectedYear }} <a-icon type="down" />
        </a>
        <a-menu slot="overlay" @click="handleMenuClick">
          <a-menu-item key="">
            All
          </a-menu-item>
          <a-menu-item key="2013">
            2013
          </a-menu-item>
          <a-menu-item key="2014">
            2014
          </a-menu-item>
          <a-menu-item key="2015">
            2015
          </a-menu-item>
          <a-menu-item key="2016">
            2016
          </a-menu-item>
          <a-menu-item key="2017">
            2017
          </a-menu-item>
          <a-menu-item key="2018">
            2018
          </a-menu-item>
          <a-menu-item key="2019">
            2019
          </a-menu-item>
          <a-menu-item key="2020">
            2020
          </a-menu-item>
          <a-menu-item key="2021">
            2021
          </a-menu-item>
          <a-menu-item key="2022">
            2022
          </a-menu-item>
          <a-menu-item key="2023">
            2023
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <h3>HRV eBooks by Year</h3>
    </div>

    <hooper class="slider" :vertical="true" :itemsToShow="toShow" :settings="hooperSettings" :infiniteScroll="toScroll">
      <slide class="slider-item" v-if="year == '2023' || year == ''">
        <img src="../../../public/books/2023_NP/zxc1.jpg" alt="" height="150" width="100" @click="showModal2023N" />
      </slide>
      <slide class="slider-item" v-if="year == '2023' || year == ''">
        <img src="../../../public/books/2023_EN/zxc1.jpg" alt="" height="150" width="100" @click="showModal2023E" />
      </slide>
      <slide class="slider-item" v-if="year == '2022' || year == ''">
        <img src="../../../public/books/2022_NP/zxc1.jpeg" alt="" height="150" width="100" @click="showModal2022N" />
      </slide>
      <slide class="slider-item" v-if="year == '2022' || year == ''">
        <img src="../../../public/books/2022_EN/zxc1.jpeg" alt="" height="150" width="100" @click="showModal2022E" />
      </slide>
      <slide class="slider-item" v-if="year == '2021' || year == ''">
        <img src="../../../public/books/2021_EN/zxc1.jpeg" alt="" height="150" width="100" @click="showModal2021E" />
      </slide>
      <slide class="slider-item" v-if="year == '2021' || year == ''">
        <img src="../../../public/books/2021_NP/zxc1.jpeg" alt="" height="150" width="100" @click="showModal2021N" />
      </slide>
      <slide class="slider-item" v-if="year == '2020' || year == ''">
        <img src="../../../public/books/2020_NP/zxc1.jpeg" alt="" height="150" width="100" @click="showModal2020N" />
      </slide>
      <slide class="slider-item" v-if="year == '2020' || year == ''">
        <img src="../../../public/books/2020_EN/zxc1.jpeg" alt="" height="150" width="100" @click="showModal2020E" />
      </slide>
      <slide class="slider-item" v-if="year == '2019' || year == ''">
        <img src="../../../public/icons/2019-n.jpg" alt="" height="150" width="100" @click="showModal2019N" />
      </slide>
      <slide class="slider-item" v-if="year == '2019' || year == ''">
        <img src="../../../public/books/2019_EN/zxc1.jpeg" alt="" height="150" width="100" @click="showModal2019E" />
      </slide>
      <slide class="slider-item" v-if="year == '2018' || year == ''">
        <img src="../../../public/books/2018/zxc1.jpeg" alt="" height="150" width="100" @click="showModal2018" />
      </slide>
      <slide class="slider-item" v-if="year == '2017' || year == ''">
        <img src="../../../public/books/2017/zxc1.jpeg" alt="" height="150" width="100" @click="showModal2017" />
      </slide>
      <slide class="slider-item" v-if="year == '2016' || year == ''">
        <img src="../../../public/books/2016/zxc1.jpeg" alt="" height="150" width="100" @click="showModal2016" />
      </slide>
      <slide class="slider-item" v-if="year == '2015' || year == ''">
        <img src="../../../public/books/2015/zxc1.jpeg" alt="" height="150" width="100" @click="showModal2015" />
      </slide>
      <slide class="slider-item" v-if="year == '2014' || year == ''">
        <img src="../../../public/books/2014/zxc1.jpeg" alt="" height="150" width="100" @click="showModal2014" />
      </slide>
      <slide class="slider-item" v-if="year == '2013' || year == ''">
        <img src="../../../public/books/2013/zxc1.jpeg" alt="" height="150" width="100" @click="showModal2013" />
      </slide>

      <!-- <hooper-navigation slot="hooper-addons"></hooper-navigation> -->
      <hooper-pagination slot="hooper-addons"></hooper-pagination>
    </hooper>
    <a-modal v-model="visible2013" :dialog-style="{ top: '20px' }" @ok="handleOk2013" width="1200px">
      <Flipbook2013 />
      <div style="width: 100%; height: 50px"></div>
    </a-modal>
    <a-modal v-model="visible2014" :dialog-style="{ top: '20px' }" @ok="handleOk2014" width="1200px">
      <Flipbook2014 />
      <div style="width: 100%; height: 50px"></div>
    </a-modal>
    <a-modal v-model="visible2015" :dialog-style="{ top: '20px' }" @ok="handleOk2015" width="1200px">
      <Flipbook2015 />
      <div style="width: 100%; height: 50px"></div>
    </a-modal>
    <a-modal v-model="visible2016" :dialog-style="{ top: '20px' }" @ok="handleOk2016" width="1200px">
      <Flipbook2016 />
      <div style="width: 100%; height: 50px"></div>
    </a-modal>
    <a-modal v-model="visible2017" :dialog-style="{ top: '20px' }" @ok="handleOk2017" width="1200px">
      <Flipbook2017 />
      <div style="width: 100%; height: 50px"></div>
    </a-modal>
    <a-modal v-model="visible2018" :dialog-style="{ top: '20px' }" @ok="handleOk2018" width="1200px">
      <Flipbook2018 />
      <div style="width: 100%; height: 50px"></div>
    </a-modal>
    <a-modal v-model="visible2019E" :dialog-style="{ top: '20px' }" @ok="handleOk2019E" width="1200px">
      <Flipbook2019E />
      <div style="width: 100%; height: 50px"></div>
    </a-modal>
    <a-modal v-model="visible2019N" :dialog-style="{ top: '20px' }" @ok="handleOk2019N" width="1200px">
      <Flipbook2019N />
      <div style="width: 100%; height: 50px"></div>
    </a-modal>
    <a-modal v-model="visible2020E" :dialog-style="{ top: '20px' }" @ok="handleOk2020E" width="1200px">
      <Flipbook2020E />
      <div style="width: 100%; height: 50px"></div>
    </a-modal>
    <a-modal v-model="visible2020N" :dialog-style="{ top: '20px' }" @ok="handleOk2020N" width="1200px">
      <Flipbook2020N />
      <div style="width: 100%; height: 50px"></div>
    </a-modal>
    <a-modal v-model="visible2021N" :dialog-style="{ top: '20px' }" @ok="handleOk2021N" width="1200px">
      <Flipbook2021N />
      <div style="width: 100%; height: 50px"></div>
    </a-modal>
    <a-modal v-model="visible2021E" :dialog-style="{ top: '20px' }" @ok="handleOk2021E" width="1200px">
      <Flipbook2021E />
      <div style="width: 100%; height: 50px"></div>
    </a-modal>
    <a-modal v-model="visible2022E" :dialog-style="{ top: '20px' }" @ok="handleOk2022E" width="1200px">
      <Flipbook2022E />
      <div style="width: 100%; height: 50px"></div>
    </a-modal>
    <a-modal v-model="visible2022N" :dialog-style="{ top: '20px' }" @ok="handleOk2022N" width="1200px">
      <Flipbook2022N />
      <div style="width: 100%; height: 50px"></div>
    </a-modal>
    <a-modal v-model="visible2023E" :dialog-style="{ top: '20px' }" @ok="handleOk2023E" width="1200px">
      <Flipbook2023E />
      <div style="width: 100%; height: 50px"></div>
    </a-modal>
    <a-modal v-model="visible2023N" :dialog-style="{ top: '20px' }" @ok="handleOk2023N" width="1200px">
      <Flipbook2023N />
      <div style="width: 100%; height: 50px"></div>
    </a-modal>
  </div>
</template>
<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";
import Flipbook2013 from "../Flipbook/2013";
import Flipbook2014 from "../Flipbook/2014";
import Flipbook2015 from "../Flipbook/2015";
import Flipbook2016 from "../Flipbook/2016";
import Flipbook2017 from "../Flipbook/2017";
import Flipbook2018 from "../Flipbook/2018";
import Flipbook2019E from "../Flipbook/2019E";
import Flipbook2019N from "../Flipbook/2019N";
import Flipbook2020E from "../Flipbook/2020E";
import Flipbook2020N from "../Flipbook/2020N";
import Flipbook2021E from "../Flipbook/2021E";
import Flipbook2021N from "../Flipbook/2021N";
import Flipbook2022E from "../Flipbook/2022E";
import Flipbook2022N from "../Flipbook/2022N";
import Flipbook2023E from "../Flipbook/2023E";
import Flipbook2023N from "../Flipbook/2023N";

export default {
  name: "Slider",
  components: {
    Hooper,
    Slide,
    HooperPagination,
    Flipbook2013,
    Flipbook2014,
    Flipbook2015,
    Flipbook2016,
    Flipbook2017,
    Flipbook2018,
    Flipbook2019E,
    Flipbook2019N,
    Flipbook2020E,
    Flipbook2020N,
    Flipbook2021E,
    Flipbook2021N,
    Flipbook2022E,
    Flipbook2022N,
    Flipbook2023E,
    Flipbook2023N,
  },
  data() {
    return {
      toShow: 4.5,
      toScroll: true,
      show: true,
      visible_books: false,
      year: "",
      selectedYear: "Select Year",
      visible: false,
      visible2013: false,
      visible2014: false,
      visible2015: false,
      visible2016: false,
      visible2017: false,
      visible2018: false,
      visible2019E: false,
      visible2019N: false,
      visible2020E: false,
      visible2020N: false,
      visible2021E: false,
      visible2021N: false,
      visible2022E: false,
      visible2022N: false,
      visible2023E: false,
      visible2023N: false,
      hooperSettings: {},
    };
  },
  methods: {
    handleMenuClick(e) {
      this.year = e.key;
      this.selectedYear = this.year;
      if (e.key === "") {
        this.selectedYear = "Select Year";
      }

      if (this.year >= 2019) {
        this.toShow = 2;
        this.toScroll = false;
      }
    },
    handleOk2013() {
      this.visible2013 = false;
    },
    showModal2013() {
      this.visible2013 = true;
    },
    handleOk2014() {
      this.visible2014 = false;
    },
    showModal2014() {
      this.visible2014 = true;
    },
    handleOk2015() {
      this.visible2015 = false;
    },
    showModal2015() {
      this.visible2015 = true;
    },
    handleOk2016() {
      this.visible2016 = false;
    },
    showModal2016() {
      this.visible2016 = true;
    },
    handleOk2017() {
      this.visible2017 = false;
    },
    showModal2017() {
      this.visible2017 = true;
    },
    handleOk2018() {
      this.visible2018 = false;
    },
    showModal2018() {
      this.visible2018 = true;
    },
    handleOk2019E() {
      this.visible2019E = false;
    },
    showModal2019E() {
      this.visible2019E = true;
    },
    handleOk2019N() {
      this.visible2019N = false;
    },
    showModal2019N() {
      this.visible2019N = true;
    },
    handleOk2020E() {
      this.visible2020E = false;
    },
    showModal2020E() {
      this.visible2020E = true;
    },
    handleOk2020N() {
      this.visible2020N = false;
    },
    showModal2020N() {
      this.visible2020N = true;
    },
    handleOk2021N() {
      this.visible2021N = false;
    },
    showModal2021N() {
      this.visible2021N = true;
    },
    handleOk2021E() {
      this.visible2021E = false;
    },
    showModal2021E() {
      this.visible2021E = true;
    },
    handleOk2022E() {
      this.visible2022E = false;
    },
    showModal2022E() {
      this.visible2022E = true;
    },
    handleOk2022N() {
      this.visible2022N = false;
    },
    showModal2022N() {
      this.visible2022N = true;
    },
    handleOk2023E() {
      this.visible2023E = true;
    },
    showModal2023E() {
      this.visible2023E = true;
    },
    handleOk2023N() {
      this.visible2023N = true;
    },
    showModal2023N() {
      this.visible2023N = true;
    }
  },
};
</script>

<style>
/* slider wrapper */
.slider-wrapper {
  background: #ebfbfe;
  border-radius: 10px;
  padding: 20px 0;
}

.slider {
  height: 800px;
  /* height:calc(100vh + 100px); */
  background-size: cover;
  background-repeat: no-repeat;
}

.slider-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -179px;
}

.slider-item img {
  cursor: pointer;
}

.hooper-list:focus {
  outline: none !important;
}

.hooper-prev:focus {
  outline: none !important;
}

.hooper-next:focus {
  outline: none !important;
}

.icon-arrowUp {
  fill: #fff;
  position: relative;
  right: 75px;
  width: 50px;
  height: 50px;
}

.icon-arrowDown {
  fill: #fff;
  position: relative;
  right: 75px;
  width: 50px;
  height: 50px;
}

.ant-modal-footer {
  display: none;
}
</style>
