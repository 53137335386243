<template>
  <a-row class="dataset-row">
    <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
      <div class="bargraph">
        <apexchart
          height="500"
          type="bar"
          :options="chartOptions"
          :series="series"
        ></apexchart>
          <!-- <div class="datasetLoader"> -->
      <!-- <a-spin v-if="loading1"  /> -->
      <div class="spinBefore datasetLoader" v-if="loading1"></div>
      <!-- </div>  -->
        <!-- <a-spin v-if="loading1" /> -->
      </div>
    </a-col>
    <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
      <a-row>
        <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="latest-data-title">
            <h2>Latest Datasets</h2>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="latest-data-title data-input">
            <a-input-search
              placeholder="Search latest Datasets"
              @search="onSearch"
              enter-button
            />
            <!--@search="onSearch"-->
          </div>
        </a-col>
      </a-row>

      <div class="latest-data">
        <div class="demo-infinite-container" :infinite-scroll-distance="10">
          <!--:infinite-scroll-disabled="busy"-->
          <a-list item-layout="horizontal" :data-source="articleData">
            <a-list-item
              slot="renderItem"
              slot-scope="item"
              @click="send_data(item.title)"
            >
              <a-list-item-meta :description="'Released on ' + item.posted_at">
                <!--  <a slot="title" href="#">{{
                    item.title
                  }}</a>-->
                <router-link
                  :to="{ name: 'dataset_article', params: { id: item.id } }"
                  slot="title"
                >
                  {{ item.title }}
                </router-link>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import DatasetsServices from "../../services/datasets";
import { EventBus } from "@/main";

export default {
  data() {
    return {
      loading1: true,
      chartOptions: {
        chart: {
          height: 500,
          type: "bar",
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            // horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          offsetY: 2,
          style: {
            fontSize: "7px",
            colors: ["#fff"],
            fontweight: "400",
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [
            "Province 1",
            "Province 2",
            "Bagmati Pradesh",
            "Gandaki Pradesh",
            "Province 5",
            "Karnali Pradesh",
            "Sudurpashchim Pradesh",
          ],
          // categories: [ 'Province 1', 'Province 2', 'Province 3', 'Province 4', 'Province 5', 'Province 6', 'Province 7'],
        },
      },
      series: [],
      articleData: [],
    };
  },
  components: {
    apexchart: VueApexCharts,
  },
  created() {
    this.getDatasets();
    this.getLatestData();
  },
  methods: {
    onSearch(value) {
      DatasetsServices.fetchLatestData(value).then((res) => {
        this.articleData = res.data.data;
      });
    },
    getDatasets() {
      DatasetsServices.fetchDatasets().then((res) => {
        // let series_data = [];
        this.series = res.data.data;
        this.loading1 = false;
        // console.log(this.loading1);
      });
    },
    getLatestData() {
      DatasetsServices.fetchLatestData().then((res) => {
        this.articleData = res.data.data;
      });
    },
    send_data(data) {
      EventBus.$emit("i-got-clicked", data);
    },
  },
};
</script>
<style scoped>
.latest-data {
  width: 100%;
  /* padding-right: 15px;
  padding-top: 32px;
  padding-left: 15px; */
  margin-right: auto;
  margin-left: auto;
}

.latest-data h2 {
  margin-left: 0px;
}

.bargraph {
  width: 100%;
  padding-right: 15px;
  padding-top: 35px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 60vh;
}

.latest-data-title {
  padding-right: 15px;
  padding-top: 32px;
  padding-left: 15px;
}
.bargraph > .ant-spin-spinning {
  position: absolute !important;
  left: 19rem !important;
  top: 10rem !important;
}
.dataset-row{
height: calc(100vh - 137px);
}
@media only screen and (max-width: 575px) {
  .latest-data-title h2 {
    text-align: center;
  }
  .data-input {
    padding-top: 0px;
  }
}
@media only screen and (max-width: 870px){
  .dataset-row{
    height:calc(100% - 173px);
  }
  .bargraph > .ant-spin-spinning {
  position: absolute !important;
  left: 9rem !important;
  top: 10rem !important;
}
}

.spinBefore{
	position: relative;
	width: 12px;
	height: 12px;
	border-radius: 12px;
	background-color: #feab56;
	-webkit-transform-origin:  50% 50%;
			transform-origin:  50% 50% ;
	-webkit-animation: spinBefore 1s ease-in-out infinite;
			animation: spinBefore 1s ease-in-out infinite;
}

.spinBefore:before{
	content: "";
	position: absolute;
	background-color:#c3535f;
	top: 0px;
	left: -25px;
	height: 12px;
	width: 12px;
	border-radius: 12px;
}

.spinBefore:after{
	content: "";
	position: absolute;
	background-color:#78c972;
	top: 0px;
	left: 25px;
	height: 12px;
	width: 12px;
	border-radius: 12px;
}


@-webkit-keyframes spinBefore{
    0%{-webkit-transform:rotate(0deg);}
    50%{-webkit-transform:rotate(180deg);}
    100%{-webkit-transform:rotate(180deg);}
}

@keyframes spinBefore{
    0%{transform:rotate(0deg);}
    50%{transform:rotate(180deg);}
    100%{transform:rotate(180deg);}
}
.datasetLoader{
      position: absolute;
    top: 50%;
    left: 50%;
}
</style>
