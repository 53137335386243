<template>
  <div>
    <div style="margin-top: 25px">
      <a-row style="padding: 20px 40px">
        <div style="text-align: center;">
          <h2 style="color: #00599a; font-weight: 500">
            {{ report_head }} Dataset
          </h2>
          <p>This data sheet contains the {{ report_head }} Data Report</p>

        </div>
        <div class="button-wrapper">
          <a-row>
            <a-col class="btn-child" :xl="3" :lg="{span:4,offset:2}" :md="24" :sm="24" :xs="24">
                 <a-select
            @change="changeeventapidata"
            v-model="group_by"
          >
            <a-select-option value="vdc"> Local Level </a-select-option>
            <a-select-option value="district"> District </a-select-option>
            <a-select-option value="province_id"> Province </a-select-option>
          </a-select>
            </a-col>
             <a-col class="btn-child" :xl="3" :lg="{span:4,offset:1}" :md="24" :sm="24" :xs="24" >
                   <a-select
            v-model="countOf"
            @change="changecountofData(countOf)"
          >
            <a-select-option value="victim"> Victim </a-select-option>
            <a-select-option value="perpetrator">
              Perpetrator
            </a-select-option>
          </a-select>
            </a-col>
             <a-col class="btn-child" :xl="4" :lg="{span:4,offset:1}" :md="24" :sm="24" :xs="24">
                  <a-input-search
            placeholder="Search..."
            class="search-btn"
            enter-button
            @search="onSearch"
          />
            </a-col>
             <a-col class="btn-child" :xl="4" :lg="{span:4,offset:1}" :md="24" :sm="24" :xs="24">
                 <a-select
            v-model="graph_change"
            @change="changeGraphData(graph_change)"
          >
            <a-select-option value="male_count">
              Male
            </a-select-option>
            <a-select-option value="female_count">
              Female
            </a-select-option>
            <a-select-option value="others_count">
              Others
            </a-select-option>
            <a-select-option value="total_count">
              Total Count
            </a-select-option>
          </a-select>
         
            </a-col>
             <a-col class="btn-child" :xl="3" :lg="{span:4,offset:1}" :md="24" :sm="24" :xs="24">
                     <download-excel
            class="ant-btn"
            :data="data"
            :fields="json_fields"
            worksheet="My Worksheet"
            name="filename.xls"
          >
            <a-icon type="download"></a-icon>
          </download-excel>
            </a-col>
          </a-row>


          
        </div>
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <div class="d-table">
            <a-table :columns="columns" :data-source="data">
              <span slot="sn" slot-scope="text, record">
                {{ record.key }}
              </span>
            </a-table>
          </div>
        </a-col>
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <apexchart
            type="line"
            style="margin-top: 50px"
            height="500"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import ARow from "ant-design-vue/es/grid/Row";
import event from "/src/services/event";
import ACol from "ant-design-vue/es/grid/Col";
import VueApexCharts from "vue-apexcharts";
import JsonExcel from "vue-json-excel";

const columns = [
  {
    title: "S.No.",
    dataIndex: "sn",
    key: "sn",
    scopedSlots: { customRender: "sn" },
  },
  {
    title: "District",
    dataIndex: "district",
    key: "district",
  },
  {
    title: "Report",
    dataIndex: "report_count",
    key: "report_count",
  },
  {
    title: "No.of Male",
    dataIndex: "male_count",
    key: "male_count",
  },
  {
    title: "No.of Female",
    dataIndex: "female_count",
    key: "female_count",
  },
  {
    title: "Others",
    dataIndex: "others_count",
    key: "others_count",
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
  },
];
const data = [];
export default {
  created() {
    this.fetcheventapidata();
  },
  components: {
    ACol,
    ARow,
    downloadExcel: JsonExcel,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      data,
      columns,
      report_head: "Abduction",
      event_data: "",
      event_type: "abduction",
      group_by: "district",
      countOf: "victim",
      filter: "",
      check: 1,
      key_columns: "",
      json_fields: "",
      table_data: 1,
      map_data: 0,
      graph_data: 0,
      x_axis_data: [],
      y_axis_data: [],
      graph_change: "",
      calendar_year: [
        "2020",
        "2019",
        "2018",
        "2017",
        "2016",
        "2015",
        "2014",
        "2013",
        "2012",
        "2011",
        "2010",
        "2009",
        "2008",
        "2007",
        "2006",
        "2005",
        "2004",
        "2003",
        "2002",
        "2001",
        "2000",
      ],
      cal_data: "",
      json_fields_district: {
        "S.No": "key",
        District: "district",
        Report_count: "report_count",
        "No.of Male": "male_count",
        "No.of Female": "male_count",
        Others: "others_count",
        Total: "total",
      },
      json_fields_vdc: {
        "S.No": "key",
        VDC: "vdc",
        Report_count: "report_count",
        "No.of Male": "male_count",
        "No.of Female": "male_count",
        Others: "others_count",
        Total: "total",
      },
      json_fields_province: {
        "S.No": "key",
        Province: "province_id",
        Report_count: "report_count",
        "No.of Male": "male_count",
        "No.of Female": "male_count",
        Others: "others_count",
        Total: "total",
      },
      json_data: [
        {
          name: "Tony Peña",
          city: "New York",
          country: "United States",
          birthdate: "1978-03-15",
          phone: {
            mobile: "1-541-754-3010",
            landline: "(541) 754-3010",
          },
        },
        {
          name: "Thessaloniki",
          city: "Athens",
          country: "Greece",
          birthdate: "1987-11-23",
          phone: {
            mobile: "+1 855 275 5071",
            landline: "(2741) 2621-244",
          },
        },
      ],

      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      series: [],
      chartOptions: {
        chart: {
          height: 500,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        legend: {
          position: "top",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          type: "category",
        },
      },
    };
  },
  methods: {
    async fetcheventapidata() {
      this.json_fields = this.json_fields_district;
      this.event_type = this.$route.params.id;
      this.report_head = this.event_type;

      await event
        .fetcheventdata(
          this.event_type,
          this.group_by,
          this.countOf,
          this.cal_data
        )
        .then((res) => {
          this.event_data = res.data.data;
          this.data = res.data.data;
          this.keydata();
          //   console.log("-");
        });
      this.changeGraphData();
    },
    async changeeventapidata() {
      switch (this.group_by) {
        case "vdc":
          this.columns[1].dataIndex = "vdc";
          this.columns[1].key = "vdc";
          this.columns[1].title = "Local Level";
          this.json_fields = this.json_fields_vdc;
          break;
        case "district":
          this.columns[1].dataIndex = "district";
          this.columns[1].key = "district";
          this.columns[1].title = "District";
          this.json_fields = this.json_fields_district;
          break;
        case "province_id":
          this.columns[1].dataIndex = "province_id";
          this.columns[1].key = "province_id";
          this.columns[1].title = "Province";
          this.json_fields = this.json_fields_province;
          break;
      }

      await event
        .fetcheventdata(
          this.event_type,
          this.group_by,
          this.countOf,
          this.cal_data
        )
        .then((res) => {
          this.data = "";
          this.event_data = res.data.data;
          this.data = res.data.data;
          this.keydata();
          this.key_columns = Object.keys(this.data[0]);
        });

      this.changeGraphData();
    },

    async changeDataYear(value) {
      await event
        .fetcheventdata(this.event_type, this.group_by, this.countOf, value)
        .then((res) => {
          this.data = res.data.data;
          this.keydata();
        });
      this.changeGraphData();
    },

    async changecountofData() {
      await event
        .fetcheventdata(
          this.event_type,
          this.group_by,
          this.countOf,
          this.cal_data
        )
        .then((res) => {
          this.data = res.data.data;
          this.keydata();
        });
      this.changeGraphData(this.countOf);
    },
    changesearchdata() {},
    onSearch(value) {
      let test = this.data.filter((data) => {
        const male_count = data.male_count.toString();
        const female_count = data.female_count.toString();
        const report = data.report_count.toString();
        const other = data.others_count.toString();
        //const total = data.total.toString();

        let vdc = "";
        if (data.vdc !== undefined) {
          vdc = data.vdc.toString().toLowerCase();
        }
        let district = "";
        if (data.district !== undefined) {
          district = data.district.toLowerCase();
        }
        let province = "";
        if (data.province_id !== undefined) {
          province = data.province_id.toString();
        }
        const filter = value.toString().toLowerCase();

        return male_count.includes(filter) ||
          female_count.includes(filter) ||
          report.includes(filter) ||
          other.includes(filter) ||
          district != ""
          ? district.includes(filter)
          : "" || vdc != ""
          ? vdc.includes(filter)
          : "" || province != ""
          ? province.includes(filter)
          : "";
      });

      this.data = test;
      this.keydata();
    },
    keydata() {
      let i = 0;
      this.data.forEach(function(dat) {
        dat["key"] = i + 1;
        i++;
      });
    },
    /*  rename(value) {
                  let objectOrder = '';
                  if(value != ''){
                      switch (value){
                          case  'vdc':
                              if("District" in this.json_fields){
                                   objectOrder = {
                                      'VDC': null,
                                  }
                                  this.json_fields.VDC = this.json_fields.District;
                                  this.json_fields.VDC = 'vdc';
                                  delete this.json_fields.District;
                                  Object.assign(objectOrder, this.json_fields)
                              }
                              else if("Province" in this.json_fields){
                                  this.json_fields.VDC = this.json_fields.Province;
                                  delete this.json_fields.Province;
                                  Object.assign(objectOrder, this.json_fields)
                              }
                              break;
                          case  'district':
                              if("VDC" in this.json_fields){
                                  this.json_fields.District = this.json_fields.VDC;
                                  delete this.json_fields.VDC;
                                  Object.assign(objectOrder, this.json_fields)
                              }
                              else if("Province" in this.json_fields){
                                  this.json_fields.District = this.json_fields.Province;
                                  delete this.json_fields.Province;
                                  Object.assign(objectOrder, this.json_fields)
                              }
                              break;
                          case  'province_id':
                              if("VDC" in this.json_fields){
                                  this.json_fields.Province = this.json_fields.VDC;
                                  delete this.json_fields.VDC;
                                  Object.assign(objectOrder, this.json_fields)
                              }
                              else if("District" in this.json_fields){
                                  this.json_fields.Province = this.json_fields.District;
                                  delete this.json_fields.District;
                                  Object.assign(objectOrder, this.json_fields)

                              }
                              break;
                      }
                  }
                     /!* if(value == 'vdc'){
                          this.json_fields.vdc = this.json_fields.District;
                          delete this.json_fields.District;
                      }
                      else if(value == 'district'){
                          this.json_fields.vdc = this.json_fields.District;
                          delete this.json_fields.District;
                      }
                      else if(value == 'province_id'){

                      } this.json_fields.vdc = this.json_fields.District;
                      delete this.json_fields.District;
                  }*!/

              }*/
    changeoption(value) {
      switch (value) {
        case "table":
          this.table_data = 1;
          this.map_data = 0;
          this.graph_data = 0;
          break;
        case "map":
          this.map_data = 1;
          this.table_data = 0;
          this.graph_data = 0;
          break;
        case "graph":
          this.graph_data = 1;
          this.table_data = 0;
          this.map_data = 0;
          break;
      }
    },
    async changebtnEventData(value, title) {
      await event
        .fetcheventdata(value, this.group_by, this.countOf, this.cal_data)
        .then((res) => {
          this.report_head = title;

          this.data = res.data.data;
          this.keydata();
        });
      this.changeGraphData();
    },
    changeGraphData(value = "") {
      let title = this.report_head + " " + "Graph";
      this.chartOptions = {
        title: {
          text: title,
        },
      };
      // this.chartOptions.xaxis.categories = [];
      // console.log('test-----');
      // console.log(this.chartOptions.xaxis.categories);
      // this.series = [];
      // let that = this.chartOptions.xaxis.categories;
      // this.series[0].data.push({});
      let series_data = [];
      this.data.forEach(function(dat) {
        series_data.push({
          x: dat.label,
          y: dat.report_count,
        });
      });

      // //
      // // this.chartOptions.xaxis.categories = that;
      // // console.log(this.chartOptions.xaxis.categories);
      // console.log(series_data);
      // console.log(this.series);
      //   console.log(series_data);
      // console.log(this.series[0].data);

      this.series = [
        {
          name: "Reports",
          data: series_data,
        },
      ];
      if (value == "male_count") {
        this.updateSeries();
      } else if (value == "female_count") {
        this.updateFemaleSeries();
      } else if (value == "others_count") {
        this.updateOthersSeries();
      } else if (value == "total_count") {
        this.updateTotalSeries();
      } else if (value == "perpetrator") {
        this.updatePerperatorSeries();
      }
    },
    updateSeries() {
      let series_data = [];
      this.data.forEach(function(dat) {
        series_data.push({
          x: dat.label,
          y: dat.male_count,
        });
      });
      this.series.push({
        name: "Male Count",
        data: series_data,
      });
    },
    updateFemaleSeries() {
      let female_data = [];
      this.data.forEach(function(dat) {
        female_data.push({
          x: dat.label,
          y: dat.female_count,
        });
      });
      this.series.push({
        name: "Female_count",
        data: female_data,
      });
    },
    updateOthersSeries() {
      let others_data = [];
      this.data.forEach(function(dat) {
        others_data.push({
          x: dat.label,
          y: dat.others_count,
        });
      });

      this.series.push({
        name: "Others Count",
        data: others_data,
      });
    },
    updateTotalSeries() {
      let total_series = [];
      this.data.forEach(function(dat) {
        total_series.push({
          x: dat.label,
          y: dat.total,
        });
      });
      this.series.push({
        name: "Total Count",
        data: total_series,
      });
    },
    updatePerperatorSeries() {
      let perperator_data = [];
      this.data.forEach(function(dat) {
        perperator_data.push({
          x: dat.label,
          y: dat.total,
        });
      });
      this.series.push({
        name: "Perperator Count",
        data: perperator_data,
      });
    },
    /*updateVicitmSeries(){
        let victim_data =[];
          this.data.forEach(function (dat) {
              victim_data.push({
                  x: dat.label,
                  y: dat.total
              });
          })
          this.series.push({
              name: 'Victim Count',
              data:perperator_data
          })
      }*/

      /*changemultipleData(value){
          let item = '';
          let data = this.series;
          if(value.includes("female_count")){
               this.updateFemaleSeries();
          }


          (value.includes("female_count")) ? this.updateFemaleSeries() :     this.series.splice(data.findIndex(data => data.name === "Female_count"),1);
           console.log(item);
          //delete  this.series[item];
          console.log(this.series)

      }*/

  },
};
</script>

<style>
/*----Report CSS*/
.title {
  margin-top: 50px;
}

.button-wrapper .btn {
  margin-right: 15px;
  width: 90px;
  height: 37px;
  color: white;
}
.d-table {
  margin-top: 50px;
  overflow: auto;
}

.module {
  text-align: center;
  cursor: pointer;
}

.module-wrapper {
  height: 50px;
  width: 50px;
  border-radius: 14px;
  border: 1px solid #00599a;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.module-wrapper .ant-card-body {
  padding: 9px !important;
}

.module p {
  margin-top: 4px;
}

.test {
  margin-left: 15px;
}
/* 
.test p {
  margin-top: 15px;
  margin-bottom: 0;
} */

.module-wrapper {
  height: 50px;
  width: 50px;
  border-radius: 14px;
  border: 1px solid #00599a;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* flex-flow: column;*/
/*.module-wrapper .ant-card-body {*/
/*padding: 9px !important;*/
/*}*/
/*.module p {*/
/*margin-top: 4px;*/
/*}*/

/* .button-wrapper {
  display: flex;
   align-items: center;
  justify-content:space-around;
} */

/* .test {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
} */

.all-card-wrapper {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  width: 80%;
  margin: auto;
}

.event-title {
  margin-left: auto;
  margin-right: auto;
  margin-top: -26px;
  background: #fff;
  width: 52%;
  text-align: center;
}

.test:hover img {
  transform: scale(0.95);
  transition: all 0.3s ease;
}
/* .ant-btn-primary {
  width: 50px !important;
} */

.ant-select{
  display:block !important;
}
.search-btn{
  margin-left:0px !important;
}
.search-btn .ant-btn-primary {
  width:50px !important;
}
.ant-btn{
display:block !important; 
}
@media(max-width:1125px){
  .btn-child{
    margin-bottom:5px;
  }
}
</style>
