<template>
  <a-row>
    <div class="map-wrapper-1">
          <Map />
    </div>
  </a-row>
</template>

<script>
import Map from "./Map";

export default {
  name: "Geography",
  data(){
    return{
      
    }
  },
  components: {
    Map,
  },
};
</script>

<style>
  .map-wrapper-1 {
   height: calc(100vh - 137px);
   overflow:auto;
  }
</style>