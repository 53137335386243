<template>
  <div class="main">
    <div class="container">
      <a-row>
        <!-- map wrapper -->
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <h2 class="province-name">{{ provinceNum }} GIS Map</h2>
          <a-row>
            <a-col :xl="14" :lg="14" :md="14" :xs="24" :sm="24">
              <p class="province-title">
                Human Rights Situation of {{ provinceNum }} in
              </p>
            </a-col>
            <a-col :xl="7" :lg="7" :md="7" :xs="24" :sm="24">
              <div class="province-date-select">
                <!-- <DatePicker
                   v-model="time1"
                   type="year"

                   :placeholder="selectYear"
                 ></DatePicker>-->
                <a-select
                  style="width:100%"
                  @change="changeDate"
                  v-model="time1"
                  :default-value="time1"
                >
                  <a-select-option
                    v-for="(dt, k) in dates"
                    :value="dt"
                    :key="k"
                  >
                    {{ dt }}
                  </a-select-option>
                </a-select>
              </div>
            </a-col>
            <a-col :span="24">
              <div class="map-content">
                <ProvinceMap :date_data="time1" ref="checka"></ProvinceMap>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <!-- report wrapper -->
        <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
          <a-row>
            <a-col :span="12">
              <p>No. of Victims by Incidents</p>
            </a-col>
            <a-col :span="9" :offset="3" class="stats">
              <div class="circle state"></div>
              <p>By State</p>
              <div class="circle others"></div>
              <p>By Others</p>
            </a-col>
          </a-row>
          <a-row>
            <!-- stat section -->
            <a-col :span="24" class="stat-container">
              <div class="infograph">
                <a-col :span="2" class="info-wrapper">
                  <div class="infograph-item">
                    <p>{{ killed.state }}</p>
                    <div style="text-align: center">
                      {{ killed.non_state }}
                    </div>
                  </div>
                  <a-divider class="connecter" type="vertical" />
                  <div class="icon">
                    <img
                      src="../../../public/icons/GIS ICONS/GIS_Killed.svg"
                      alt=""
                      height="70"
                      width="70"
                    />
                    <span>Killed</span>
                  </div>
                </a-col>
                <a-col :span="2" class="info-wrapper">
                  <div class="infograph-img">
                    <div class="icons">
                      <span>Injured</span>
                      <img
                        src="../../../public/icons/GIS ICONS/GIS_Injured.svg"
                        alt=""
                        height="70"
                        width="70"
                      />
                    </div>
                    <a-divider class="connecter" type="vertical" />
                    <div class="infograph-item bottom-items">
                      <div style="text-align: center">
                        {{ injured.state }}
                      </div>
                      <p>{{ injured.non_state }}</p>
                    </div>
                  </div>
                </a-col>
                <a-col :span="2" class="info-wrapper">
                  <div class="infograph-item">
                    <p>{{ childRight.state }}</p>
                    <div style="text-align: center">
                      {{ childRight.non_state }}
                    </div>
                  </div>
                  <a-divider class="connecter" type="vertical" />
                  <div class="icon">
                    <img
                      src="../../../public/icons/GIS ICONS/GIS_Child_Rights.svg"
                      alt=""
                      height="70"
                      width="70"
                    />
                    <span>Child<br />Right</span>
                  </div>
                </a-col>
                <a-col :span="2" class="info-wrapper">
                  <div class="infograph-img" style="margin-top:-24px;">
                    <div class="icons">
                      <span style="font-size:11px;"
                        >Inhuman <br />
                        Behaviour</span
                      >
                      <img
                        src="../../../public/icons/GIS ICONS/GIS_Inhuman_Behaviour.svg"
                        alt=""
                        height="70"
                        width="70"
                      />
                    </div>
                    <a-divider class="connecter" type="vertical" />
                    <div class="infograph-item bottom-items">
                      <div style="text-align: center">
                        {{ inhumanBehaviour.state }}
                      </div>
                      <p>{{ inhumanBehaviour.non_state }}</p>
                    </div>
                  </div>
                </a-col>
                <a-col :span="2" class="info-wrapper">
                  <div class="infograph-item">
                    <p>{{ ESCRight.state }}</p>
                    <div style="text-align: center">
                      {{ ESCRight.non_state }}
                    </div>
                  </div>
                  <a-divider class="connecter" type="vertical" />
                  <div class="icon">
                    <img
                      src="../../../public/icons/GIS ICONS/GIS_ESC_rights.svg"
                      alt=""
                      height="70"
                      width="70"
                    />
                    <span>ESC<br />Rights</span>
                  </div>
                </a-col>
                <a-col :span="2" class="info-wrapper">
                  <div class="infograph-img">
                    <div class="icons">
                      <span>Abduction</span>
                      <img
                        src="../../../public/icons/GIS ICONS/GIS_Abduction.svg"
                        alt=""
                        height="70"
                        width="70"
                      />
                    </div>
                    <a-divider class="connecter" type="vertical" />
                    <div class="infograph-item bottom-items">
                      <div style="text-align: center">
                        {{ abduction.state }}
                      </div>
                      <p>{{ abduction.non_state }}</p>
                    </div>
                  </div>
                </a-col>
                <a-col :span="2" class="info-wrapper">
                  <div class="infograph-item">
                    <p>{{ womenRight.state }}</p>
                    <div style="text-align: center">
                      {{ womenRight.non_state }}
                    </div>
                  </div>
                  <a-divider class="connecter" type="vertical" />
                  <div class="icon">
                    <img
                      src="../../../public/icons/GIS ICONS/GIS_Women_Rights.svg"
                      alt=""
                      height="70"
                      width="70"
                    />
                    <span>Women<br />Rights</span>
                  </div>
                </a-col>
                <a-col :span="2" class="info-wrapper">
                  <div class="infograph-img" style="margin-top:-23px;">
                    <div class="icons">
                      <span style="font-size:11px;"
                        >Right to <br />
                        Assembly</span
                      >
                      <img
                        src="../../../public/icons/GIS ICONS/GIS_Right_to_Assembly.svg"
                        alt=""
                        height="70"
                        width="70"
                      />
                    </div>
                    <a-divider class="connecter" type="vertical" />
                    <div class="infograph-item bottom-items">
                      <div style="text-align: center">
                        {{ rightToAssembly.state }}
                      </div>
                      <p>{{ rightToAssembly.non_state }}</p>
                    </div>
                  </div>
                </a-col>
                <a-col :span="2" class="info-wrapper">
                  <div class="infograph-item">
                    <p>{{ racialDiscrimination.state }}</p>
                    <div style="text-align: center">
                      {{ racialDiscrimination.non_state }}
                    </div>
                  </div>
                  <a-divider class="connecter" type="vertical" />
                  <div class="icon">
                    <img
                      src="../../../public/icons/GIS ICONS/GIS_Racial_Discrimination.svg"
                      alt=""
                      height="70"
                      width="70"
                    />
                    <span
                      style="text-align: center; font-size:11px;position:relative;top:-5"
                      >Racial<br />Discrimination</span
                    >
                  </div>
                </a-col>
                <a-col :span="2" class="info-wrapper">
                  <div class="infograph-img">
                    <div class="icons">
                      <span>Beating</span>
                      <img
                        src="../../../public/icons/GIS ICONS/GIS_Beating.svg"
                        alt=""
                        height="70"
                        width="70"
                      />
                    </div>
                    <a-divider class="connecter" type="vertical" />
                    <div class="infograph-item bottom-items">
                      <div style="text-align: center">
                        {{ beating.state }}
                      </div>
                      <p>{{ beating.non_state }}</p>
                    </div>
                  </div>
                </a-col>
                <a-col :span="2" class="info-wrapper">
                  <div class="infograph-item">
                    <p>{{ torture.state }}</p>
                    <div style="text-align: center">
                      {{ torture.non_state }}
                    </div>
                  </div>
                  <a-divider class="connecter" type="vertical" />
                  <div class="icon">
                    <img
                      src="../../../public/icons/GIS ICONS/GIS_Torture.svg"
                      alt=""
                      height="70"
                      width="70"
                    />
                    <span>Torture</span>
                  </div>
                </a-col>
                <a-col :span="2" class="info-wrapper">
                  <div class="infograph-img" style="margin-top:-3px;">
                    <div class="icons">
                      <span>Threat</span>
                      <img
                        src="../../../public/icons/GIS ICONS/GIS_Threat.svg"
                        alt=""
                        height="70"
                        width="70"
                      />
                    </div>
                    <a-divider class="connecter" type="vertical" />
                    <div class="infograph-item bottom-items">
                      <div style="text-align: center">
                        {{ threat.state }}
                      </div>
                      <p>{{ threat.non_state }}</p>
                    </div>
                  </div>
                </a-col>
                <a-divider class="divider" />
              </div>

              <!-- <a-row class="infograph">
                
                <a-col class="infograph-content" :span="24">
                  <a-row>
                    <a-col :span="24">
                      <div class="stats-wrapper top">
                        <div class="stat...s-wrapper-child">
                          <div class="infograph-item first">
                            <p>{{ killed.state }}</p>
                            <div style="text-align: center">
                              {{ killed.non_state }}
                            </div>
                          </div>
                          <a-divider class="connecter" type="vertical" />
                        </div>

                        <div class="infograph-img img-top">
                          <br />
                          <span>Injured</span>
                          <img
                            src="../../../public/icons/GIS ICONS/GIS_Injured.svg"
                            alt=""
                            height="70"
                            width="70"
                          />
                        </div>
                        <div class="stats-wrapper-child">
                          <div class="infograph-item first">
                            <p>{{ childRight.state }}</p>
                            <div style="text-align: center">
                              {{ childRight.non_state }}
                            </div>
                          </div>
                          <a-divider class="connecter" type="vertical" />
                        </div>

                        <div class="infograph-img img-top">
                          <span
                            >Inhuman <br />
                            Behaviour</span
                          >
                          <img
                            src="../../../public/icons/GIS ICONS/GIS_Inhuman_Behaviour.svg"
                            alt=""
                            height="70"
                            width="70"
                          />
                        </div>
                        <div class="stats-wrapper-child">
                          <div class="infograph-item third">
                            <p>{{ ESCRight.state }}</p>
                            <div style="text-align: center">
                              {{ ESCRight.non_state }}
                            </div>
                          </div>
                          <a-divider class="connecter" type="vertical" />
                        </div>

                        <div class="infograph-img img-top">
                          <br />
                          <span>Abduction</span>
                          <img
                            src="../../../public/icons/GIS ICONS/GIS_Abduction.svg"
                            alt=""
                            height="70"
                            width="70"
                          />
                        </div>
                        <div class="stats-wrapper-child">
                          <div class="infograph-item fourth">
                            <p>{{ womenRight.state }}</p>
                            <div style="text-align: center">
                              {{ womenRight.non_state }}
                            </div>
                          </div>
                          <a-divider class="connecter" type="vertical" />
                        </div>

                        <div class="infograph-img img-top">
                          <span
                            >Right to <br />
                            Assembly</span
                          >
                          <img
                            src="../../../public/icons/GIS ICONS/GIS_Right_to_Assembly.svg"
                            alt=""
                            height="70"
                            width="70"
                          />
                        </div>
                        <div class="stats-wrapper-child">
                          <div class="infograph-item fourth">
                            <p>{{ racialDiscrimination.state }}</p>
                            <div style="text-align: center">
                              {{ racialDiscrimination.non_state }}
                            </div>
                          </div>
                          <a-divider class="connecter" type="vertical" />
                        </div>

                        <div class="infograph-img img-top">
                          <br />
                          <span>Beating</span>
                          <img
                            src="../../../public/icons/GIS ICONS/GIS_Beating.svg"
                            alt=""
                            height="70"
                            width="70"
                          />
                        </div>
                        <div class="stats-wrapper-child">
                          <div class="infograph-item sixth">
                            <p>{{ torture.state }}</p>
                            <div style="text-align: center">
                              {{ torture.non_state }}
                            </div>
                          </div>
                          <a-divider class="connecter" type="vertical" />
                        </div>

                        <div class="infograph-img img-top">
                          <br />
                          <span>Threat</span>
                          <img
                            src="../../../public/icons/GIS ICONS/GIS_Threat.svg"
                            alt=""
                            height="70"
                            width="70"
                          />
                        </div>
                      </div>
                      <a-divider class="divider" />
                      <div class="stats-wrapper buttom">
                        <div
                          class="infograph-img img-b"
                          style="margin-bottom: 20px"
                        >
                          <img
                            src="../../../public/icons/GIS ICONS/GIS_Killed.svg"
                            alt=""
                            height="70"
                            width="70"
                          />
                          <span>Killed</span>
                        </div>
                        <div class="stats-wrapper-child">
                          <a-divider class="connecter" type="vertical" />
                          <div class="infograph-item first">
                            <div style="text-align: center">
                              {{ injured.state }}
                            </div>
                            <p>{{ injured.non_state }}</p>
                          </div>
                        </div>
                        <div class="infograph-img img-b">
                          <img
                            src="../../../public/icons/GIS ICONS/GIS_Child_Rights.svg"
                            alt=""
                            height="70"
                            width="70"
                          />
                          <span>Child<br />Right</span>
                        </div>

                        <div class="stats-wrapper-child">
                          <a-divider class="connecter" type="vertical" />
                          <div class="infograph-item second">
                            <div style="text-align: center">
                              {{ inhumanBehaviour.state }}
                            </div>
                            <p>{{ inhumanBehaviour.non_state }}</p>
                          </div>
                        </div>
                        <div class="infograph-img img-b">
                          <img
                            src="../../../public/icons/GIS ICONS/GIS_ESC_rights.svg"
                            alt=""
                            height="70"
                            width="70"
                          />
                          <span>ESC<br />Rights</span>
                        </div>

                        <div class="stats-wrapper-child">
                          <a-divider class="connecter" type="vertical" />
                          <div class="infograph-item second">
                            <div style="text-align: center">
                              {{ abduction.state }}
                            </div>
                            <p>{{ abduction.non_state }}</p>
                          </div>
                        </div>
                        <div class="infograph-img img-b">
                          <img
                            src="../../../public/icons/GIS ICONS/GIS_Women_Rights.svg"
                            alt=""
                            height="70"
                            width="70"
                          />
                          <span>Women<br />Rights</span>
                        </div>
                        <div class="stats-wrapper-child">
                          <a-divider class="connecter" type="vertical" />
                          <div class="infograph-item fourth">
                            <div style="text-align: center">
                              {{ rightToAssembly.state }}
                            </div>
                            <p>{{ rightToAssembly.non_state }}</p>
                          </div>
                        </div>
                        <div class="infograph-img img-b">
                          <img
                            src="../../../public/icons/GIS ICONS/GIS_Racial_Discrimination.svg"
                            alt=""
                            height="70"
                            width="70"
                          />
                          <span style="text-align: center"
                            >Racial<br />Discrimination</span
                          >
                        </div>
                        <div class="stats-wrapper-child">
                          <a-divider class="connecter" type="vertical" />
                          <div class="infograph-item fifth">
                            <div style="text-align: center">
                              {{ beating.state }}
                            </div>
                            <p>{{ beating.non_state }}</p>
                          </div>
                        </div>
                        <div
                          class="infograph-img img-b"
                          style="margin-bottom: 20px"
                        >
                          <img
                            src="../../../public/icons/GIS ICONS/GIS_Torture.svg"
                            alt=""
                            height="70"
                            width="70"
                          />
                          <span>Torture</span>
                        </div>

                        <div class="stats-wrapper-child">
                          <a-divider class="connecter" type="vertical" />
                          <div class="infograph-item fifth">
                            <div style="text-align: center">
                              {{ threat.state }}
                            </div>
                            <p>{{ threat.non_state }}</p>
                          </div>
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row> -->
              <!-- <a-row>
                <a-col class="province-stat" :span="24">
                  <p>{{ provinceNum }} Statistics</p>
                  <a-row>
                    <a-col class="province-stat-items" :span="6">
                      <img
                        src="../../../public/icons/GIS ICONS/GIS_Total_Reports.svg"
                        alt=""
                        height="75"
                        width="75"
                      />
                      <div class="stat-content">
                        <div class="total-report">
                          <h3>Total Reports</h3>
                          <p>{{ report }}</p>
                        </div>
                      </div>
                    </a-col>
                    <a-col class="province-stat-items" :span="6" :offset="2">
                      <img
                        src="../../../public/icons/GIS ICONS/GIS_Perpetrators.svg"
                        alt=""
                        height="75"
                        width="75"
                      />
                      <div class="stat-content">
                        <div class="total-report">
                          <h3>Total Perpetrator</h3>
                          <p>{{ perpetrator }}</p>
                        </div>
                      </div>
                    </a-col>
                    <a-col class="province-stat-items" :span="6" :offset="2">
                      <img
                        src="../../../public/icons/GIS ICONS/GIS_Victims.svg"
                        alt=""
                        height="75"
                        width="75"
                      />
                      <div class="stat-content">
                        <div class="total-report">
                          <h3>Total Victims</h3>
                          <p>{{ victims }}</p>
                        </div>
                      </div>
                    </a-col>
                  </a-row>

                  
                <a-col class="province-stat-items" :span="6">
                  <img
                    src="../../../public/icons/GIS ICONS/GIS_Total_Reports.svg"
                    alt=""
                    height="75"
                    width="75"
                  />
                  <div class="stat-content">
                    <div class="total-report">
                      <h3>Total Reports</h3>
                      <p>{{report}}</p>
                    </div>
                  </div>
                </a-col>
                <a-col class="province-stat-items" :span="6" :offset="2">
                  <img
                    src="../../../public/icons/GIS ICONS/GIS_Perpetrators.svg"
                    alt=""
                    height="75"
                    width="75"
                  />
                  <div class="stat-content">
                    <div class="total-report">
                      <h3>Total Perpetrator</h3>
                      <p>{{perpetrator}}</p>
                    </div>
                  </div>
                </a-col>
                <a-col class="province-stat-items" :span="6" :offset="2">
                  <img
                    src="../../../public/icons/GIS ICONS/GIS_Victims.svg"
                    alt=""
                    height="75"
                    width="75"
                  />
                  <div class="stat-content">
                    <div class="total-report">
                      <h3>Total Victims</h3>
                      <p>{{victims}}</p>
                    </div>
                  </div>
                </a-col>
              </a-row> -->
              <!-- </a-col> -->
              <!-- </a-row> -->
            </a-col>
          </a-row>
          <!-- mobile-stat section -->
          <a-row>
            <a-col :span="24" class="mobile-stat-container">
              <div class="mobo-wrapper">
                 <a-row style="position:relative; left:20px;">
                <div class="mobile-infograph">
                  <div class="mobo-icon">
                    <span>Killed</span>
                    <img
                      src="../../../public/icons/mobile-icon/insec_killed.svg"
                      alt=""
                      height="70"
                      width="70"
                    />
                  </div>
                  <div class="mobo-connecter"></div>
                  <div class="infograph-item mobo-infograph-item">
                    <p>{{ killed.state }}</p>
                    <div style="text-align: center">
                      {{ killed.non_state }}
                    </div>
                  </div>
                </div>
              </a-row>
              <a-row style="position:relative; right:-5px;">
                <div class="mobile-infograph">
                  <div class="infograph-item mobo-infograph-item" style="left:6px;">
                    <div style="text-align: center">
                      {{ injured.state }}
                    </div>
                    <p>{{ injured.non_state }}</p>
                  </div>

                  <div class="mobo-connecter" style="left:6px;" ></div>
                  <div class="mobo-icon">
                    <img
                      src="../../../public/icons/mobile-icon/insec_injured.svg"
                      alt=""
                      height="70"
                      width="70"
                    />
                    <span>Injured</span>
                  </div>
                </div>
              </a-row>
              <a-row
                style="position: relative;
left: 0px;"
              >
                <div class="mobile-infograph">
                  <div class="mobo-icon">
                    <span>Child Right</span>
                    <img
                      src="../../../public/icons/mobile-icon/insec_child rights.svg"
                      alt=""
                      height="70"
                      width="70"
                    />
                  </div>
                  <div class="mobo-connecter"></div>
                  <div class="infograph-item mobo-infograph-item">
                    <p>{{ childRight.state }}</p>
                    <div style="text-align: center">
                      {{ childRight.non_state }}
                    </div>
                  </div>
                </div>
              </a-row>
               <a-row style="position:relative;
               left: 42px;">
                <div class="mobile-infograph">
                  <div class="infograph-item mobo-infograph-item" style="left:6px;">
                   <div style="text-align: center">
                        {{ inhumanBehaviour.state }}
                      </div>
                      <p>{{ inhumanBehaviour.non_state }}</p>
                  </div>

                  <div class="mobo-connecter" style="left:6px;"></div>
                  <div class="mobo-icon">
                    
                      <img
                        src="../../../public/icons/mobile-icon/insec_inhuman behavior.svg"
                        alt=""
                        height="70"
                        width="70"
                      />
                        <span style="font-size:0.8rem">Inhuman Behaviour</span
                      >
                  </div>
                </div>
              </a-row>
              <a-row
                style="position: relative;
left: 0px;"
              >
                <div class="mobile-infograph">
                  <div class="mobo-icon">
                    <span>ESC Rights</span>
                    <img
                      src="../../../public/icons/mobile-icon/insec_ESC rights.svg"
                      alt=""
                      height="70"
                      width="70"
                    />
                  </div>
                  <div class="mobo-connecter"></div>
                  <div class="infograph-item mobo-infograph-item">
                    <p>{{ ESCRight.state }}</p>
                    <div style="text-align: center">
                      {{ ESCRight.non_state }}
                    </div>
                  </div>
                </div>
              </a-row>
               <a-row style="position:relative; left:15px;">
                <div class="mobile-infograph">
                  <div class="infograph-item mobo-infograph-item" style="left:6px;">
                    <div style="text-align: center">
                        {{ abduction.state }}
                      </div>
                      <p>{{ abduction.non_state }}</p>
                  </div>

                  <div class="mobo-connecter" style="left:6px;"></div>
                  <div class="mobo-icon">
                    
                      <img
                        src="../../../public/icons/mobile-icon/insec_abduction.svg"
                        alt=""
                        height="70"
                        width="70"
                      />
                        <span>Abduction</span>
                  </div>
                </div>
              </a-row>
              <a-row
                style="position: relative;
left: -15px;"
              >
                <div class="mobile-infograph">
                  <div class="mobo-icon">
                    <span>Women Rights</span>
                    <img
                      src="../../../public/icons/mobile-icon/insec_women right.svg"
                      alt=""
                      height="70"
                      width="70"
                    />
                  </div>
                  <div class="mobo-connecter"></div>
                  <div class="infograph-item mobo-infograph-item">
                    <p>{{ womenRight.state }}</p>
                    <div style="text-align: center">
                      {{ womenRight.non_state }}
                    </div>
                  </div>
                </div>
              </a-row>
               <a-row style="position:relative;
               left:40px;">
                <div class="mobile-infograph">
                  <div class="infograph-item mobo-infograph-item" style="left:6px;">
                    <div style="text-align: center">
                        {{ rightToAssembly.state }}
                      </div>
                      <p>{{ rightToAssembly.non_state }}</p>
                  </div>

                  <div class="mobo-connecter" style="left:6px;"></div>
                  <div class="mobo-icon">
                    
                      <img
                        src="../../../public/icons/mobile-icon/insec_Right to Assembly.svg"
                        alt=""
                        height="70"
                        width="70"
                      />
                        <span>Right to Assembly</span>
                  </div>
                </div>
              </a-row>
              <a-row
                style="position: relative;
left: -35px;"
              >
                <div class="mobile-infograph">
                  <div class="mobo-icon">
                    <span>Racial Discrimination</span>
                    <img
                      src="../../../public/icons/mobile-icon/insec_Racial Discrimination.svg"
                      alt=""
                      height="70"
                      width="70"
                    />
                  </div>
                  <div class="mobo-connecter"></div>
                  <div class="infograph-item mobo-infograph-item">
                    <p>{{ racialDiscrimination.state }}</p>
                    <div style="text-align: center">
                      {{ racialDiscrimination.non_state }}
                    </div>
                  </div>
                </div>
              </a-row>
               <a-row style="position:relative;left:5px;">
                <div class="mobile-infograph">
                  <div class="infograph-item mobo-infograph-item" style="left:6px;">
                     <div style="text-align: center">
                        {{ beating.state }}
                      </div>
                      <p>{{ beating.non_state }}</p>
                  </div>

                  <div class="mobo-connecter" style="left:6px;"></div>
                  <div class="mobo-icon">
                      <img
                        src="../../../public/icons/mobile-icon/insec_Beating.svg"
                        alt=""
                        height="70"
                        width="70"
                      />
                        <span>Beating</span>
                  </div>
                </div>
              </a-row>
              <a-row style="position:relative; left:10px;">
                <div class="mobile-infograph">
                  <div class="mobo-icon">
                    <span>Torture</span>
                    <img
                      src="../../../public/icons/mobile-icon/insec_Torture.svg"
                      alt=""
                      height="70"
                      width="70"
                    />
                  </div>
                  <div class="mobo-connecter"></div>
                  <div class="infograph-item mobo-infograph-item">
                    <p>{{ torture.state }}</p>
                    <div style="text-align: center">
                      {{ torture.non_state }}
                    </div>
                  </div>
                </div>
              </a-row>
                  <a-divider class="mobo-divider" type="vertical" />
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
        
          <a-row class="stat-total-report">
              <a-divider />
            <p>{{ provinceNum }} Statistics</p>
            <!-- <a-col :xl="5" :lg="5" :md="5" :sm="24" :xs="24">
              <div class="province-stat">
                <div class="province-stat-img">
                  <img
                    src="../../../public/icons/GIS ICONS/GIS_Total_Reports.svg"
                    alt=""
                    height="75"
                    width="75"
                  />
                </div>
                <div class="total-report">
                  <h3>Total Reports</h3>
                  <p>{{ report }}</p>
                </div>
              </div>
            </a-col>
            <a-col :xl="5" :lg="5" :md="5" :sm="24" :xs="24" :offset="3">
              <div class="province-stat">
                <div class="province-stat-img">
                  <img
                    src="../../../public/icons/GIS ICONS/GIS_Perpetrators.svg"
                    alt=""
                    height="75"
                    width="75"
                  />
                </div>
                <div class="total-report">
                  <h3 style="font-size:15px;">Total Perpetrator</h3>
                  <p>{{ perpetrator }}</p>
                </div>
              </div>
            </a-col>
            <a-col :xl="5" :lg="5" :md="5" :sm="24" :xs="24" :offset="3">
              <div class="province-stat">
                <div class="province-stat-img">
                  <img
                    src="../../../public/icons/GIS ICONS/GIS_Victims.svg"
                    alt=""
                    height="75"
                    width="75"
                  />
                </div>
                <div class="total-report">
                  <h3>Total Victims</h3>
                  <p>{{ victims }}</p>
                </div>
              </div>
            </a-col> -->
              <a-col  :xl="{span:7}" :lg="{span:7}" :md="24" :sm="24" :xs="24">
                  <div class="province-stat">
                <div class="province-stat-img">
                  <img
                    src="../../../public/icons/GIS ICONS/GIS_Total_Reports.svg"
                    alt=""
                    height="75"
                    width="75"
                  />
                </div>
                <div class="total-report">
                  <h3>Total Reports</h3>
                  <p>{{ report }}</p>
                </div>
              </div>
              </a-col>
              <a-col  :xl="{span:7,offset:1}" :lg="{span:7,offset:1}" :md="24" :sm="24" :xs="24">
                  <div class="province-stat">
                <div class="province-stat-img">
                  <img
                    src="../../../public/icons/GIS ICONS/GIS_Perpetrators.svg"
                    alt=""
                    height="75"
                    width="75"
                  />
                </div>
                <div class="total-report">
                  <h3 style="font-size:15px; margin-left:10px;">Total Perpetrator</h3>
                  <p>{{ perpetrator }}</p>
                </div>
              </div>
              </a-col>
              <a-col  :xl="{span:7,offset:1}" :lg="{span:7,offset:1}" :md="24" :sm="24" :xs="24">
                  <div class="province-stat">
                <div class="province-stat-img">
                  <img
                    src="../../../public/icons/GIS ICONS/GIS_Victims.svg"
                    alt=""
                    height="75"
                    width="75"
                  />
                </div>
                <div class="total-report">
                  <h3>Total Victims</h3>
                  <p>{{ victims }}</p>
                </div>
              </div>
              </a-col>
          </a-row>
          <a-row class="mobo-stat-report">
            <a-col :span="24" style="margin-bottom:5px">
                <div class="stat-report">
                Total Reports : {{ report }}
            </div>
            </a-col>
            <a-col :span="24" style="margin-bottom:5px">
                 <div class="stat-report">
                Total Perpetrator : {{  perpetrator }}
            </div>
            </a-col>
            <a-col :span="24" style="margin-bottom:5px">
               <div class="stat-report">
                Total Victims : {{  victims }}
            </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <!-- grand total table -->
      <!--<a-row>
        <a-col :span="22" :offset="1">
          <h3>VIOLENCE AGAINST WOMEN</h3>
          <a-table
            :columns="grandTotalColumns"
            :data-source="grandTotalData"
            bordered
            size="middle"
            :scroll="{ x: 'calc(700px + 50%)', y: 240 }"
          />
        </a-col>
      </a-row>-->
      <!-- <Scroll/> -->
      <div class="scroll">
        <Scroll />
      </div>
      <a-row style="position:relative;top:-80px;">
        <a-divider class="bottom-hr" />
        <a-col :span="24" style="text-align:center;">
          <h2>Number of Incident with Status of Legal Proceeding</h2>
        </a-col>
        <a-col :span="22" :offset="1">
          <!-- child rights -->
          <h3>CHILD RIGHTS</h3>
          <div class="table">
            <a-table
              :columns="columns"
              :data-source="new_child_data"
              :scroll="true"
            >
              <!--   <a slot="name" slot-scope="text">{{ text }}</a>-->
            </a-table>
          </div>
        </a-col>
        <!-- women rights -->
        <a-col :span="22" :offset="1">
          <h3>WOMEN RIGHTS</h3>
          <div class="table">
            <a-table :columns="columns1" :data-source="new_woman_data">
            </a-table>
          </div>
        </a-col>
        <!-- number of incidents -->
        <a-col :span="22" :offset="1">
          <h3>Number of Incidents</h3>
          <div class="table">
            <a-table :columns="columns2" :data-source="killing_new_data">
            </a-table>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import Scroll from "../Scroll/Scroll";
import ARow from "ant-design-vue/es/grid/Row";
import ACol from "ant-design-vue/es/grid/Col";
import ProvinceMap from "./province_map";
//import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { EventBus } from "@/main";
// Listen for the i-got-clicked event and its payload.
// table data
// child rights
const columns = [
  {
    title: "Type of Events",
    dataIndex: "event_type_data",
    key: "event_type_data",
    scopedSlots: { customRender: "events" },
  },
  {
    title: "Closed Case",
    dataIndex: "closed_case",
    key: "closed_case",
  },
  {
    title: "Compromise",
    dataIndex: "compromise",
    key: "compromise",
    // ellipsis: true,
  },
  {
    title: "Incomplete",
    dataIndex: "incomplete",
    key: "incomplete",
    // ellipsis: true,
  },
  {
    title: "Investigation",
    dataIndex: "investigation",
    key: "investigation",
    // ellipsis: true,
  },
  {
    title: "Legal Proceeding",
    dataIndex: "legal_proceeding",
    key: "legal_proceeding",
    // ellipsis: true,
  },
  {
    title: "No Legal Proceeding",
    dataIndex: "no_legal_proceeding",
    key: "no_legal_proceeding",
    // ellipsis: true,
  },
];
const data = [
  {
    key: "1",
    events: "Child Trafficking",
    case: 32,
    compromise: "1",
    incomplete: "1",
    investigation: "1",
    legalproceeding: "1",
    noproceeding: "1",
  },
  {
    key: "2",
    events: "Rape",
    case: 42,
    compromise: "1",
    incomplete: "1",
    investigation: "1",
    legalproceeding: "1",
    noproceeding: "1",
  },
  {
    key: "3",
    events: "Sexual Abuse",
    case: 32,
    compromise: "1",
    incomplete: "1",
    investigation: "1",
    legalproceeding: "1",
    noproceeding: "1",
  },
  {
    key: "3",
    events: "Total",
    case: 32,
    compromise: "1",
    incomplete: "1",
    investigation: "1",
    legalproceeding: "1",
    noproceeding: "1",
  },
];
// women rights
const columns1 = [
  {
    title: "Type of Events",
    dataIndex: "w_event",
    key: "w_event",
  },
  {
    title: "Closed Case",
    dataIndex: "closed_case",
    key: "closed_case",
    // width: 100,
  },
  {
    title: "Compromise",
    dataIndex: "compromise",
    key: "compromise",
    // ellipsis: true,
  },
  {
    title: "Incomplete",
    dataIndex: "incomplete",
    key: "incomplete",
    // ellipsis: true,
  },
  {
    title: "Investigation",
    dataIndex: "investigation",
    key: "investigation",
    // ellipsis: true,
  },
  {
    title: "Legal Proceeding",
    dataIndex: "legal_proceeding",
    key: "legal_proceeding",
    // ellipsis: true,
  },
  {
    title: "No Legal Proceeding",
    dataIndex: "no_legal_proceeding",
    key: "no_legal_proceeding",
  },
];
const data1 = [
  {
    key: "1",
    events: "Domestic Violence",
    case: 32,
    compromise: "1",
    incomplete: "1",
    investigation: "1",
    legalproceeding: "1",
    noproceeding: "1",
  },
  {
    key: "2",
    events: "Rape",
    case: 42,
    compromise: "1",
    incomplete: "1",
    investigation: "1",
    legalproceeding: "1",
    noproceeding: "1",
  },
  {
    key: "3",
    events: "Rape Attempt",
    case: 32,
    compromise: "1",
    incomplete: "1",
    investigation: "1",
    legalproceeding: "1",
    noproceeding: "1",
  },
  {
    key: "3",
    events: "Total",
    case: 32,
    compromise: "1",
    incomplete: "1",
    investigation: "1",
    legalproceeding: "1",
    noproceeding: "1",
  },
];
// number of incident
const columns2 = [
  {
    title: "Type of Events",
    dataIndex: "k_event_type",
    key: "k_event_type",
    scopedSlots: { customRender: "events" },
  },
  {
    title: "Closed Case",
    dataIndex: "closed_case",
    key: "closed_case",
    // width: 100,
  },
  {
    title: "Compromise",
    dataIndex: "compromise",
    key: "compromise",
    // ellipsis: true,
  },
  {
    title: "Incomplete",
    dataIndex: "incomplete",
    key: "incomplete",
    // ellipsis: true,
  },
  {
    title: "Investigation",
    dataIndex: "investigation",
    key: "investigation",
    // ellipsis: true,
  },
  {
    title: "Legal Proceeding",
    dataIndex: "legal_proceeding",
    key: "legal_proceeding",
    // ellipsis: true,
  },
  {
    title: "No Legal Proceeding",
    dataIndex: "no_legal_proceeding",
    key: "no_legal_proceeding",
    // ellipsis: true,
  },
];
const data2 = [
  {
    events: "Killing",
    case: 32,
    compromise: "1",
    incomplete: "1",
    investigation: "1",
    legalproceeding: "1",
    noproceeding: "1",
  },
];
// grand total table
const grandTotalColumns = [
  {
    title: "Type of Events",
    dataIndex: "events",
    key: "events",
    width: "250",
    fixed: "left",
    onFilter: (value, record) => record.name.indexOf(value) === 0,
  },
  {
    title: "By Others",
    children: [
      {
        title: "Third Gender",
        dataIndex: "thirdGender",
        key: "thirdGender",
      },
      {
        title: "Female",
        dataIndex: "female",
        key: "female",
      },
      {
        title: "Male",
        dataIndex: "male",
        key: "male",
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
      },
    ],
  },
];
const grandTotalData = [
  {
    events: "rape",
    thirdGender: 1,
    female: 1,
    male: 1,
    total: 1,
  },
  {
    events: "Inhuman Behaviour",
    thirdGender: 1,
    female: 1,
    male: 1,
    total: 1,
  },
  {
    events: "Injured",
    thirdGender: 1,
    female: 1,
    male: 1,
    total: 1,
  },
  {
    events: "Killing",
    thirdGender: 1,
    female: 1,
    male: 1,
    total: 1,
  },
  {
    events: "Child Rights",
    children: [
      {
        events: "Child Trafficking",
        thirdGender: 1,
        female: 1,
        male: 1,
        total: 1,
      },
      {
        events: "Rape",
        thirdGender: 1,
        female: 1,
        male: 1,
        total: 1,
      },
      {
        events: "Sexual Abuse",
        thirdGender: 1,
        female: 1,
        male: 1,
        total: 1,
      },
      {
        events: "Total",
        thirdGender: 1,
        female: 1,
        male: 1,
        total: 1,
      },
    ],
  },
  {
    events: "Women Rights",
    children: [
      {
        events: "Domestic Violence",
        thirdGender: 1,
        female: 1,
        male: 1,
        total: 1,
      },
      {
        events: "Rape",
        thirdGender: 1,
        female: 1,
        male: 1,
        total: 1,
      },
      {
        events: "Rape Attempt",
        thirdGender: 1,
        female: 1,
        male: 1,
        total: 1,
      },
      {
        events: "Total",
        thirdGender: 1,
        female: 1,
        male: 1,
        total: 1,
      },
    ],
  },
  {
    events: "Grand Total",
    thirdGender: 1,
    female: 1,
    male: 1,
    total: 1,
  },
];
export default {
  name: "Province",
  components: {
    ACol,
    ARow,
    ProvinceMap,
    Scroll,
    //DatePicker,
  },
  data() {
    return {
      loading: false,
      selectYear: "Select Year ",
      killed: {
        state: 0,
        non_state: 0,
      },
      injured: {
        state: 0,
        non_state: 0,
      },
      childRight: {
        state: 0,
        non_state: 0,
      },
      inhumanBehaviour: {
        state: 0,
        non_state: 0,
      },
      ESCRight: {
        state: 0,
        non_state: 0,
      },
      abduction: {
        state: 0,
        non_state: 0,
      },
      womenRight: {
        state: 0,
        non_state: 0,
      },
      rightToAssembly: {
        state: 0,
        non_state: 0,
      },
      racialDiscrimination: {
        state: 0,
        non_state: 0,
      },
      beating: {
        state: 0,
        non_state: 0,
      },
      torture: {
        state: 0,
        non_state: 0,
      },
      threat: {
        state: 0,
        non_state: 0,
      },
      perpetrator: null,
      report: null,
      victims: null,
      provinceNum: "Province",
      data,
      columns,
      data1,
      columns1,
      data2,
      columns2,
      switch_e_data:'',
      grandTotalColumns,
      grandTotalData,
      child_rights_key: [],
      child_rights_data: [],
      new_child_data: [],
      women_rights_data: [],
      women_rights_key: [],
      new_woman_data: [],
      killing_data: [],
      killing_data_key: [],
      killing_new_data: [],
      dates: [
        "2021",
        "2020",
        "2019",
        "2018",
        "2017",
        "2016",
        "2015",
        "2014",
        "2013",
        "2012",
        "2011",
        "2010",
        "2009",
        "2008",
        "2007",
        "2006",
        "2005",
        "2004",
        "2003",
        "2002",
        "2001",
        "2000",
        "1999",
        "1998",
        "1997",
        "1996",
        "1995",
        "1994",
        "1993",
      ],
      time1: "2020",
    };
  },
  created() {
    // this.provinceNum = parseInt(this.$route.params.id);
    EventBus.$on("districtData", (data) => {
      Object.assign(this.$data, this.$options.data.call(this));
      this.provinceNum = data.message;

      // for(let i = 0; i < data.length; i++) {
      //   this.killed += data[i].Killed.state
      // }
      this.child_rights_key = Object.keys(data.data[0].child_rights);
      this.child_rights_data = data.data;
      this.women_rights_data = data.data;
      this.women_rights_key = Object.keys(data.data[0].women_rights);
      this.killing_data = data.data;
      this.killing_data_key = Object.keys(data.data[0].killing);

      data.data.forEach((element) => {
        this.killed.state += element.Killed.state;
        this.killed.non_state += element.Killed.non_state;
        this.injured.state += element.Injured.state;
        this.injured.non_state += element.Injured.non_state;
        this.childRight.state += element.ChildRight.state;
        this.childRight.non_state += element.ChildRight.non_state;
        this.inhumanBehaviour.state += element.InhumanBehaviour.state;
        this.inhumanBehaviour.non_state += element.InhumanBehaviour.non_state;
        this.ESCRight.state += element.ESCRights.state;
        this.ESCRight.non_state += element.ESCRights.non_state;
        this.abduction.state += element.Abduction.state;
        this.abduction.non_state += element.Abduction.non_state;
        this.womenRight.state += element.WomenRights.state;
        this.womenRight.non_state += element.WomenRights.non_state;
        this.rightToAssembly.state += element.RightToAssembly.state;
        this.rightToAssembly.non_state += element.RightToAssembly.non_state;
        this.racialDiscrimination.state += element.RacialDiscrimination.state;
        this.racialDiscrimination.non_state +=
          element.RacialDiscrimination.non_state;
        this.beating.state += element.Beating.state;
        this.beating.non_state += element.Beating.non_state;
        this.torture.state += element.Torture.state;
        this.torture.non_state += element.Torture.non_state;
        this.threat.state += element.Threat.state;
        this.threat.non_state += element.Threat.non_state;

        this.report += element.report;
        this.perpetrator += element.perpetrator;
        this.victims += element.victims;
      });
      let child_data_key = this.child_rights_key;
      let child_data = this.child_rights_data;
      console.log('child_data',this.child_rights_data);
      console.log('child_data_key',this.child_rights_key);
      /* let new_data ={
           closed_case:'',
           compromise:'',
           incomplete:'',
           investigation:'',
           legal_proceeding:'',
           no_legal_proceeding:'',
       };*/
      let new_data = [];
      //let women_rights = [];
      let event_type_data = "";
      let closed_case_data = 0;
      let compromise_data = 0;
      let incomplete_data = 0;
      let investigation_data = 0;
      let legal_proceeding_data = 0;
      let no_legal_proceeding_data = 0;
      /* this.child_rights_data.forEach(function (d) {
          /!* test.forEach(function (k) {
               //d.child_rights[k]['event_name'] = k;
               //new_data.push(d.child_rights[k]);
           });*!/

     $i = $i + $i
       });*/
      //this.new_child_data = new_data;
       let that = this;
      child_data_key.forEach(function(k) {
        child_data.forEach(function(d) {
          event_type_data =  that.changeEventTypeData(k);
          closed_case_data += d.child_rights[k].closed_case;
          compromise_data += d.child_rights[k].compromise;
          incomplete_data += d.child_rights[k].incomplete;
          investigation_data += d.child_rights[k].investigation;
          legal_proceeding_data += d.child_rights[k].legal_proceeding;
          no_legal_proceeding_data += d.child_rights[k].no_legal_proceeding;
        });
        new_data.push({
          event_type_data: event_type_data,
          closed_case: closed_case_data,
          compromise: compromise_data,
          incomplete: incomplete_data,
          investigation: investigation_data,
          legal_proceeding: legal_proceeding_data,
          no_legal_proceeding: no_legal_proceeding_data,
        });
      });
      this.new_child_data = new_data;
      console.log('new_child_data',this.new_child_data);

      let w_data = this.women_rights_data;
      let w_key = this.women_rights_key;
      let new_wom_data = [];
      let w_event_type = "";
      let w_closed_case = 0;
      let w_compromise = 0;
      let w_incomplete = 0;
      let w_investigation = 0;
      let w_legal_proceeding = 0;
      let w_no_legal_proceeding = 0;

      w_key.forEach(function(k) {
        w_data.forEach(function(d) {
          w_event_type = that.changeEventTypeData(k);
          w_closed_case += d.women_rights[k].closed_case;
          w_compromise += d.women_rights[k].compromise;
          w_incomplete += d.women_rights[k].incomplete;
          w_investigation += d.women_rights[k].investigation;
          w_legal_proceeding += d.women_rights[k].legal_proceeding;
          w_no_legal_proceeding += d.women_rights[k].no_legal_proceeding;
        });
        new_wom_data.push({
          w_event: w_event_type,
          closed_case: w_closed_case,
          compromise: w_compromise,
          incomplete: w_incomplete,
          investigation: w_investigation,
          legal_proceeding: w_legal_proceeding,
          no_legal_proceeding: w_no_legal_proceeding,
        });
      });
      this.new_woman_data = new_wom_data;
      let k_data = this.killing_data;
      let k_data_key = this.killing_data_key;
      let k_event_type = "";
      let k_closed_case = 0;
      let k_compromise = 0;
      let k_incomplete = 0;
      let k_investigation = 0;
      let k_legal_proceeding = 0;
      let k_no_legal_proceeding = 0;
      let k_n_data = [];
      k_data_key.forEach(function(k) {
        k_data.forEach(function(d) {
          k_event_type = that.changeEventTypeData(k);
          k_closed_case += d.killing[k].closed_case;
          k_compromise += d.killing[k].compromise;
          k_incomplete += d.killing[k].incomplete;
          k_investigation += d.killing[k].investigation;
          k_legal_proceeding += d.killing[k].legal_proceeding;
          k_no_legal_proceeding += d.killing[k].no_legal_proceeding;
        });
        k_n_data.push({
          k_event_type: k_event_type,
          closed_case: k_closed_case,
          compromise: k_compromise,
          incomplete: k_incomplete,
          investigation: k_investigation,
          legal_proceeding: k_legal_proceeding,
          no_legal_proceeding: k_no_legal_proceeding,
        });
      });
      this.killing_new_data = k_n_data;
      // console.log("-------");
      // console.log(this.killing_new_data);
    });
    EventBus.$on("year", (data) => {
      this.time1 = data;
    });
  },
  updated() {},
  methods: {
    districtData() {
      // console.log("paas", data);
    },
      changeEventTypeData(data){
        //console.log('switch_data',data);
        switch (data){
            case 'child_trafficking':
                this.switch_e_data = 'Child Trafficking';
                break;
             case 'sexual_abuse':
                 this.switch_e_data = 'Sexual Abuse';
                 break;
            case 'domestic_violence':
                this.switch_e_data = 'Domestic Violence';
                break;
            case 'rape':
                this.switch_e_data = 'Rape';
                break;
            case 'rape_attempt':
                this.switch_e_data = 'Rape Attempt';
                break;
            case 'killing':
                this.switch_e_data = 'Killing';
                break;
            default:
                this.switch_e_data = data;

        }
        return this.switch_e_data
      },
    changeDate() {
      this.time1 = parseInt(this.time1);
      /* let date_d = this.time1.getFultlYear();
       this.time1 = date_d;*/
      // this.time1.value = date_d;
      this.$refs.checka.date_data = this.time1;
      this.$refs.checka.createSvg();
      this.loading;
    },
    /*udata(data){
      this.time1 = data;
      console.log(this.time1);
    }*/
  },
};
</script>

<style scoped>
.province-name {
  margin-left: 10px;
}
.province-title {
  margin-top: 3px;
  margin-left: 10px;
}
.container {
  padding: 10px;
  margin-top: 15px;
}

.title-section {
  padding-left: 10px;
}

/* map */
/* .map-content p {
  padding-left: 20px;
}

.map-content h2 {
  color: #00599a;
  padding-left: 20px;
} */

.circle {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-top: 5px;
  /* background-color:black; */
}

.state {
  background-color: #c3535f;
}

.others {
  background-color: #00599a;
}

.stats {
  display: flex;
  /* align-items:center; */
  justify-content: safe;
}

.stats p {
  margin-right: 10px;
  margin-left: 5px;
}

/* left content */
.mainContent-left {
  /* padding-top: 40px; */
  margin-top: 40px;
}

.infograph {
  margin-top: 10px;
  position: relative;
}

.info-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.infograph-content img {
  width: 100%;
}

.infograph-img {
  text-align: center;
}

.icon {
  text-align: center;
  margin-top: -8px;
}

.icons {
  text-align: center;
  margin-top: 56px;
  position: relative;
  top: 6px;
}

/* .infograph-img {
  display: Flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
} */

/* state items */
.infograph-item {
  height: 55px;
  width: 55px;
  background: #c3535f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  color: #fff;
}

.infograph-item div {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #00599a;
}

.img-top {
  position: relative;
  top: 13px;
  right: 10px;
}

.img-b {
  position: relative;
  top: -15px;
  left: 8px;
}

.stats-wrapper {
  display: flex;
}

.stats-wrapper-child {
  display: flex;
  align-items: center;
  justify-content: end;
  flex-flow: column;
}

.top {
  position: relative;
  left: 7px;
}

.buttom {
  position: relative;
  left: -3px;
}

.divider {
  background: #78c972 !important;
  position: absolute;
  height: 2px;
  margin: 0px;
  padding: 0px;
  top: 144px;
}

.connecter {
  height: 90px;
  background: #00599a;
  margin: 0px;
}

/* .stat-content {
  height: 55px;
  width: 213px;
  position: absolute;
  z-index: -10;
  border-top-right-radius: 30px;
  right: -64px;
  top: 11px;
  background-color: #dfdfdf;
} */

.province-stat {
  background-color: #dfdfdf;
  border-top-right-radius: 30px;
  position: relative;
  width:100%;
}

.province-stat-img {
  position: relative;
}

.province-stat-img img {
  position: absolute;
  top: -10px;
  left: -26px;
}

.total-report {
  text-align: center;
}

.table {
  width: 100%;
  overflow: auto;
}

.bottom-items {
  position: relative;
  left: 7px;
}
.scroll {
  position: absolute;
  right: 50%;
  left: 40%;
  top: 75%;
}
/* #mobile-connecter{
  height:1px;
} */
.mobile-infograph {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobo-connecter {
  height: 1px;
  width: 15%;
  background: #00599a;
  position: relative;
  left: -7px;
}
.mobo-infograph-item {
  position: relative;
  left: -7px;
}
.mobo-wrapper{
  position:relative;
}
.mobo-divider{
   background: #78c972 !important;
   position:absolute;
   height:100%;
   width:2px;
   left:50%;
   right:50%;

}
.stat-report{
  width:100%;
  background:#00599a;
  padding:5px 10px;
  border-radius:5px 10px;
  text-align:center;
  color:#fff;
}
/* mobile responsive */
  .mobile-stat-container {
    display: none;
  }
  .mobo-stat-report{
    display:none;
  }
/* mobile responsive */
@media only screen and (min-width: 985px) {
  /* .stat-total-report {
    display: none;
  } */
    .bottom-hr{
    margin-top: 60px;
  }
}
@media only screen and (min-width: 900px) {
  .mobo-stat-report{
    display:none;
  }
}
@media only screen and (max-width: 840px) {
  /* .stat-container {
    display: none !important;
  } */
  .province-name {
    text-align: center;
  }
  .province-title {
    text-align: center;
  }
  .province-date-select > .ant-select {
    text-align: Center;
  }
  .province-stat-img img {
    position: absolute;
    top: -10px;
    left: -20px;
  }
  .province-stat {
    margin-left: 10px;
  }
      .bottom-hr{
    margin-top: 80px;
  }

}
@media only screen and (max-width:500px){
  .stat-container{
    display:none !important;
  }
    .mobile-stat-container {
    display: inline-block;
  }
  .mobo-stat-report{
    display:inline-block;
  }
  /* .bottom-scroll{
    display:none;
  } */
    .stat-total-report{
    display:none;
  }
      .bottom-hr{
    margin-top: 80px;
  }
  

}
</style>
