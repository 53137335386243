import axios from 'axios';

export default {
    //Event Type
    fetcheventdata(event_type, group_by, countOf, start_date, end_date) {
        return axios.get(`https://hrv.insec.org.np/api/v1/event-data?event_type=${event_type}&groupBy=${group_by}&countOf=${countOf}&start_date=${start_date}&end_date=${end_date}`)
    },
    changedatepickerdata(event_type, group_by, countOf, start_date, end_date) {
        return axios.get(`https://hrv.insec.org.np/api/v1/event-data?event_type=${event_type}&groupBy=${group_by}&countOf=${countOf}&start_date=${start_date}&end_date=${end_date}`)
    },
    victimcountdata(event_type, group_by, countOf, start_date, end_date) {
        return axios.get(`https://hrv.insec.org.np/api/v1/event-data?event_type=${event_type}&groupBy=${group_by}&countOf=${countOf}&start_date=${start_date}&end_date=${end_date}`)
    },
    perpetratorcountdata(event_type, group_by, countOf, start_date, end_date) {
        return axios.get(`https://hrv.insec.org.np/api/v1/event-data?event_type=${event_type}&groupBy=${group_by}&countOf=${countOf}&start_date=${start_date}&end_date=${end_date}`)
    },


    ///Event Status
    fetcheventcomplete(event_type, group_by, countOf, start_date, end_date) {
        return axios.get(`https://hrv.insec.org.np/api/v1/event-complete-data?type=${event_type}&groupBy=${group_by}&countOf=${countOf}&start_date=${start_date}&end_date=${end_date}`)
    },
    changeeventstatusdate(event_type, group_by, countOf, start_date, end_date) {
        return axios.get(`https://hrv.insec.org.np/api/v1/event-complete-data?type=${event_type}&groupBy=${group_by}&countOf=${countOf}&start_date=${start_date}&end_date=${end_date}`)
    },
    fetchEsvictimcountdata(event_type, group_by, countOf, start_date, end_date) {
        return axios.get(`https://hrv.insec.org.np/api/v1/event-complete-data?type=${event_type}&groupBy=${group_by}&countOf=${countOf}&start_date=${start_date}&end_date=${end_date}`)
    },
    fetchEsperpetratorcountdata(event_type, group_by, countOf, start_date, end_date) {
        return axios.get(`https://hrv.insec.org.np/api/v1/event-complete-data?type=${event_type}&groupBy=${group_by}&countOf=${countOf}&start_date=${start_date}&end_date=${end_date}`)
    }

}