<template>
  <div>
    <a-modal
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      centerd
      width="700px"
    >
      <div class="container">
        <a-row :gutter="[16,16]">
          <a-col :xl="12" :lg="12" :md="12" :xs="24" :sm="24" style="text-align: center;">
            <div class="img">
              <img
                src="../../../public/books/2023_NP/zxc1.jpg"
                alt=""
                width="70%"
              />
            </div>
                   <a href="https://mycloud.cellapp.co/s/nc2BT5d8wNwFtG4">
 <a-button type="primary" block style="width:72% !important;margin:5px auto;">   
                  Download In English
                </a-button>
                  </a>
               
                   <a href="https://mycloud.cellapp.co/s/Nss53zJkXj55EEg">
                  
 <a-button type="primary" block style="width:72% !important; margin: auto;">
                 Download In Nepali
                </a-button>
                   </a>
          </a-col>
          <a-col :xl="12" :lg="12" :md="12" :xs="24" :sm="24" style="margin-top:20%">
              <h3 style="text-align:center;">
                  HRV year book 2023 is here
              </h3>
                <p style="text-align:center">
                    Get this book in your Email.
                </p>
                <Email />
               </a-col>
        </a-row>
       
      </div>
    </a-modal>
  </div>
</template>
<script>
import Email from '../EmailMe/Email.vue';
export default {
  data() {
    return {
      visible: true,
      confirmLoading: false,
    };
  },
  components:{
    Email,
    },
    created(){
       
       this.hideModal();
    },

  methods: {
    hideModal() {
      if(this.$cookies.get("modal-visibility")) {
        this.visible = false;
      } else {
        this.visible = true;
      }      
      
    },

    handleCancel() {
      this.visible = false;
      this.$cookies.set("modal-visibility", "sdfhdsyuf2sndhua73yugdf87ydfvsd879yugsdjh87yusd", "1d");
    },
  },
};
</script>

<style></style>
