<template>
  <div class="email">
    <!-- <h4>Drop Your Email to Receive HRV Year Book</h4> -->
    <a-form :form="form">
      <a-form-item>
        <a-input-search
          placeholder="Drop Your Email to Receive HRV Year Book"
          enter-button="Send Me Books"
          class="emailme"
          ref="email"
          @search="handleSubmit"
          v-decorator="[
            'email',
            {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ],
            },
          ]"
        />
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import ContactService from "../../services/contactForm";

export default {
  name: "emailus",
  data() {
    return {
      form: this.$form.createForm(this, { name: "coordinated" }),
    };
  },
  methods: {
    handleSubmit(value) {
      
      this.form.validateFields((err) => {
        this.$refs.email.value = '';
        if (!err) {
          ContactService.subscribeEmail({email: value}).then((res) => {
            if (res.status === 200) {
              this.$message.success("Thank you! Successfully Subscribed.");
            } else {
              this.$message.error(
                "Oops! Something went wrong! Please try again"
              );
            }
          });
        }
      });
      
    },
  },
};
</script>

<style>
.emailme .ant-btn-primary {
  width: 100% !important;
}
/* .emailme .ant-input-group > .ant-input:first-child{
  width:343px;
} */
</style>
