<template>
  <div class="aboutus">
    <!-- <div class="first-slide image">
      <div class="go-to-bottom">
        <a-icon type="down" style="color: aliceblue;" />
      </div>
    </div>
    <div class="second-slide image"></div>
    <div class="third-slide image"></div> -->

    <!-- New -->
    <!-- <main class="wrapper">
      <section class="first-slide">
      </section>
      <div class="sticky"></div>
      <section class="second-slide ">
      </section>
      <section class="third-slide">
      </section>
    </main> -->
    <!-- End New -->
     <!-- <div class="timeline">
      <a href="#first-step" v-smooth-scroll>
         <div class="box box1">
      <h3>PHASE 1</h3>
      </div>
      </a>
     <a href="#second-step" v-smooth-scroll>
          <div class="box box2">
        <h3>PHASE 2</h3>
      </div>
     </a>
     <a href="#third-step" v-smooth-scroll>
          <div class="box box3">
      <h3>PHASE 3</h3>
      </div>
     </a>
    </div> -->
     <!-- <section id="first-step" class="first-slide image">
        <div class="go-to-bottom">
        <a-icon type="down" style="color: aliceblue;" />
      </div>
    </section>
    <section id="second-step" class="second-slide image">
    </section>
    <section id="third-step" class="third-slide image">
    </section>
    -->
    <div class="sidebar">

      <ul class="menu" v-scroll-spy-active="{class: 'customActive'}" v-scroll-spy-link>
        <!-- <li :key="item" v-for="item in titles" class="menu-item">
          <a>{{item}}</a>
        </li> -->
        <li class="first scroll">
          <a >PHASE 1</a>
        </li>
          <li class="second scroll">
          <a >PHASE 2</a>
        </li>
          <li class="third scroll">
          <a >PHASE 3</a>
        </li>
      </ul>
    </div>

    <div class="main" v-scroll-spy="{data: 'section'}">
      <div id="first-step" class="first-slide image">
        <div class="go-to-bottom">
        <a-icon type="down" style="color: aliceblue;" />
      </div>
      </div>
      <div id="second-step" class="second-slide image">
       
      </div>
      <div id="third-step" class="third-slide image">
        
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data(){
    return{
           section: 0,
    }
  }
};
</script>

<style scoped>
  .customActive {
    background:#78c972 !important; 
    border-left: 5px solid #178ce6;
    padding-left: 5px;
    transition: all 0.5s;
  }
  .menu{
    position:fixed;
    left:-40px;
    top:40%;
  }
  .menu li{
    list-style: none;
   height:50px;
 width:100px;
 background:#008ddf;
 text-align:center;
 /* display:inline; */
  }
  .menu li a{
    color:#fff;
    position:relative;
    top:13px;
    padding:10px;
  }
.scroll{
  margin-bottom:10px;
}
.aboutus {
  padding: 0px;
}
.aboutus p {
  text-align: justify;
}

.go-to-bottom {
  position: absolute;
  bottom: 5%;
  left: 50%;
  width: 40px;
  height: 40px;
  font-size: 40px;
  line-height: 40px;
  animation: bounce 2s infinite ease-in-out;
  -webkit-animation: bounce 2s infinite ease-in-out;
}

@keyframes bounce {
  0%,
  20%,
  60%,
  100% {
    -ms-transform: translateY(0);
  }
  0%,
  20%,
  60%,
  100% {
    -o-transform: translateY(0);
  }
  0%,
  20%,
  60%,
  100% {
    -moz-transform: translateY(0);
  }
  0%,
  20%,
  60%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-20px);
  }
  80% {
    -webkit-transform: translateY(-10px);
  }
}

.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  /* height: calc(100vh - 90px); */
  height:100vh;
  background-position: center center;
}
.first-slide {
  background-image: url("../../../public/icons/about-us/Slider_Insec-01.png");
  background-position-y: top;
}
.second-slide {
  background-image: url("../../../public/icons/about-us/Slider_Insec-02.png");
}
.third-slide {
  background-image: url("../../../public/icons/about-us/Slider_Insec-03.png");
  margin-bottom: -38px;
}

@media screen and (max-width: 1024px) {
  .first-slide, .second-slide, .third-slide {
    background-attachment: scroll;
    background-position: center center;
  }

  .image {
    background-size: contain;
    height: 50vh;
  }
}
.box{
 height:50px;
 width:100px;
 background:#008ddf;
 position:fixed;
 text-align: center;

}
.box h3{
  color:#fff;
  margin-top:12px;
}
.box1{
  top:240px;
}
.box2{
  top:300px;
}
.box3{
  top:360px
}

</style>