<template>
  <div id="app">
    <Header />
    <div class="main-body">
      <router-view></router-view>
    </div>
    <!-- <VueBotUI
      :messages="messages"
      :options="botOptions"
      @msg-send="messageSendHandler"
      class="chatbot"
    /> -->
    <footer>
      <a-row style="margin-bottom: 0">
        <a-col
          class="footer-content"
          :xs="{ span: 20, offset: 1 }"
          :sm="{ span: 20, offset: 1 }"
          :md="{ span: 20, offset: 1 }"
          :lg="{ span: 5, offset: 10 }"
        >
          <h1>
            Powered by <a href="https://cellapp.co/" target="_blank">Cellapp</a>
          </h1>
        </a-col>
        <a-col class="mobo-footer" :span="24">
          <h1>
            Powered by <a href="https://cellapp.co/" target="_blank">Cellapp</a>
          </h1>
        </a-col>
      </a-row>
    </footer>
  </div>
</template>

<script>
//import landingPage from './components/landingPage.vue'
//import Slider from './components/Landingpage/Slider'
import Header from "./components/Dashboard/Header";
// import { VueBotUI } from "vue-bot-ui";
const messages = [
  {
    agent: "bot", // Required. 'bot' or 'user'
    type: "text", // Required. Bubble message component type: 'text' / 'button'
    text: "Hello. How can I help you ", // Required. The message
    disableInput: false, // Disable message input or not
  }
];
export default {
  name: "App",
  components: {
    //landingPage
    //Slider
    Header,
    // VueBotUI,
  },
  data() {
    return {
      messages,
      data: [], // See Data example below
      // botOptions: {
      //   botTitle: "INSEC",
      // },
    };
  },
  methods: {
    messageSendHandler(msg) {
      messages.push({
        agent: "user",
        type: "text", // always
        text: msg.text,
      });
    },
  },
};
</script>

<style scoped>
.main-body {
  min-height: calc(100vh - 147px);
}
.ant-table-thead > tr > th {
  background-color: #00599a !important;
  color: #fff !important;
}
.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 16px !important;
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 16px !important;
}
.ant-btn-primary {
  background-color: #00599a !important;
  border-color: #00599a !important;
}
.navigation-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-layout-header {
  padding: 0;
}
.ant-menu-horizontal > .ant-menu-item-selected > a,
.ant-menu-horizontal > .ant-menu-item > a:hover {
  color: #00599a;
}

/* footer */
footer {
  width: 100%;
  /* background:#008ddf; */
  position: absolute;
  left: 0px;
}
.footer-content {
  padding-top: 5px;
  text-align: Center;
  font-size: 0.5rem;
  background: #008ddf;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.footer-content h1 {
  color: #fff;
}
.footer-content h1 a {
  color: #fff;
}
.footer-content h1 a:hover,
.footer-content h1 a:active {
  color: #15dddd;
}
.mobo-footer {
  display: none;
}
/* for responsive */
@media only screen and (max-width: 870px) {
  .footer-content {
    display: none;
  }
  .mobo-footer {
    display: block;
    background: #008ddf;
    text-align: center;
  }
  .mobo-footer h1 {
    font-size: 1rem;
    color: #fff;
  }
  .mobo-footer h1 a {
    color: #fff;
  }
}
/* @media only screen and (max-width :400px){
  .chatbot{
    display:none;
  }
}
.chatbot{
  display:none;
} */

</style>
