<template>
  <div>
    <!--  <a-row>
          <a-col :span="5">
              <a-select class="article-select" style="margin-left: 32px" v-model="v_e_type_data" @change="changeGraph" default-value="">
                  <a-select-option value="">Select Graph</a-select-option>
                  <a-select-option v-for="(d,i) in victim_event_type_dropdown_data" :key="i"  :value="d.VictimEventType">
                      {{d.VictimEventType}}
                  </a-select-option>
              </a-select>
          </a-col>
      </a-row>-->
   <!-- <a-row style="padding:20px; 40px;">
      &lt;!&ndash;<a-col :xl="15" :lg="15" :md="24" :sm="24" :xs="24">
        <div id="chart">
         &lt;!&ndash; <apexchart
            type="line"
            height="500"
            :options="chartOptions"
            :series="series"
          ></apexchart>&ndash;&gt;
        </div>
      </a-col>&ndash;&gt;
      <a-col :xl="7" :lg="7" :md="24" :sm="24" :xs="24" class="axis-row">
        <a-row>
          <a-col :span="6">
            <h3>Plot Y-Axis</h3>
          </a-col>
          <a-col :span="12">
          &lt;!&ndash;  <a-button type="primary" @click="clearData">Clear</a-button>&ndash;&gt;
          </a-col>
        </a-row>
        <a-row>
          <a-select
            class="article-select"
            :default-value="default_data"
            v-model="v_e_type_data"
          >
           &lt;!&ndash; @change="changeGraph"&ndash;&gt;
            <a-select-option value="">Select</a-select-option>
            <a-select-option
              v-for="(d, i) in victim_event_type_dropdown_data"
              :key="i"
              :value="d.VictimEventType"
            >
              {{ d.VictimEventType }}
            </a-select-option>
          </a-select>
        </a-row>

        <div class="description">
          <p>{{ description }}</p>
        </div>
      </a-col>
    </a-row>-->
    <a-row>
      <!-- title -->
      <div class="title-wrapper">
         <a-col :span="20" :offset="1">
        <h2>{{article_title}}</h2>
      </a-col>
      </div>
      <!-- description -->
      <div class="discription-wrapper">
        <a-col :span="22" :offset="1" >
          <p>
          {{article_desc}}
          </p>
        </a-col>
      </div>
     <!-- download button -->
      <a-col  :xl="{span:20,offset:1}" :lg="{span:20,offset:1}" :md="{span:23,offset:1}" :sm="{span:23,offset:1}" :xs="{span:23,offset:1}">
        <a-button
          type="primary"
          icon="download"
          @click="showModal"
          class="pivot"
        >
          Download Table
        </a-button>
      <!--  <a-button @click="gettabledata">test</a-button>-->
      </a-col>
    </a-row>
    <a-row>
      
      <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
        <div style="overflow: auto" class="dataset-table-wrapper">
          <!--<h3>Pivottable Demo</h3>-->
          <vue_pivottable_ui
            :data="pivotData"
            :aggregator-name="aggregatorName"
            :renderer-name="rendererName"
            :rows="rows"
            :cols="cols"
            :vals="vals"
            :row-total="true"
            :col-total="true"
            id="pivotview"
            ref="table"
          >
          </vue_pivottable_ui>
        </div>
      </a-col>
     
    </a-row>
    <a-row>
    </a-row>
    <!--   <a-button id="export-btn"  v-on:click="btnClick">Excel Export</a-button>-->
    <div class="overlay" v-if="overlay_visible">
      <!-- <a-spin size="large" /> -->
      <div class="datasetLoader"></div>
    </div>
 <!--   <a-modal
      :visible="downloadVisible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form
        :form="form"
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        @submit="handleSubmit"
      >
        <a-form-item label="Name">
          <a-input
            v-decorator="[
              'Name',
              { rules: [{ required: true, message: ' name !' }] },
            ]"
          />
        </a-form-item>
        <a-form-item label="Organization Name">
          <a-input
            v-decorator="[
              'Organization_Name',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please input your organization name !',
                  },
                ],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="E-mail">
          <a-input
            v-decorator="[
              'Email',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item  :xl="{span:4, offset:19}" :lg="{span: 4, offset:19}" :md="24" :sm="24" :xs="24">
          <a-button type="primary" html-type="submit"> Submit </a-button>
        </a-form-item>
      </a-form>
    </a-modal>-->
  </div>
</template>

<script>
import article from "../../services/datasets";
/*import VueApexCharts from "vue-apexcharts";*/
import { /*VuePivottable,*/ VuePivottableUi } from "vue-pivottable";
import ARow from "ant-design-vue/es/grid/Row";
import downloadPost from "../../services/downloadForm";

export default {
  components: {
    ARow,
    vue_pivottable_ui: VuePivottableUi,
    //apexchart: VueApexCharts,
  },
  created() {
    this.fetchArticleData();
    this.fetchDatasetsData();
  },
  data() {
    return {
      downloadVisible: false,
      uri: "data:application/vnd.ms-excel;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64: function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format: function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      },

      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      overlay_visible: true,
      description: "",
      local_data: [],
      article_data: [],
      article_title:[],
      article_desc:[],
      rows_data: [],
      article_header: "",
      victim_event_type_dropdown_data: [],
      default_data: "",
      v_e_type_data: "",
      row_data_one: "",
      columns_data: [],
      columns_data_one: [],
      pivotData: [],
      key_data: [],
      aggregatorName: "Count",
      rendererName: "Table Heatmap",
      rows: [],
      cols: [],
      vals: [],
      disabledFromDragDrop: ["Party Size", "Payer Gender"],
      hiddenFromDragDrop: ["Total Bill"],
      series: [],
      chartOptions: {
        chart: {
          height: 500,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },

        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [
            "Bagmati Province",
            "Gandaki Province",
            "Karnali Province",
            "Lumbini Province",
            "Province 1",
            "Province 2",
            "Sudur Paschim Province",
          ],
        },
      },
    };
  },
  methods: {
    // download modal button
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(values);
          this.email = values.Email;
          this.orgName = values.Organization_Name;
          this.name = values.Name;
          let formData = new FormData();
          formData.append("name", this.name);
          formData.append("email", this.email);
          formData.append("org_name", this.orgName);
          downloadPost.postData(formData).then((res) => {
            // console.log(res.data);

            if (res.data.success === true) {
              this.tableToExcel("table", "Lorem Table");
              this.downloadVisible = false;
            }
          });
        }
      });
    },
    showModal() {
      if(this.$cookies.get("downloadExcel") == null){
        this.downloadVisible = true;
        // console.log("there is no cookie");
        this.$cookies.set("downloadExcel","fjdklsajfkdls","7d");
      }
      else{
        this.tableToExcel("table", "Lorem Table");
        // console.log("there is cookie");
      }
    },
    handleCancel() {
      // console.log("Clicked cancel button");
      this.downloadVisible = false;
    },
    tableToExcel(table, name) {
      if (!table.nodeType) table = document.getElementsByClassName("pvtTable");
      var ctx = { worksheet: name || "Worksheet", table: table[0].innerHTML };
      window.location.href =
        this.uri + this.base64(this.format(this.template, ctx));
    },
    fetchArticleData() {
      let route_id = this.$route.params.id;
      article.fetchArticleDatasets(route_id).then((res) => {
        this.article_data = res.data.data;
        this.rows = res.data.data.rows;
        this.cols = res.data.data.columns;
        // console.log('rows',this.rows);
        // console.log('cols',this.cols);


          let p_data = [];
          this.rows.forEach(function (data) {
              p_data.push(data);
          });
          this.cols.forEach(function (data) {
              p_data.push(data);
          });
          this.pivotData[0] = p_data;
          // console.log('p_data',this.pivotData);

          this.article_title = res.data.data.title;
          this.article_desc = res.data.data.description

        //this.columns_data_one = this.columns_data[0];
       /* let title = res.data.data.title;
        this.chartOptions = {
          title: {
            text: title,
            style: {
              fontSize: "20px",
            },
          },
        };
        this.description = res.data.data.description;*/
      });
    },
   async fetchDatasetsData(){
     let data = "victim";
     await  article.fetchDatasetCount(data).then((res)=>{
         this.overlay_visible = false;

        let api_data = res.data.data;
        let n_data = this.pivotData[0];
        // console.log('n_data',n_data);
        let new_pivot_data = [];
        api_data.forEach(function (data) {
            let c  =[];
           n_data.forEach(function (n) {
               c.push(data[n]);
           });
            new_pivot_data.push(c);
        });
        new_pivot_data.unshift(n_data);
        // console.log('data',new_pivot_data);
        this.pivotData = new_pivot_data;





     });
   },
  /*  async fetchDatasetsData() {
      let data = "victim";
      await article.fetchDatasetCount(data).then((res) => {
        this.overlay_visible = false;
        this.rows = [];
        this.cols = [];
        this.pivotData = [];
        console.log(this.pivotData);
        //this.pivotData.push([this.row_data_one]);
        //this.pivotData[0].push(this.columns_data_one);


        this.key_data = res.data.data;

        //For rows data
        let i = 1;
        let row_data_test = this.pivotData;
        let r = this.row_data_one;
        this.key_data.forEach(function (data) {
          row_data_test[i] = [data[r]];
          i++;
        });
        //For columns data
        let j = 1;
        let c = this.columns_data_one;
        this.key_data.forEach(function (data) {
          row_data_test[j].push(data[c]);
          j++;
        });
        this.pivotData = [];
        this.pivotData = row_data_test;
        this.rows = this.columns_data; // this.columns_data
        this.cols = this.rows_data;

        //Dropdown
        let all_data = this.pivotData;
        let drop_victim_event_type = [];
        all_data.forEach(function (d) {
          let i = drop_victim_event_type.findIndex(
            (x) => x.VictimEventType == d[1]
          );
          if (i <= -1) {
            drop_victim_event_type.push({ VictimEventType: d[1] });
          }
        });
        this.victim_event_type_dropdown_data = drop_victim_event_type;
        this.victim_event_type_dropdown_data.shift();
        this.default_data = this.victim_event_type_dropdown_data[0].VictimEventType;

        let t = this.pivotData;
        let vt = [];
        this.victim_event_type_dropdown_data.forEach(function (data) {
          t.forEach(function (pd) {
            pd.includes(data.VictimEventType) ? vt.push(pd) : "";
          });
        });

        let nd = [];
        let pro = [
          "Bagmati Province",
          "Gandaki Province",
          "Karnali Province",
          "Lumbini Province",
          "Province 1",
          "Province 2",
          "Sudur Paschim Province",
        ];

        let H = 0;
        this.victim_event_type_dropdown_data.forEach(function (v) {
          vt.forEach(function (data) {
            pro.forEach(function (p) {
              data.includes(v.VictimEventType) && data.includes(p)
                ? nd.push({ type: v, prov: p, count: H++ })
                : "";
            });
            H++;
          });
        });

        let v_data = this.default_data;
        this.v_e_type_data = v_data;
        let pd = this.pivotData;
        let a = [];
        pd.forEach(function (data) {
          data.includes(v_data) ? a.push(data) : "";
        });
        let bagmati_data = 0;
        let gandaki_data = 0;
        let karnali_data = 0;
        let lumbini_data = 0;
        let province_1_data = 0;
        let province_2_data = 0;
        let sudur_data = 0;

        a.forEach(function (d) {
          d.includes("Bagmati Province") ? bagmati_data++ : "";
          d.includes("Gandaki Province") ? gandaki_data++ : "";
          d.includes("Karnali Province") ? karnali_data++ : "";
          d.includes("Lumbini Province") ? lumbini_data++ : "";
          d.includes("Province 1") ? province_1_data++ : "";
          d.includes("Province 2") ? province_2_data++ : "";
          d.includes("Sudur Paschim Province") ? sudur_data++ : "";
          /!* if(d.includes("Bagmati Province")){
               pro_data.push(d)
           }*!/
        });

        let pro_data = [
          bagmati_data,
          gandaki_data,
          karnali_data,
          lumbini_data,
          province_1_data,
          province_2_data,
          sudur_data,
        ];
        this.series.push({
          name: v_data,
          data: pro_data,
        });

        /!*  this.key_data = res.data.data;
          let test = this.pivotData;
          console.log(test);
          let cols = this.columns_data_one;

         let i= 2;
          this.key_data.forEach(function (data) {
              test[i] = [data[cols]];
              i++;
              /!*test.push(data[cols]);*!/
          });
         this.pivotData = [];
         this.pivotData = test;
         console.log(this.pivotData);*!/
        /!*this.pivotData.push(test);
        console.log(this.pivotData);*!/
        /!*   this.pivotData.push(Object.keys(res.data.data[0]));
             this.pivotData.push([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,17,18,19,20,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,17,18,19,20,21,22]);*!/
      });
      /!* let test = [];

         this.key_data.forEach(function(entry) {
                test = entry
         });
         for (const [key, value] of Object.entries(test)) {
             console.log(`${key}: ${value}`);
         }*!/
    },*/
    /*changeGraph() {
      let v_data = this.v_e_type_data;
      /!*  this.chartOptions = {
            title: {
                text: 'hola',
            },
        };*!/
      let pd = this.pivotData;
      let a = [];
      pd.forEach(function (data) {
        data.includes(v_data) ? a.push(data) : "";
      });
      let bagmati_data = 0;
      let gandaki_data = 0;
      let karnali_data = 0;
      let lumbini_data = 0;
      let province_1_data = 0;
      let province_2_data = 0;
      let sudur_data = 0;

      a.forEach(function (d) {
        d.includes("Bagmati Province") ? bagmati_data++ : "";
        d.includes("Gandaki Province") ? gandaki_data++ : "";
        d.includes("Karnali Province") ? karnali_data++ : "";
        d.includes("Lumbini Province") ? lumbini_data++ : "";
        d.includes("Province 1") ? province_1_data++ : "";
        d.includes("Province 2") ? province_2_data++ : "";
        d.includes("Sudur Paschim Province") ? sudur_data++ : "";
        /!* if(d.includes("Bagmati Province")){
             pro_data.push(d)
         }*!/
      });

      let pro_data = [
        bagmati_data,
        gandaki_data,
        karnali_data,
        lumbini_data,
        province_1_data,
        province_2_data,
        sudur_data,
      ];
      this.series.push({
        name: v_data,
        data: pro_data,
      });
    },*/
     /* gettabledata(){
        let array = [];
         this.victim_event_type_dropdown_data.forEach(function (data) {
             array.push({
                 title:data.VictimEventType
             })
         });
          console.log(array);
      },*/
    /*btnClick: function () {
      const PivotData = PivotUtilities.PivotData;
    },*/

    /*clearData() {
      this.series = [];
      this.default_data = "";
      this.v_e_type_data = "";
    },*/
  },
};
</script>

<style>
/*@import "../../assets/css/test.css";*/
.pvtVertList,
.pvtHorizList {
  display: none !important;
}

.pvtDropdown:last-child {
  display: none !important;
}

.pvtDropdown {
  display: none !important;
}

.pvtVals {
  display: none !important;
}

.pvtUi {
  margin: 32px !important;
  width: 82% !important;
}

.article-select .ant-select-selection--single {
  width: 100%;
}

.pivot {
  width: 200px !important;
  margin-top: 10px;
}

.description p {
  margin-top: 30px;
  text-align: justify;
}
.axis-row {
  margin-left: 32px;
}
@media only screen and (max-width: 985px) {
  .description p {
    margin-left: 0px;
  }
  .axis-row {
    margin-left: 0px;
  }
  .pivot {
    width: 95% !important;
    margin: 0px;
  }
}

/* table{
     padding
 }*/

.overlay {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay > .ant-spin-spinning {
  position: fixed !important;
  top: 50% !important;
}

.datasetLoader {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #feab56;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: datasetLoader 1s ease-in-out infinite;
  animation: datasetLoader 1s ease-in-out infinite;
}

.datasetLoader:before {
  content: "";
  position: absolute;
  background-color: #c3535f;
  top: 0px;
  left: -25px;
  height: 12px;
  width: 12px;
  border-radius: 12px;
}

.datasetLoader:after {
  content: "";
  position: absolute;
  background-color: #78c972;
  top: 0px;
  left: 25px;
  height: 12px;
  width: 12px;
  border-radius: 12px;
}

@-webkit-keyframes datasetLoader {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
  }
}

@keyframes datasetLoader {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
.dataset-table-wrapper{
  padding:20px;
  width:97%;
  margin-left:10px;
}
.pvtUi{
  margin:0px !important;
  padding:0px !important;
}
.pvtUi td.pvtOutput{
  padding:0px !important;
}
.title-wrapper{
  margin-top:10px;
}
.discription-wrapper{
  text-align: justify;
}
</style>