<template>
  <div>
    <div style="margin-top: 25px">
      <div class="all-card-wrapper">
        <div class="event-title">
          <h2 style="color: #00599a; font-weight: 500; text-align: center">
            Explore by Event Type
          </h2>
        </div>
        <!-- dynamic icons -->
        <a-row>
            <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24" v-for="(icons,index) in fetchedIcon" :key="index">
            <div class="test">
              <a-card class="module-wrapper">
                <img
                  :src="imgUrl + icons.event_type + svg"
                  :alt="icons.event_type"
                  width="50"
                  height="50"
                  @click="changebtnEventData(icons.event_type.toLowerCase(), icons.event_type)"
                />
              </a-card>
             <!--  src="../../assets/Threats.svg"-->
              <!--     :src="imgUrl + icons.event_type + svg"-->
             <!-- :src="getImgUrl(icons.event_type)"-->
              <p>{{icons.event_type}}</p>
            </div>
          </a-col>
        </a-row>
        <!-- static icons -->
       <!-- <a-row>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <a-card class="module-wrapper">
                <img
                  src="../../../public/icons/Dashboard/Abduction.svg"
                  alt="Adbuction"
                  width="50"
                  height="50"
                  @click="changebtnEventData('abduction', 'Abduction')"
                />
              </a-card>
              <p>Abduction</p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Dashboard/Icon__Arrests.svg"
                alt="Arrests"
                width="50"
                height="50"
                @click="changebtnEventData('arrest', 'Arrests')"
              />
              <p>Arrests</p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Dashboard/Icon__Beating.svg"
                alt="beating"
                width="50"
                height="50"
                @click="changebtnEventData('beatings', 'Beatings')"
              />
              &lt;!&ndash; <router-link to=""></router-link> &ndash;&gt;
              <p>Beating</p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Dashboard/Icon__Child_right.svg"
                alt="Child Rights"
                width="50"
                height="50"
                @click="changebtnEventData('child rights', 'Child Rights')"
              />
              <p>Child Rights</p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Dashboard/Icon__Discrimination.svg"
                alt="Discrimination"
                width="50"
                height="50"
                @click="
                  changebtnEventData(
                    'Racial Discrimination',
                    'Racial Discrimination'
                  )
                "
              />
              <p>Discrimination</p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Dashboard/Women Rights.svg"
                alt="Women Rights"
                width="50"
                height="50"
                @click="changebtnEventData('Women Rights', 'Women Rights')"
              />
              <p>Women Rights</p>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <a-card class="module-wrapper">
                <img
                  src="../../../public/icons/Event/Icon_Death_in_Detention.svg"
                  alt="Death Detention"
                  width="50"
                  height="50"
                  @click="
                    changebtnEventData(
                      'Death in Detention',
                      'Death in Detention'
                    )
                  "
                />
              </a-card>
              <p style="text-align: center">Death in Detention</p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Event/Icon_Death_in_Jail.svg"
                alt="Death Jail"
                width="50"
                height="50"
                @click="changebtnEventData('Death in Jail', 'Death in Jail')"
              />
              <p>Death in Jail</p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Event/Icon_ESC_rights.svg"
                alt="Rights"
                width="50"
                height="50"
                @click="
                  changebtnEventData(
                    'Economic,Social %26 Cultural Rights',
                    'Economic,Social & Cultural Rights'
                  )
                "
              />
              <p style="text-align: center">
                Economic,Social & Cultural Rights
              </p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Event/Icon_Inhuman_Behaviour.svg"
                alt="Inhuman Behaviour"
                width="50"
                height="50"
                @click="
                  changebtnEventData('Inhuman Behaviour', 'Inhuman Behaviour')
                "
              />
              <p style="text-align: center">Inhuman Behaviour</p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Event/Icon_Injured.svg"
                alt="Injured"
                width="50"
                height="50"
                @click="changebtnEventData('Injured', 'Injured')"
              />
              <p>Injured</p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <a-card class="module-wrapper">
                <img
                  src="../../../public/icons/Event/Icon_Killing.svg"
                  alt="Killing"
                  width="50"
                  height="50"
                  @click="changebtnEventData('Killing', 'Killing')"
                />
              </a-card>
              <p>Killing</p>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Event/Icon_Racial_Discrimination.svg"
                alt="Racial Discrimination"
                width="50"
                height="50"
                @click="
                  changebtnEventData(
                    'Racial Discrimination',
                    'Racial Discrimination'
                  )
                "
              />
              <p style="text-align: center">Racial Discrimination</p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Event/Icon_Right_to_Assembly.svg"
                alt="Right to Assembly"
                width="50"
                height="50"
                @click="
                  changebtnEventData('Right to Assembly', 'Right to Assembly')
                "
              />
              <p style="text-align: center">Right to Assembly</p>
            </div>
          </a-col>
          <a-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
            <div class="test">
              <img
                src="../../../public/icons/Event/Icon_Threats.svg"
                alt="Threats"
                width="50"
                height="50"
                @click="changebtnEventData('Threats', 'Threats')"
              />
              <p>Threats</p>
            </div>
          </a-col>
        </a-row>-->
      </div>
      <a-row style="padding: 20px 40px">
        <div style="text-align: center">
          <h2 style="color: #00599a; font-weight: 500">
            {{ report_head }} Datasheet
          </h2>
          <p>This data sheet contains the {{ report_head }} Data Report</p>
        </div>
        <!-- quick stat -->
        <!-- <a-col style="text-align: center" :offset="9">
          <a-col :span="3">
            <p>Total Victim Type</p>
            <span>15</span>
          </a-col>
          <a-col :span="3">
            <p>Total Victims</p>
            <span>{{victim_dynamic_data}}</span>
          </a-col>
          <a-col :span="3">
            <p>Total Perpetrator</p>
            <span>{{perperator_dynamic_data}}</span>
          </a-col>
        </a-col> -->
        <a-row>
          <a-col
            :xl="{ span: 4, offset: 5 }"
            :lg="{ span: 4, offset: 5 }"
            :md="24"
            :xs="24"
            :sm="24"
          >
            <div class="total-victim stat">Event Types : 20</div>
          </a-col>
          <a-col
            :xl="{ span: 4, offset: 1 }"
            :lg="{ span: 4, offset: 1 }"
            :md="24"
            :xs="24"
            :sm="24"
          >
            <div class="total-victim stat">
              Total Victims : {{ victim_dynamic_data }}
            </div>
          </a-col>
          <a-col
            :xl="{ span: 4, offset: 1 }"
            :lg="{ span: 4, offset: 1 }"
            :md="24"
            :xs="24"
            :sm="24"
          >
            <div class="total-victim stat">
              Total Perpetrator : {{ perperator_dynamic_data }}
            </div>
          </a-col>
        </a-row>
        <a-col :xl="19" :lg="19" :md="24" :sm="24" :xs="24">
          <a-tabs default-active-key="1" @change="callback">
            <a-tab-pane key="1" tab="Table">
              <div class="d-tabled-table">
                <a-table :columns="columns" :data-source="data">
                  <span slot="sn" slot-scope="text, record">
                    {{ record.key }}
                  </span>
                </a-table>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Graph" force-render>
              <apexchart
                type="line"
                style=""
                height="500"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </a-tab-pane>
          </a-tabs>
        </a-col>
        <a-col
          :xl="{ span: 4, offset: 1 }"
          :lg="{ span: 4, offset: 1 }"
          :md="24"
          :sm="24"
          :xs="24"
          style="margin-top: 7px"
        >
          <h3>Filter</h3>
          <a-col
            :xl="24"
            :lg="24"
            :md="24"
            :sm="24"
            :xs="24"
            class="options"
            v-if="tab_key == 1"
          >
            <a-input-search
              placeholder="Search..."
              class="search-btn"
              enter-button
              @search="onSearch"
            />
          </a-col>
          <a-col
            :xl="24"
            :lg="24"
            :md="24"
            :sm="24"
            :xs="24"
            class="options"
            v-if="tab_key == 1"
          >
            <label for="date_range">Pick Date Range</label>
            <a-date-picker
              v-model="start_date"
              format="YYYY-MM-DD"
              placeholder="Start"
            />
          </a-col>
          <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24" class="options">
            <a-date-picker
              format="YYYY-MM-DD"
              v-model="end_date"
            ></a-date-picker>
          </a-col>
          <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24" class="options">
            <label for="select">Select</label>
            <a-select @change="changeeventapidata" v-model="group_by">
              <a-select-option value="vdc"> Local Level </a-select-option>
              <a-select-option value="district"> District </a-select-option>
              <a-select-option value="province_id"> Province </a-select-option>
            </a-select>
          </a-col>
          <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24" class="options">
            <label for="count">Count Of</label>
            <a-select v-model="countOf" @change="changecountofData(countOf)">
              <a-select-option value="victim"> Victim </a-select-option>
              <a-select-option value="perpetrator">
                Perpetrator
              </a-select-option>
            </a-select>
          </a-col>
          <a-col
            :xl="24"
            :lg="24"
            :md="24"
            :sm="24"
            :xs="24"
            class="options"
            v-if="tab_key == 2"
          >
            <label for="gender">Select Gender</label>
            <a-select
              v-model="graph_change"
              @change="changeGraphData(graph_change)"
              default-value="male_count"
            >
              <a-select-option value="male_count"> Male </a-select-option>
              <a-select-option value="female_count"> Female </a-select-option>
              <a-select-option value="others_count"> Others </a-select-option>
              <a-select-option value="total_count">
                Total Count
              </a-select-option>
            </a-select>
          </a-col>
          <a-col
            :xl="24"
            :lg="24"
            :md="24"
            :sm="24"
            :xs="24"
            class="options"
            v-if="tab_key == 1"
          >
            <label for="download">Download Excel Report</label>
            <a-button style="width: 100% !important" @click="showModal"
              ><a-icon type="download"></a-icon>
            </a-button>
            <download-excel
              class="ant-btn"
              style="visibility: hidden"
              id="test"
              :data="data"
              :fields="json_fields"
              worksheet="My Worksheet"
              name="filename.xls"
            >
              <a-icon type="download"></a-icon>
            </download-excel>
          </a-col>
        </a-col>
      </a-row>
    </div>
    <div class="overlay" v-if="overlay_visible">
      <!-- <a-spin size="large" /> -->
      <div class="loaderOnClick"></div>
    </div>
    <!-- download modal -->
    <a-modal
      :visible="downloadVisible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form
        :form="form"
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        @submit="handleSubmit"
      >

          <a-form-item label="Name">
      <a-input
        v-decorator="['Name', { rules: [{ required: true, message: ' name !' }] }]"
         />
       </a-form-item>

       <a-form-item label="Organization Name">
      <a-input
        v-decorator="['Organization_Name', { rules: [{ required: true, message: 'Please input your organization name !' }] }]"
         />
       </a-form-item>

       <a-form-item  label="E-mail">
      <a-input
        v-decorator="[
          'Email',
          {
            rules: [
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ],
          },
        ]"
        />
    </a-form-item>
      <a-form-item   :lg="{span: 4, offset:19}" :md="24" :sm="24" :xs="24">
      <a-button type="primary" html-type="submit">
        Submit
      </a-button>
    </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import ARow from "ant-design-vue/es/grid/Row";
import event from "/src/services/event";
import downloadPost from "../../services/downloadForm";
import ACol from "ant-design-vue/es/grid/Col";
import VueApexCharts from "vue-apexcharts";
import JsonExcel from "vue-json-excel";
import eventTypes from "../../services/eventType";


const columns = [
  {
    title: "S.No.",
    dataIndex: "sn",
    key: "sn",
    scopedSlots: { customRender: "sn" },
  },
  {
    title: "District",
    dataIndex: "district",
    key: "district",
  },
  {
    title: "Report",
    dataIndex: "report_count",
    key: "report_count",
  },
  {
    title: "No.of Male",
    dataIndex: "male_count",
    key: "male_count",
  },
  {
    title: "No.of Female",
    dataIndex: "female_count",
    key: "female_count",
  },
  {
    title: "Others",
    dataIndex: "others_count",
    key: "others_count",
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    scopedSlots: { customRender: "total" },
  },
];
const data = [];
export default {
  created() {
    this.fetcheventapidata();
    this.fetchVictimAndPerpetratorData();
    this.fetchIconName();

  },
  components: {
    ACol,
    ARow,
    downloadExcel: JsonExcel,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      iconName:[],
      fetchedIcon:[],
        imgUrl:"/svg_icons/",
        // imgUrl:"../../assets/svg_icons/",x
        // imgUrl:"../../../public/svg_icons/",
        svg:'.svg',
      formLayout: 'horizontal',
        form: this.$form.createForm(this, { name: 'coordinated' }),
      downloadVisible: false,
      confirmLoading: false,
      overlay_visible: false,
      data,
      columns,
      report_head: "Abduction",
      event_data: "",
      event_type: "abduction",
      group_by: "district",
      countOf: "victim",
      filter: "",
      check: 1,
      key_columns: "",
      json_fields: "",
      table_data: 1,
      map_data: 0,
      graph_data: 0,
      x_axis_data: [],
      y_axis_data: [],
      graph_change: "Please Select",
      tab_key: "1",
      calendar_year: [
        "2020",
        "2019",
        "2018",
        "2017",
        "2016",
        "2015",
        "2014",
        "2013",
        "2012",
        "2011",
        "2010",
        "2009",
        "2008",
        "2007",
        "2006",
        "2005",
        "2004",
        "2003",
        "2002",
        "2001",
        "2000",
      ],
      cal_data: "",
      dateFormat: "YYYY-MM-DD",
      start_date: "2020-01-01",
      end_date: "2020-01-01",
      victim_dynamic_data: "",
      perperator_dynamic_data: "",
      json_fields_district: {
        "S.No": "key",
        District: "district",
        Report_count: "report_count",
        "No.of Male": "male_count",
        "No.of Female": "female_count",
        Others: "others_count",
        Total: "total",
      },
      json_fields_vdc: {
        "S.No": "key",
        VDC: "vdc",
        Report_count: "report_count",
        "No.of Male": "male_count",
        "No.of Female": "female_count",
        Others: "others_count",
        Total: "total",
      },
      json_fields_province: {
        "S.No": "key",
        Province: "province_id",
        Report_count: "report_count",
        "No.of Male": "male_count",
        "No.of Female": "female_count",
        Others: "others_count",
        Total: "total",
      },
      json_data: [
        {
          name: "Tony Peña",
          city: "New York",
          country: "United States",
          birthdate: "1978-03-15",
          phone: {
            mobile: "1-541-754-3010",
            landline: "(541) 754-3010",
          },
        },
        {
          name: "Thessaloniki",
          city: "Athens",
          country: "Greece",
          birthdate: "1987-11-23",
          phone: {
            mobile: "+1 855 275 5071",
            landline: "(2741) 2621-244",
          },
        },
      ],

      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      series: [],
      chartOptions: {
        chart: {
          height: 500,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        legend: {
          position: "top",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          type: "category",
        },
      },
      downloadForm:{
        organizationName: "",
        email: "",
      }
    };
  },
  watch: {
    start_date(val) {
      this.start_date = val.format("YYYY-MM-DD");
      // console.log("start_date");
      // console.log(this.start_date);
      this.datepicker_data();
      this.fetchIconName();
    },
    end_date(val) {
      this.end_date = val.format("YYYY-MM-DD");
      // console.log("end_date");
      // console.log(this.end_date);
      this.datepicker_data();
      this.fetchIconName();
    },
  },
  // created(){
  //   this.fetchIconName();
  // },
  methods: {
    
    async fetchIconName(){
      await eventTypes.fetchDatasets(this.start_date,this.end_date).then(res=>{
        this.fetchedIcon = res.data.data;
        // console.log('Icon_data',this.fetchedIcon);
        this.fetchedIcon.forEach(e=>{
          // this.iconName.push(e.event_type);
          e.event_type = e.event_type.replace('/','');
        });
        // this.fetchedIcon.push(...res.data.data);
        // this.fetchedIcon.forEach(e=>{
        //   console.log("fetched-icon",e.event_type);
        //   this.fetchedIcon.push(...e.event_type);
        // });
        //  console.log("fetchedicon",this.fetchedIcon);
        
      
      })
    },
     getImgUrl(icon) {
       // this.iconName.forEach(e=>{
       //   icon = e;
       // })
      //  icon = 'Killing';  
      icon = 'Economic,Social & Cultural Rights';
      // return require('../../../public/icons/Dashboard/' + icon + '.svg');
      return require('../../assets/' + icon + '.svg');
  },
    async fetcheventapidata() {
      this.json_fields = this.json_fields_district;
      this.event_type = this.$route.params.id;
      this.report_head = this.event_type;

      await event
        .fetcheventdata(
          this.event_type,
          this.group_by,
          this.countOf,
          this.start_date,
          this.end_date
        )
        .then((res) => {
          this.event_data = res.data.data;
          this.data = res.data.data;
          this.keydata();
          //   console.log("-");
        });
      this.addTotalDataRow();
      this.changeGraphData();
    },
    async changeeventapidata() {
      switch (this.group_by) {
        case "vdc":
          this.columns[1].dataIndex = "vdc";
          this.columns[1].key = "vdc";
          this.columns[1].title = "Local Level";
          this.json_fields = this.json_fields_vdc;
          break;
        case "district":
          this.columns[1].dataIndex = "district";
          this.columns[1].key = "district";
          this.columns[1].title = "District";
          this.json_fields = this.json_fields_district;
          break;
        case "province_id":
          this.columns[1].dataIndex = "province_id";
          this.columns[1].key = "province_id";
          this.columns[1].title = "Province";
          this.json_fields = this.json_fields_province;
          break;
      }

      await event
        .fetcheventdata(
          this.event_type,
          this.group_by,
          this.countOf,
          this.start_date,
          this.end_date
        )
        .then((res) => {
          this.data = "";
          this.event_data = res.data.data;
          this.data = res.data.data;
          this.keydata();
          this.key_columns = Object.keys(this.data[0]);
        });
      this.addTotalDataRow();
      this.changeGraphData();
    },

    async changeDataYear(value) {
      await event
        .fetcheventdata(this.event_type, this.group_by, this.countOf, value)
        .then((res) => {
          this.data = res.data.data;
          this.keydata();
        });
      this.addTotalDataRow();
      this.changeGraphData();
    },

    async changecountofData() {
      await event
        .fetcheventdata(
          this.event_type,
          this.group_by,
          this.countOf,
          this.start_date,
          this.end_date
        )
        .then((res) => {
          this.data = res.data.data;
          this.keydata();
        });
      this.addTotalDataRow();
      this.changeGraphData(this.countOf);
    },
    changesearchdata() {},
    onSearch(value) {
      let test = this.data.filter((data) => {
        const male_count = data.male_count.toString();
        const female_count = data.female_count.toString();
        const report = data.report_count.toString();
        const other = data.others_count.toString();
        //const total = data.total.toString();

        let vdc = "";
        if (data.vdc !== undefined) {
          vdc = data.vdc.toString().toLowerCase();
        }
        let district = "";
        if (data.district !== undefined) {
          district = data.district.toLowerCase();
        }
        let province = "";
        if (data.province_id !== undefined) {
          province = data.province_id.toString();
        }
        const filter = value.toString().toLowerCase();

        return male_count.includes(filter) ||
          female_count.includes(filter) ||
          report.includes(filter) ||
          other.includes(filter) ||
          district != ""
          ? district.includes(filter)
          : "" || vdc != ""
          ? vdc.includes(filter)
          : "" || province != ""
          ? province.includes(filter)
          : "";
      });

      this.data = test;
      this.keydata();
    },
    keydata() {
      let i = 0;
      this.data.forEach(function (dat) {
        dat["key"] = i + 1;
        i++;
      });
      // console.log(this.data);
      /*let last_key = this.data.length -1;
                this.data[last_key].key = 'Total';*/
    },
    /*  rename(value) {
                          let objectOrder = '';
                          if(value != ''){
                              switch (value){
                                  case  'vdc':
                                      if("District" in this.json_fields){
                                           objectOrder = {
                                              'VDC': null,
                                          }
                                          this.json_fields.VDC = this.json_fields.District;
                                          this.json_fields.VDC = 'vdc';
                                          delete this.json_fields.District;
                                          Object.assign(objectOrder, this.json_fields)
                                      }
                                      else if("Province" in this.json_fields){
                                          this.json_fields.VDC = this.json_fields.Province;
                                          delete this.json_fields.Province;
                                          Object.assign(objectOrder, this.json_fields)
                                      }
                                      break;
                                  case  'district':
                                      if("VDC" in this.json_fields){
                                          this.json_fields.District = this.json_fields.VDC;
                                          delete this.json_fields.VDC;
                                          Object.assign(objectOrder, this.json_fields)
                                      }
                                      else if("Province" in this.json_fields){
                                          this.json_fields.District = this.json_fields.Province;
                                          delete this.json_fields.Province;
                                          Object.assign(objectOrder, this.json_fields)
                                      }
                                      break;
                                  case  'province_id':
                                      if("VDC" in this.json_fields){
                                          this.json_fields.Province = this.json_fields.VDC;
                                          delete this.json_fields.VDC;
                                          Object.assign(objectOrder, this.json_fields)
                                      }
                                      else if("District" in this.json_fields){
                                          this.json_fields.Province = this.json_fields.District;
                                          delete this.json_fields.District;
                                          Object.assign(objectOrder, this.json_fields)

                                      }
                                      break;
                              }
                          }
                             /!* if(value == 'vdc'){
                                  this.json_fields.vdc = this.json_fields.District;
                                  delete this.json_fields.District;
                              }
                              else if(value == 'district'){
                                  this.json_fields.vdc = this.json_fields.District;
                                  delete this.json_fields.District;
                              }
                              else if(value == 'province_id'){

                              } this.json_fields.vdc = this.json_fields.District;
                              delete this.json_fields.District;
                          }*!/

                      }*/
    changeoption(value) {
      switch (value) {
        case "table":
          this.table_data = 1;
          this.map_data = 0;
          this.graph_data = 0;
          break;
        case "map":
          this.map_data = 1;
          this.table_data = 0;
          this.graph_data = 0;
          break;
        case "graph":
          this.graph_data = 1;
          this.table_data = 0;
          this.map_data = 0;
          break;
      }
    },
    async changebtnEventData(value, title) {
      this.overlay_visible = true;
      await event
        .fetcheventdata(
          value,
          this.group_by,
          this.countOf,
          this.start_date,
          this.end_date
        )
        .then((res) => {
          this.report_head = title;
          this.event_type = value;
          this.data = res.data.data;
          this.keydata();
          this.overlay_visible = false;
        });
      this.changeGraphData();
      this.addTotalDataRow();

      this.fetchVictimAndPerpetratorData();
    },
    changeGraphData(value = "") {
      let title = this.report_head + " " + "Graph";
      this.chartOptions = {
        title: {
          text: title,
        },
      };
      // this.chartOptions.xaxis.categories = [];
      // console.log('test-----');
      // console.log(this.chartOptions.xaxis.categories);
      // this.series = [];
      // let that = this.chartOptions.xaxis.categories;
      // this.series[0].data.push({});
      let series_data = [];
      this.data.forEach(function (dat) {
        series_data.push({
          x: dat.label,
          y: dat.report_count,
        });
      });
      series_data.pop();
      // //
      // // this.chartOptions.xaxis.categories = that;
      // // console.log(this.chartOptions.xaxis.categories);
      // console.log(series_data);
      // console.log(this.series);
      //   console.log(series_data);
      // console.log(this.series[0].data);

      this.series = [
        {
          name: "Reports",
          data: series_data,
        },
      ];
      if (value == "male_count") {
        this.updateSeries();
      } else if (value == "female_count") {
        this.updateFemaleSeries();
      } else if (value == "others_count") {
        this.updateOthersSeries();
      } else if (value == "total_count") {
        this.updateTotalSeries();
      } else if (value == "perpetrator") {
        this.updatePerperatorSeries();
      }
    },
    updateSeries() {
      // console.log("male_count");
      let series_data = [];
      this.data.forEach(function (dat) {
        series_data.push({
          x: dat.label,
          y: dat.male_count,
        });
      });
      series_data.pop();
      this.series.push({
        name: "Male Count",
        data: series_data,
      });
      // console.log(this.series);
    },
    updateFemaleSeries() {
      let female_data = [];
      this.data.forEach(function (dat) {
        female_data.push({
          x: dat.label,
          y: dat.female_count,
        });
      });
      female_data.pop();
      this.series.push({
        name: "Female_count",
        data: female_data,
      });
    },
    updateOthersSeries() {
      let others_data = [];
      this.data.forEach(function (dat) {
        others_data.push({
          x: dat.label,
          y: dat.others_count,
        });
      });
      others_data.pop();
      this.series.push({
        name: "Others Count",
        data: others_data,
      });
    },
    updateTotalSeries() {
      let total_series = [];
      this.data.forEach(function (dat) {
        total_series.push({
          x: dat.label,
          y: dat.total,
        });
      });
      total_series.pop();
      this.series.push({
        name: "Total Count",
        data: total_series,
      });
    },
    updatePerperatorSeries() {
      let perperator_data = [];
      this.data.forEach(function (dat) {
        perperator_data.push({
          x: dat.label,
          y: dat.total,
        });
      });
      perperator_data.pop();
      this.series.push({
        name: "Perperator Count",
        data: perperator_data,
      });
    },
    callback(key) {
      this.tab_key = key;
    },
    async datepicker_data() {
      let start_date = this.start_date;
      let end_date = this.end_date;

      await event
        .changedatepickerdata(
          this.event_type,
          this.group_by,
          this.countOf,
          start_date,
          end_date
        )
        .then((res) => {
          this.event_data = res.data.data;
          this.data = res.data.data;
          this.keydata();
          // console.log(this.data);
        });
      this.fetchVictimAndPerpetratorData();
      this.addTotalDataRow();
      this.changeGraphData();
    },
    addTotalDataRow() {
      let report_count = 0;
      let male_count = 0;
      let female_count = 0;
      let others = 0;
      let total_count = 0;
      this.data.forEach(function (value) {
        report_count += value.report_count;
        male_count += value.male_count;
        female_count += value.female_count;
        others += value.others_count;
        total_count += value.total;
      });

      this.data.push({
        sn: "total",
        district: "total",
        report_count: report_count,
        male_count: male_count,
        female_count: female_count,
        others_count: others,
        total: total_count,
      });
    },
    async fetchVictimAndPerpetratorData() {
      let countOfVictim = "victim";
      let countOfPerpetrator = "perpetrator";
      let total_v_data = 0;
      let total_p_data = 0;
      await event
        .victimcountdata(
          this.event_type,
          this.group_by,
          countOfVictim,
          this.start_date,
          this.end_date
        )
        .then((res) => {
          let victim_data = res.data.data;
          // console.log("test");
          // console.log(victim_data);
          victim_data.forEach(function (v_d) {
            total_v_data += v_d.total;
          });
        });
      await event
        .perpetratorcountdata(
          this.event_type,
          this.group_by,
          countOfPerpetrator,
          this.start_date,
          this.end_date
        )
        .then((res) => {
          let perpet_data = res.data.data;
          perpet_data.forEach(function (per_d) {
            total_p_data += per_d.total;
          });
        });
      // console.log("-cvd");
      // console.log(total_v_data);
      this.victim_dynamic_data = total_v_data;
      this.perperator_dynamic_data = total_p_data;
    },
    /*updateVicitmSeries(){
                let victim_data =[];
                  this.data.forEach(function (dat) {
                      victim_data.push({
                          x: dat.label,
                          y: dat.total
                      });
                  })
                  this.series.push({
                      name: 'Victim Count',
                      data:perperator_data
                  })
              }*/

    /*changemultipleData(value){
                let item = '';
                let data = this.series;
                if(value.includes("female_count")){
                     this.updateFemaleSeries();
                }


                (value.includes("female_count")) ? this.updateFemaleSeries() :     this.series.splice(data.findIndex(data => data.name === "Female_count"),1);
                 console.log(item);
                //delete  this.series[item];
                console.log(this.series)

            }*/
    // download modal button
      clearData(){
        this.name = "";
        this.email = "";
        this.orgName = "";
      },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(values);
          this.email = values.Email;
          this.orgName = values.Organization_Name;
          this.name = values.Name;
          let formData = new FormData();
          formData.append("name",this.name);
          formData.append("email",this.email);
          formData.append("org_name",this.orgName);
          downloadPost.postData(formData).then(res=>{

            if(res.data.success === true){
                this.$cookies.set("downloadExcel","fjdklsajfkdls","7d");
                document.getElementById("test").click();
                this.downloadVisible = false;
                this.clearData();
            }

          })

           
          // console.log('Received values of form: ', values.Email);
        }
      });
    },
    showModal() {
     if(this.$cookies.get("downloadExcel") == null){
        this.downloadVisible = true;
       /*
        console.log("there is no cookie");*/
        //this.$cookies.set("downloadExcel","fjdklsajfkdls","7d");
      }
      else{
         this.downloadVisible = false;
         document.getElementById("test").click();
      }
    },
    handleOk() {
      this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      setTimeout(() => {
        this.downloadVisible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel() {
      // console.log("Clicked cancel button");
      this.downloadVisible = false;
    },
  },
};
</script>

<style>
/*----Report CSS*/
.title {
  margin-top: 50px;
}

.button-wrapper .btn {
  margin-right: 15px;
  width: 90px;
  height: 37px;
  color: white;
}
.d-table {
  overflow: auto;
}

.module {
  text-align: center;
  cursor: pointer;
}

.module-wrapper {
  border:none !important;
}

.module-wrapper .ant-card-body {
  padding: 9px !important;
}

.module p {
  margin-top: 4px;
}

.test {
  margin-left: 15px;
  cursor: pointer;
}
.test{
  margin-top:10px;
  text-align: center;
}
.test p {
    width: 150px;
    word-wrap: break-word;
    overflow:hidden;
    margin: 5px auto;
    text-align: center;
    /* animation: 3s linear 0s infinite alternate move; */
}

/* .module-wrapper {
  height: 50px;
  width: 50px;
  border-radius: 14px;
  border: 1px solid #00599a;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* flex-flow: column;*/
/*.module-wrapper .ant-card-body {*/
/*padding: 9px !important;*/
/*}*/
/*.module p {*/
/*margin-top: 4px;*/
/*}*/

/* .button-wrapper {
    display: flex;
     align-items: center;
    justify-content:space-around;
  } */

/* .test {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  } */

.all-card-wrapper {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  width: 80%;
  margin: auto;
}

.event-title {
  margin-left: auto;
  margin-right: auto;
  margin-top: -26px;
  background: #fff;
  width: 52%;
  text-align: center;
}

.test:hover img {
  transform: scale(0.95);
  transition: all 0.3s ease;
}
/* .ant-btn-primary {
    width: 50px !important;
  } */

.ant-select {
  display: block !important;
}
.search-btn {
  margin-left: 0px !important;
}
.search-btn .ant-btn-primary {
  width: 50px !important;
}
.ant-btn {
  display: block !important;
}
@media (max-width: 1125px) {
  .btn-child {
    margin-bottom: 5px;
  }
}
.options {
  margin-bottom: 10px;
}
.options .ant-calendar-picker {
  width: 100%;
}
span {
  text-align: center;
}
.overlay {
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0px;
  left: 0px;
  /* opacity: 0.3; */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .overlay > .ant-spin-spinning{
  position:fixed !important;
  top:50% !important; 
} */

.loaderOnClick {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #feab56;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: loaderOnClick 1s ease-in-out infinite;
  animation: loaderOnClick 1s ease-in-out infinite;
}

.loaderOnClick:before {
  content: "";
  position: absolute;
  background-color: #c3535f;
  top: 0px;
  left: -25px;
  height: 12px;
  width: 12px;
  border-radius: 12px;
}

.loaderOnClick:after {
  content: "";
  position: absolute;
  background-color: #78c972;
  top: 0px;
  left: 25px;
  height: 12px;
  width: 12px;
  border-radius: 12px;
}

@-webkit-keyframes loaderOnClick {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
  }
}

@keyframes loaderOnClick {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
</style>
